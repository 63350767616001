import scrollIntoView from 'scroll-into-view-if-needed'

export function useValidation() {
  function getValidationError(feildName) {
    return this.validationErrors[feildName]
  }

  function hasValidationError(feildName) {
    return this.getValidationError(feildName) !== undefined
  }

  function countValidationErrors() {
    return Object.keys(this.validationErrors).length
  }

  function setValidationError(feildName, value) {
    this.validationErrors[feildName] = value
  }

  function removeValidationError(feildName) {
    delete this.validationErrors[feildName]
  }

  function validateFeild(feildName, test) {
    const testResult = test()
    if (typeof testResult === 'string') {
      this.setValidationError(feildName, testResult)
      return false
    } else {
      this.removeValidationError(feildName)
      return true
    }
  }

  function scrollToErrors() {
    const errorsEls = Object.keys(this.validationErrors)
      // refs need to be named like this otherwise it will break
      .map(name => this.$refs[`field_${name}`])
      .filter(el => el !== undefined)

    const topValue = el => window.scrollY + el.getBoundingClientRect().top
    const highestEl = errorsEls.reduce(
      (current, next) => (topValue(next) < topValue(current) ? next : current),
      errorsEls[0]
    )

    scrollIntoView(highestEl, { behavior: 'smooth' })
  }

  return {
    validationErrors: {},
    getValidationError,
    hasValidationError,
    setValidationError,
    removeValidationError,
    countValidationErrors,
    validateFeild,
    scrollToErrors,
  }
}
