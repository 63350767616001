;(function ($) {
  $(document).ready(function () {
    if ($(document.body).hasClass('admin-tools')) {
      $('.ui-tools-datepicker').each(function () {
        var controlsMin = $(this).data('controls-min')
        var controlsMax = $(this).data('controls-max')

        $(this).datepicker({
          dateFormat: 'yy-mm-dd',
          minDate: $(this).data('min-date'),
          maxDate: $(this).data('max-date'),
        })

        if (controlsMin) {
          $(this).on('change', function () {
            $(controlsMin).datepicker('option', 'minDate', $(this).val())
          })
        }

        if (controlsMax) {
          $(this).on('change', function () {
            $(controlsMax).datepicker('option', 'maxDate', $(this).val())
          })
        }
      })

      $('.admin-tools-charges-index--table tbody tr').on('click', function (e) {
        var $a = $(this).find('a')

        window.location.href = $a.prop('href')
        e.preventDefault()
      })
    }
  })
})(jQuery)
