import { focusLock } from 'js/lib/focus-lock'

const dropdowns = document.querySelectorAll('.js-c-ui-dropdown')

function initDropdown(dropdown) {
  if (!dropdown) return

  const trigger = dropdown.querySelector('.js-c-ui-dropdown-trigger')
  const content = dropdown.querySelector('.js-c-ui-dropdown-content')

  if (!trigger || !content) return

  const handleKeydown = (event) => onKeydown(event, trigger, content)
  const handleOutsideClick = (event) => onClickOutside(event, trigger, content)
  const handleFocusLock = (event) => focusLock(event, dropdown)
  const handleTriggerClick = () => toggleDropdown(trigger, content, handleKeydown, handleOutsideClick, handleFocusLock)

  trigger.addEventListener('click', handleTriggerClick)
}

const toggleDropdown = (trigger, content, handleKeydown, handleOutsideClick, handleFocusLock) => {
  const isExpanded = trigger.getAttribute('aria-expanded') === 'true'
  if (isExpanded) {
    closeDropdown(trigger, content, handleKeydown, handleOutsideClick, handleFocusLock)
  } else {
    openDropdown(trigger, content, handleKeydown, handleOutsideClick, handleFocusLock)
  }
}

const openDropdown = (trigger, content, handleKeydown, handleOutsideClick, handleFocusLock) => {
  content.classList.replace('tw-hidden', 'tw-flex')

  trigger.setAttribute('aria-expanded', 'true')

  // Add event listeners
  document.addEventListener('keydown', handleKeydown)
  document.addEventListener('click', handleOutsideClick)
  document.addEventListener('keydown', handleFocusLock)
}

const closeDropdown = (trigger, content, handleKeydown, handleOutsideClick, handleFocusLock) => {
  content.classList.replace('tw-flex', 'tw-hidden')

  trigger.setAttribute('aria-expanded', 'false')

  // Remove event listeners
  document.removeEventListener('keydown', handleKeydown)
  document.removeEventListener('click', handleOutsideClick)
  document.removeEventListener('keydown', handleFocusLock)
}

const onKeydown = (event, trigger, content) => {
  if (event.key === 'Escape' && trigger.getAttribute('aria-expanded') === 'true') {
    closeDropdown(trigger, content)
  }
}

const onClickOutside = (event, trigger, content) => {
  if (!trigger.contains(event.target) && !trigger.contains(event.target) ) {
    closeDropdown(trigger, content)
  }
}

// Initialize all dropdowns
dropdowns.forEach((dropdown) => {
  initDropdown(dropdown)
})
