/*
  Adds utm_campaign url params to the feed links so that we can track where the feed is being used
*/

const feed = document.querySelector('body.feed')
if (feed !== null) {
  const utmLinks = document.querySelectorAll('a[href*=utm]')
  const utmCampaign = window.location != window.parent.location ? document.referrer : 'unknown'

  utmLinks.forEach(link => {
    link.href = `${link.href}&utm_campaign=${utmCampaign}`
  })
}
