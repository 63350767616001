import entries from 'object.entries'
import { RescueDirectoryMap } from './map-service'
import { underscore } from 'inflected'
import { createFlash } from 'components/flash/component'
import I18n from 'js/lib/i18n.js.erb'

$(document).ready(function () {
  if (!shouldInitializeRescueDirectory()) return

  let map = undefined
  const filters = {}

  async function getInitializedMap() {
    if (map) return map
    try {
      const mapInsance = new RescueDirectoryMap()

      await mapInsance.init()

      $('.petrescue-map.uninitialized').removeClass('uninitialized')

      map = mapInsance
      return map
    } catch (error) {
      const message = I18n.t('rescue_directory.map.flash.errors.loading_error')
      createFlash('notice', message)
    }
  }

  $(document).on('click', '.petrescue-map.uninitialized', async function (e) {
    e.preventDefault()
    await getInitializedMap()
  })

  $('.map-filters .filter-box-options .filter-box-options__option').on('click', async function (e) {
    e.preventDefault()
    const map = await getInitializedMap()
    const filterData = $(this).data()

    for (const [index, value] of entries(filterData)) {
      const key = underscore(index)

      if (!$(this).hasClass('active')) {
        filters[key] = filters[key] ? filters[key] : []
        filters[key].push(value)
      } else {
        const i = filters[key].indexOf(value)
        if (i !== -1) {
          filters[key].splice(i, 1)
        }
      }
    }

    map.loadMapData(filters)
    $(this).toggleClass('active')
  })

  $('.filter-box-options').on('submit', async function (e) {
    e.preventDefault()
    const map = await getInitializedMap()
    const location = $('.location').val()

    try {
      await map.geocode(location)
    } catch (error) {
      const message = I18n.t('rescue_directory.map.flash.errors.no_results', {
        location: location,
      })
      createFlash('notice', message)
    }
  })

  $('.filter-box-options.state a').on('click', async function (e) {
    const map = await getInitializedMap()

    if ($(this).hasClass('active')) {
      map.recenterMap($(this).data('lat'), $(this).data('lng'), $(this).data('stateName'))
    }

    if (!$('.filter-box-options.state a').hasClass('active')) {
      map.recenterMap()
    }

    e.preventDefault()
  })

  $('.sub-list').on('click', function (e) {
    e.preventDefault()
    if (!$(e.target).hasClass('sub-list')) return
    $(this).toggleClass('expanded')
    $(this).find('ul').toggleClass('hidden')
  })
})

function shouldInitializeRescueDirectory() {
  return $('.petrescue-map').length > 0
}
