import I18n from 'js/lib/i18n.js.erb';
import { createFlash } from 'components/flash/component';
import { CLOSE_BUTTON_SELECTOR, MODAL_SELECTOR, closeAllModals } from 'components/modal/component';

const initForm = (formElement) => {
  const listingStatus = formElement.querySelector('.js-c-change-status-input select');
  let initialStatus = listingStatus.value;
  let newStatus = initialStatus;

  const listingOnHoldReasonWrapper = formElement.querySelector('.js-c-on-hold-reason');
  const listingOnHoldReason = formElement.querySelector('.js-c-on-hold-reason select');
  let initialOnHoldReason = listingOnHoldReason.value;
  let newOnHoldReason = initialOnHoldReason;

  const buttonElement = formElement.querySelector('.js-c-change-status-button');
  const onHoldHint = document.querySelector('.js-listing-change-status-hint2');

  // Don't run if select is disabled
  if (listingStatus.getAttribute('disabled') !== null) return;

  function confetti() {
    $('.confetti-canvas').show()
    window.RestartConfetti()
    setTimeout(window.DeactivateConfetti, 1200)
  }

  function shouldDisableButton() {
    const statusUnchanged = initialStatus === newStatus;
    const onHoldReasonUnchanged = initialOnHoldReason === newOnHoldReason;

    if (newStatus === 'on_hold') {
      return statusUnchanged && onHoldReasonUnchanged;
    }

    return statusUnchanged;
  }

  function updateFormState() {
    if(buttonElement) {
      console.log(shouldDisableButton())
      buttonElement.disabled = shouldDisableButton();
    }
    
    listingOnHoldReasonWrapper.classList.toggle('tw-hidden', newStatus !== 'on_hold');
    onHoldHint && onHoldHint.classList.toggle('tw-hidden', newStatus !== 'on_hold');

  }

  function updateListingStatusBanner() {
    // This is the small banner which sits on top of the image
    // Isn't directly related to changing the status of a listing
  
    var publicOnHoldReasons = ['reviewing_applications', 'adoption_pending']
    const statusBanners = document.querySelectorAll('.js-c-listing-status-banner');
  
    let statusText = I18n.t(`listings.listing.status-banner.${newStatus}`);

    if (newStatus === 'on_hold' && publicOnHoldReasons.includes(newOnHoldReason)) {
      const reason = I18n.t(`listings.statuses.on_hold_reasons.${newOnHoldReason}`);
      statusText = I18n.t('listings.listing.status-banner.on_hold_with_reason', { reason });
    }
  
    statusBanners.forEach((banner) => {
      banner.querySelector('.js-c-listing-status-banner__banner').innerHTML = statusText;
      banner.classList.toggle('tw-hidden', newStatus === 'active');
    });
  }
  updateFormState();

  listingStatus.addEventListener('change', function () {
    newStatus = this.value;
    updateFormState();
  });

  listingOnHoldReason.addEventListener('change', function () {
    newOnHoldReason = this.value;
    updateFormState();
  });

  // Submitting the fake form
  buttonElement && buttonElement.addEventListener('click', function () {
    const selectedData = listingStatus.querySelector(`option[value="${newStatus}"]`).dataset;
    const url = selectedData.url;
    const method = selectedData.method;

    const data = { reason: listingOnHoldReason.value }

    const callbacks = {
      on_hold: function (listingData) {
        const listings = {};
        listings[listingData.id] = listingData.name;
  
        $.onHoldNotificationModal.init(listings)
      },
      rehomed: function (listingData) {
        const listings = {};
        listings[listingData.id] = listingData.name;
  
        $.adoptionNotificationModal.init(listings, {
          noEnquiriesCallback: confetti,
        })
  
        // Add event listener for the close button
        const closeButton = document.querySelector(`.adopted_notification ${CLOSE_BUTTON_SELECTOR}`);
        if (closeButton) {
          closeButton.addEventListener('click', confetti);
        }
      },
    };

    // eslint-disable-next-line no-undef
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(() => {
        if (callbacks[newStatus]) {
          callbacks[newStatus].call(this, listingStatus.dataset);
        }

        createFlash('notice', I18n.t('listings.admin_box.statuses.flash.success'))
        
        
        
        // changes to the listing page (not directly related to this component)
        updateListingStatusBanner()
        // TODO: these classes are way too generic
        if (newStatus == 'active') {
          // TODO: these classes are way too generic
          $('.active, .active-button').removeClass('hidden')
          $('.not-active, .not-active p, .pet-listing__status p, .not-active-button .js-c-listing-status-banner').addClass('hidden')
        } else {
          $('.active, .pet-listing__status p, .not-active p, .active-button').addClass('hidden')
          $('.not-active').removeClass('hidden')
          $('.not-active-button').removeClass('hidden')
        }

        // reset the form
        initialStatus = newStatus;
        initialOnHoldReason = newOnHoldReason
        updateFormState()
      })
      .catch(() => {
        createFlash('alert', I18n.t('listings.admin_box.statuses.flash.error'));
      });
  });

  document.querySelector(MODAL_SELECTOR).addEventListener('click', (event) => {
    if (event.target.classList.contains('close-button')) {
      closeAllModals();
    }
  });
};

document.querySelectorAll('.js-c-change-status-form').forEach(initForm);
