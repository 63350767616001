;(function ($) {
  $(document).ready(function () {
    var hash = window.location.hash
    var $selectedContent = $(hash)

    $('[data-content-replace-handler]').on('click', function (e) {
      var $contentContainer = $(this).find('[data-content-replace]')
      var text = $contentContainer.data('content-replace')

      $contentContainer.data('content-replace', $contentContainer.text()).text(text)

      $(this).toggleClass('expanded')

      e.stopPropagation()
    })

    if ($selectedContent.length > 0) {
      $selectedContent.trigger('click')
    }
  })
})(jQuery)
