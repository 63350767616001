import $ from 'cash-dom'

let listeningForUnload = false

// Returns a function that allows you to remove the alert programatically 
export function protectFromUnsavedChanges(formInputs) {
  $(formInputs).on('change', onPageHasUnsavedChanges)
  return onAllChangesSaved
}

function onPageHasUnsavedChanges() {
  if (listeningForUnload === true) return
  listeningForUnload = true

  console.log('added unsaved listener')

  // use native event listeners to to enable default browser behaviour
  window.addEventListener('beforeunload', beforeUnloadListener, { capture: true })
}

function onAllChangesSaved() {
  listeningForUnload = false
  // use native event listeners to to enable default browser behaviour
  window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true })
}

function beforeUnloadListener(event) {
  console.log('before unload?')
  event.preventDefault()
  const confirmationMessage = 'You have unsaved changes, Are you sure you want to exit?'

  return (event || window.event).returnValue = confirmationMessage
}