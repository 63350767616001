$(document).ready(function () {
  if (!$('.pages-show-our-people').length) return

  $(document).on('click', '.team_member_heading', function () {
    var $parent = $(this).parent()
    $parent
      .find('.team_member_description')
      .stop(true, true)
      .slideToggle(300, function () {
        $parent.toggleClass('expanded')
      })
  })
})
