import createFormHelper from "../../../../form/helpers/form_helper";

document.addEventListener('DOMContentLoaded', () => {
  const el = document.querySelector('.js-rms_pet_form')
  if (!el) return;
  const petForm = createFormHelper(el)

  const initialFormState = petForm.getState()
  handleSpeciesChange(initialFormState)
  handleSeniorField(initialFormState)

  petForm.onChange(formState => {
    handleSpeciesChange(formState)
    handleSeniorField(formState)
  })

  function handleSeniorField(formState) {
    const birthdate = new Date(formState['rms_pet_assignment[pet_attributes][birthdate]'].value)
    const minSeniorAge = formState['rms_pet_assignment[pet_attributes][senior]'].dataAttr.minAge
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - birthdate.getTime();
    const petAge = Math.floor(timeDiff / (1000 * 3600 * 24 * 365.25));

    if(petAge >= minSeniorAge) {
      petForm.show('rms_pet_assignment[pet_attributes][senior]')
    } else {
      petForm.hide('rms_pet_assignment[pet_attributes][senior]')
    }
  }

  function handleSpeciesChange(formState) {
    const species = formState['rms_pet_assignment[pet_attributes][species_id]'].value
    const cFormSelectSearch = document.querySelector('.c-form-select-search')

    // Reset all fields. 
    // If you add new fields to be dynamically changed below in the switch/case then add them here too    
    petForm.reset([
      'rms_pet_assignment[pet_attributes][breeds]',
      'rms_pet_assignment[pet_attributes][mix]',
      'rms_pet_assignment[pet_attributes][size]',
      'rms_pet_assignment[pet_attributes][vaccinated]',
      'rms_pet_assignment[pet_attributes][heart_worm_treated]',
      'rms_pet_assignment[pet_attributes][wormed]',
      'rms_pet_assignment[needs_constant_care]',
      'rms_pet_assignment[pet_attributes][coat]',
      'rms_pet_assignment[indoor_only]'
    ])
    
    switch (species) {
      case '1': // Dog
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][size]')
        petForm.show('rms_pet_assignment[pet_attributes][vaccinated]')
        petForm.show('rms_pet_assignment[pet_attributes][heart_worm_treated]')
        petForm.show('rms_pet_assignment[pet_attributes][wormed]')
        petForm.show('rms_pet_assignment[needs_constant_care]')
        cFormSelectSearch.setAttribute('data-query-params', JSON.stringify( { "species_id": 1 } ))
        break;
        
      case '2': // Cat
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][coat]')
        petForm.show('rms_pet_assignment[pet_attributes][vaccinated]')
        petForm.show('rms_pet_assignment[pet_attributes][wormed]')
        petForm.show('rms_pet_assignment[indoor_only]')
        cFormSelectSearch.setAttribute('data-query-params', JSON.stringify( { "species_id": 2 } ))
        break;

      case '3': // Guinea Pig
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][coat]')
        cFormSelectSearch.setAttribute('data-query-params', JSON.stringify( { "species_id": 3 } ))
        break;
      
      case '4': // Rabbit
        petForm.show('rms_pet_assignment[pet_attributes][breeds]')
        petForm.show('rms_pet_assignment[pet_attributes][mix]')
        petForm.show('rms_pet_assignment[pet_attributes][vaccinated]')
        cFormSelectSearch.setAttribute('data-query-params', JSON.stringify( { "species_id": 4 } ))
        break;
    
      default:
        break;
    }
  }
});