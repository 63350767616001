import { request } from 'js/lib/api/api-request'

export function deleteFavourite(favouriteId, listingId) {
  const data = { favourites: { listing_id: listingId } }
  return request({
    url: `/users/favourites/${favouriteId}`,
    type: 'DELETE',
    data,
    dataType: 'json',
  })
}

export async function createFavourite(listingId) {
  const data = { favourites: { listing_id: listingId } }
  return request({
    url: '/users/favourites',
    type: 'POST',
    data,
    dataType: 'json',
  })
}
