import { register } from 'js/lib/create-component'
import { initializePinPayment, handlePinPayment } from 'components/payments/pin/card_entry/component'
import { useValidation } from 'js/lib/components/use-validation'

register('newCardSubscription', function () {
  async function init() {
    await initializePinPayment()
    this.initialized = true
  }

  async function handleSubmit() {
    if (this.isSubmitting === true) return
    this.isSubmitting = true
    this.validationErrors = {}

    const form = this.$refs.form
    const { validationErrors, token } = await handlePinPayment()

    if (token) {
      // Submit the form after alpine has updated everything.
      this.paymentToken = token
      this.$nextTick(() => form.submit()) // Note that form.submit does not raise a submit event so it will skip this handler
    } else if (validationErrors) {
      this.validationErrors = validationErrors
      this.$nextTick(() => {
        this.isSubmitting = false
        this.scrollToErrors()
      })
    }
  }

  return {
    ...useValidation(),
    init,
    initialized: false,
    isSubmitting: false,
    paymentToken: '',
    handleSubmit,
  }
})
