const waitForLoad = document.querySelectorAll(".js-wait-for-load")

if (waitForLoad.length) {
  document.body.classList.add('js-loading')

  window.addEventListener("load", playAnimations)
}

function playAnimations() {
  document.body.classList.remove('js-loading');
}
