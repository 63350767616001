import 'alpinejs'

const componentsList = {}

export function register(name, componentFn) {
  componentsList[name] = componentFn
}

function use(name, ...props) {
  try {
    return componentsList[name](...props)
  } catch (error) {
    console.error(`component "${name}" has thrown an error and can't be run (see below)`)
    console.error(error)
  }
}

window.use = use
