import { loadScript } from 'js/lib/script-loader'
import { createFlash } from 'components/flash/component'

let paypalPaymentApprovedPromise

export async function initializePaypal(renderToEl, getPaymentData) {
  await loadScript(`https://www.paypal.com/sdk/js?client-id=${window.PetRescue.env.paypal.clientId}&currency=AUD`)
  let paymentData
  // Doing some fancy stuff here so that we can have a nicer way of consuming paypal payments. This way all you have to
  // do to get the payment token is show the button then go `await getPaypalPaymentToken()`. One thing to note is that this
  // assumes there will only ever be one "payment" to approve on a page load.
  paypalPaymentApprovedPromise = new Promise((resolve, reject) => {
    window.paypal
      .Buttons({
        env: PetRescue.env.paypal.environment,
        style: { color: 'gold', layout: 'horizontal', shape: 'pill' },
        createOrder: async () => {
          try {
            paymentData = await createOrder(getPaymentData())
            return paymentData.token
          } catch (error) {
            reject(error)
          }
        },
        onApprove: paypalData => resolve({ paypalData, paymentData }),
      })
      .render(renderToEl)
  })
}

export async function getPaypalPaymentToken() {
  return await paypalPaymentApprovedPromise
}

async function createOrder(data) {
  try {
    return await $.ajax({
      url: window.PetRescue.env.endpoints.payments.create_paypal_payment,
      type: 'POST',
      data,
      dataType: 'json',
    })
  } catch (error) {
    const response = error.responseJSON ? error.responseJSON.error : error.responseText
    createFlash('notice', response)
  }
}
