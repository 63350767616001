const historyLogRevealButtons = document.querySelectorAll('.js-c-history-log-button')

historyLogRevealButtons.forEach(button => {
  button.addEventListener('click', () => {
    const currentRow = button.closest('tr')
    const expandedDetails = currentRow.nextElementSibling
    button.setAttribute('aria-expanded', button.getAttribute('aria-expanded') === 'false' ? 'true' : 'false')
    currentRow.classList.toggle('tw-bg-neutral-100')
    expandedDetails.classList.toggle('hidden')
    button.querySelector('.c-icon').classList.toggle('tw-rotate-180')
  })
})
