$(document).ready(function () {
  $('#listings_enquiries_blacklist_email').blur(function () {
    $('.hint').remove()

    var email = $(this).val()
    var alreadyBlacklisted = $('td:contains(' + email + ')')

    if (email.length > 0 && alreadyBlacklisted.length > 2) {
      $(this).after(
        $('<p>', {
          class: 'hint',
          text: 'Email has already been blacklisted ' + alreadyBlacklisted.length + ' times',
        })
      )
    }
  })
})
