import { openModal, CLOSE_BUTTON_SELECTOR } from 'components/modal/component'
import { createFlash } from 'components/flash/component'

const REMOVE_MODAL_NAME = 'remove'

;(function ($) {
  $(document).ready(function () {
    var $form = $('form.mass_update_listings')

    $form
      .find('select[name="status"]')
      .on('change', function () {
        dataLayer.push({
          listingStatus: $(this).val(),
        })
      })
      .trigger('change')

    $('#site-menu-drawer').on('click', '.mass_update_listings--submit', function (e) {
      var $self = $(this)
      var formUrl = $form.prop('action')
      var status = $form.find('select[name="status"]').val()
      var statusReason = $form.find('select[name="reason"]').val()
      var $listings = $form.find('.checked').closest('.checkbox')
      var requestData

      e.preventDefault()

      $self.addClass('disabled').prop('disabled', true)

      if (status === 'removed') {
        openModal(REMOVE_MODAL_NAME)
        $('.yes').on('click', function () {
          $('.mass_update_listings').submit()
        })
      } else if (status === 'rehomed') {
        requestData = {
          status: status,
          reason: statusReason,
          listing: {},
        }

        $listings.each(function () {
          requestData.listing[$(this).data('id')] = '1'
        })

        $.ajax({
          url: formUrl,
          type: 'PUT',
          data: requestData,
          dataType: 'json',
          success: function () {
            var listings = {}

            $listings.each(function () {
              var listingData = $(this).data()

              listings[listingData.id] = listingData.name
            })

            $.ajax({
              url: window.location.href,
              type: 'GET',
              dataType: 'html',
              success: replaceRecords,
            })

            $.adoptionNotificationModal.init(listings, {
              noEnquiriesCallback: afterAdoption,
            })
          },
          error: function () {
            createFlash('alert', "Sorry, we encountered an error and weren't able to update those listings")
            $self.removeClass('disabled').prop('disabled', false)
          },
        })

        // TODO: This is a dodgy way of setting up a callback but to fix this the whole file would
        // need to be refactored. For now we're just importing the close button selector - that way
        // we can at least keep track of it if something changes there.
        $(`.adopted_notification ${CLOSE_BUTTON_SELECTOR}`).on('click', afterAdoption)
      } else if (status === 'on_hold') {
        requestData = {
          status: status,
          reason: statusReason,
          listing: {},
        }

        $listings.each(function () {
          requestData.listing[$(this).data('id')] = '1'
        })

        $.ajax({
          url: formUrl,
          type: 'PUT',
          data: requestData,
          dataType: 'json',
          success: function () {
            var listings = {}

            $listings.each(function () {
              var listingData = $(this).data()

              listings[listingData.id] = listingData.name
            })

            $.ajax({
              url: window.location.href,
              type: 'GET',
              dataType: 'html',
              success: replaceRecords,
            })

            $.onHoldNotificationModal.init(listings)
          },
          error: function () {
            createFlash('alert', "Sorry, we encountered an error and weren't able to update those listings")
            $self.removeClass('disabled').prop('disabled', false)
          },
        })
      } else {
        $('.mass_update_listings').submit()
      }
    })
  })

  function replaceRecords(response) {
    var $newDocument = $(response)

    $newDocument.find('select').select2({
      minimumResultsForSearch: 9,
      width: '100%',
    })

    const exclusions = ':not(.no-uniform, .component, [class^="c-"], [class^="js-c-"])'
    $newDocument.find(`input[type="checkbox"]${exclusions}, input[type="radio"]${exclusions}`).uniform()

    $('.records-container').replaceWith($newDocument.find('.records-container'))

    $('[data-controlled-by]').trigger('controlledBy.bind')
  }

  function afterAdoption() {
    $('.confetti-canvas').show()
    window.RestartConfetti()
    setTimeout(window.DeactivateConfetti, 1200)
  }
})(jQuery)
