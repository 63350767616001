import { formatDistanceToNow } from 'date-fns'

PetRescue.Public.Listings = (function () {
  function Listings() {
    this.attachPageOptions()
    this.adoptedDaysAgo()
  }

  Listings.prototype.attachPageOptions = function () {
    return $('[name="per_page"]').change(function () {
      var $this, currentPage, existingParams, i, j, len, pair, param, params, perPage, searchString
      searchString = window.location.search.substring(1)
      existingParams = searchString.split('&')
      perPage = 12
      currentPage = 1
      $this = $(this)
      params = ['per_page=' + $this.val()]

      for (j = 0, len = existingParams.length; j < len; j++) {
        param = existingParams[j]
        pair = param.split('=')
        if (pair[0] === 'per_page') {
          perPage = pair[1]
        } else {
          if (pair[0] === 'page') {
            currentPage = pair[1]
          } else {
            params.push(param)
          }
        }
      }
      i = (currentPage - 1) * perPage + 1
      currentPage = Math.ceil(i / $this.val())
      params.push('page=' + currentPage)
      searchString = params.join('&')
      return (window.location.href = window.location.pathname + '?' + searchString)
    })
  }

  Listings.prototype.adoptedDaysAgo = function () {
    $('.cards-listings-preview__content__section__adopted-at').each(function (_, value) {
      const dateString = $(value).data('date')
      if (dateString) {
        const created = new Date(dateString)
        $(value).html(formatDistanceToNow(created) + ' ago')
      }
    })
  }

  return Listings
})()

$(function () {
  if ($('body').is('.users-favourites, .listings, .groups-show, .blog_posts')) {
    return (window['listings'] = new PetRescue.Public.Listings())
  }
})
