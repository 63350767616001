import $ from 'cash-dom'

const alertBannerComponent = $('.js-c-members-alert-banner')

if (alertBannerComponent.length) {
  alertBannerComponent.on('click', '.js-c-members-alert-banner__close', handleBannerRemove)
  alertBannerComponent.on('click', '.js-c-members-alert-banner a', handleBannerRemove)
}

async function handleBannerRemove() {
  const banner = $(this).closest('.js-c-members-alert-banner')
  const id = $(this).data('id')
  const data = { notification_type: 'ui', id }

  await window.$.ajax({ url: '/notification_views', type: 'POST', data })
  banner.remove()
  if (!$('.js-c-members-alert-banner').length) {
    $('.js-c-members-alert-banner-wrapper').remove()
  }
}
