import { on } from 'js/lib/events'

on('change', '.js-c-search-modal__select', event => {
  const selector = event.target.value

  document.querySelectorAll('.js-c-search-modal .js-hint-above').forEach(el => el.classList.add('hidden'))

  if (selector.length === 0) return;

  document.querySelector(`.js-c-search-modal .js-hint-above[data-selector=${selector}]`).classList.remove('hidden')

  enableSubmit()
})

on('keyup', '#js-c-search-modal__input', enableSubmit)

function enableSubmit() {
  const searchInput = document.getElementById('js-c-search-modal__input')
  const searchSelect = document.querySelector('.js-c-search-modal__select')
  const submitButton = document.querySelector('.js-c-search__btn')

  if (searchInput.value.length > 0 && searchSelect.value.length > 0) {
    submitButton.classList.remove('disabled')
    submitButton.disabled = false
  } else {
    submitButton.classList.add('disabled')
    submitButton.disabled = true
  }
}
