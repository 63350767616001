import I18n from 'js/lib/i18n.js.erb'
import { on } from 'js/lib/events'
import { handleError, request } from 'js/lib/api/api-request'

const refundButtons = document.querySelectorAll('.c-payments-refund-payment-request-button')
refundButtons.forEach(el => on('click', el, event => handleMarkRefunded(event, el)))

async function handleMarkRefunded(event, el) {
  event.preventDefault()

  const refund_url = el.dataset.refundUrl
  const isConfirmed = window.confirm(I18n.t('payments.refund_payment_request_button.component.confirm_refund'))

  if (isConfirmed) {
    try {
      await request({ url: refund_url })
      window.location.reload()
    } catch (e) {
      console.log(e)
      handleError(e)
    }
  }
}
