import { createAnalyticsGroup } from 'js/lib/analytics'

const track = createAnalyticsGroup('Our team cards')

$('.js-c-our-team-card-text-arrow').on('click', function () {
  let $dropdown = $(this).parent().find('.js-c-our-team-card-text-dropdown')
  if ($dropdown.is(':visible')) {
    $dropdown.slideUp()
    track('accordion-close', 'accordion')
  } else {
    $dropdown.slideDown()
    track('accordion-open', 'accordion')
  }

  $(this).find('.fa').toggleClass('fa-chevron-down')
  $(this).find('.fa').toggleClass('fa-chevron-up')
})
