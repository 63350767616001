import { openCloudinaryWidget, isCloudinaryWidgetOpen, preloadCloudinaryWidget } from 'js/lib/cloudinary'
import { closeModal, CLOSE_BUTTON_SELECTOR } from 'components/modal/component'

const ADD_CHILD_MODAL_NAME = 'add_child'

$(document).ready(function () {
  const MAX_IMAGES = 9
  const photoCountText = $('.js-user-photo-count')

  initializePhotosUpload()
  initializeFileUpload()
  initializeAddPetPhoto()
  initializeNewChildForm()

  function getUserPhotoCount() {
    return $('.js-adopter-profile-img').length
  }

  function updateUserPhotoCountState() {
    const photoCount = getUserPhotoCount()
    photoCountText.text(`${photoCount} of ${MAX_IMAGES} uploaded`)

    // disable the input if we've reached max images
    $('#photos--upload').attr('disabled', photoCount >= MAX_IMAGES)
  }

  function initializePhotosUpload() {
    if (!$('#photos--upload').length) return

    preloadCloudinaryWidget()

    document.getElementById('photos--upload').addEventListener('click', async function () {
      if (getUserPhotoCount() >= MAX_IMAGES) return
      if (isCloudinaryWidgetOpen()) return

      var results = await openCloudinaryWidget({
        tags: ['general_photo'],
        maxImageWidth: 1500,
        maxImageHeight: 1500,
        maxFiles: MAX_IMAGES - getUserPhotoCount(),
      })

      results.forEach(function (result) {
        const photo = result.info
        var formData = new FormData()

        formData.append('photo', 'image/upload/' + photo.path + '#' + photo.signature)
        formData.append('ownable_id', $('.user_id').val())
        formData.append('ownable_type', 'User')

        $.ajax({
          url: '/photos',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          success: function (photoResults) {
            $('.multiple_images .icon').append(
              '<div class="photo js-adopter-profile-img"><img src="' +
                result.info.secure_url +
                '" /><a data-confirm="Are you sure you want to remove this photo?" class="action remove-photo" rel="nofollow" data-method="delete" href="/photos/' +
                photoResults.id +
                '">Remove</a></div>'
            )

            updateUserPhotoCountState()
          },
          error: function (xhr) {
            if ((xhr.status = 401)) {
              window.location.reload()
            }
          },
        })
      })
    })
  }

  function initializeFileUpload() {
    if (!$('#button--file--upload').length) return
    preloadCloudinaryWidget()

    $('#button--file--upload').on('click', async function () {
      if (isCloudinaryWidgetOpen()) return

      var results = await openCloudinaryWidget({
        form: '#adopter_profile_form',
        fieldName: 'user[profile_image_url]',
        tags: ['profile_photo'],
        multiple: false,
        maxImageWidth: 1500,
        maxImageHeight: 1500,
      })

      results.forEach(function (result) {
        $('.profile_image img').attr('src', result.info.secure_url).removeClass('hidden')
      })
      
      // Submit form on photo upload
      document.getElementById('adopter_profile_form').submit()
    })
  }

  function initializeAddPetPhoto() {
    if (!$('.pet_list').length) return
    preloadCloudinaryWidget()

    $('.pet_list').on('click', '.add_pet_photo', async function (e) {
      e.preventDefault()

      if (isCloudinaryWidgetOpen()) return

      var results = await openCloudinaryWidget({
        tags: ['pet_profile'],
        multiple: false,
        context: { pet_id: $(this).parent().attr('data-id') },
        multiple: false,
        maxImageWidth: 1500,
        maxImageHeight: 1500,
      })

      if ($(this).attr('data-id')) {
        deleteRecord(null, '/photos/' + $(this).attr('data-id'))
      }

      results.forEach(function (result) {
        var formData = new FormData()
        formData.append('photo', 'image/upload/' + result.info.path + '#' + result.info.signature)
        formData.append('ownable_id', result.info['context']['custom'].pet_id)
        formData.append('ownable_type', 'Pet')

        $.ajax({
          url: '/photos',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          success: function (photoResults) {
            var pet = $('.pet_list').find('[data-id="' + result.info['context']['custom'].pet_id + '"]')
            pet.find('img').attr('src', result.info.secure_url)
            pet.attr('data-photo', photoResults.data.url.url)
            pet.find('.add_pet_photo').html('Change Photo').attr('data-id', photoResults.data.id)
          },
          error: function (xhr) {
            if ((xhr.status = 401)) {
              window.location.reload()
            }
          },
        })
      })
    })
  }

  //add new child to db
  function initializeNewChildForm() {
    $('#new_child').submit(function (e) {
      e.preventDefault()

      $.ajax({
        url: '/children',
        type: 'POST',
        data: $('#new_child').serialize(),
        success: function (result) {
          $('#user_no_children_input').addClass('hidden')

          closeModal(ADD_CHILD_MODAL_NAME)

          $('.child_list').append(
            '<div class="child c-tag p-5" data-id="' +
              result.data.id +
              '">' +
              result.data.age +
              ' \
              <a class="delete_child" href="">\
              <i class="fa fa-close"></i>\
              </a></div>'
          )
        },
        error: function (xhr) {
          if (xhr.status == 401) {
            window.location.reload()
          } else {
            $('#new_child .error_message').remove()
            $('#new_child .error').removeClass('error')

            $.each(xhr.responseJSON.errors, function (index, value) {
              $('#child_' + index + '_input').addClass('error')
              $('#child_' + index + '_input').append('<p class="error error_message">' + value[0] + '</p>')
            })
          }
        },
      })
    })
  }

  function deleteRecord(record, url) {
    return $.ajax({
      url: url,
      type: 'DELETE',
      success: function () {
        if (record) record.remove()
      },
      error: function (xhr) {
        if (xhr.status == 401) {
          window.location.reload()
        }
      },
    })
  }

  //delete exsisting child
  $('.child_list').on('click', '.delete_child', function (e) {
    e.preventDefault()

    if (window.confirm('Are you sure you want to delete this child?')) {
      deleteRecord($(this).parent(), '/children/' + $(this).parent().attr('data-id'))
      if ($('.child_list .child').length === 1) {
        $('#user_no_children_input').removeClass('hidden')
      }
    }
  })

  $('.edit_user_profile .photo').on('click', '.remove-photo', async function (e) {
    e.preventDefault()

    await deleteRecord($(this).parent(), '/photos/' + $(this).attr('data-id'))
    updateUserPhotoCountState()
  })

  // delete exsisting pet
  $('.pet_list').on('click', '.delete_pet', function (e) {
    e.preventDefault()

    if (window.confirm('Are you sure you want to delete this pet?')) {
      deleteRecord($(this).parent(), '/pets/' + $(this).parent().attr('data-id'))

      if ($('.pet_list .pet').length === 1) {
        $('#user_no_pets_input').removeClass('hidden')
      }
    }
  })

  // add/update pet to db
  $('#new_pet').submit(function (e) {
    var buttonText = $('#pet_submit_action button').html()
    var url, type

    $('#pet_submit_action button').html('Loading ')
    $('#pet_submit_action button').append(' <i class="fa fa-spinner fa-pulse"></i>')

    e.preventDefault()

    if ($('#pet_id').val().length === 0) {
      url = '/pets'
      type = 'POST'
    } else {
      type = 'PATCH'
      url = '/pets/' + $('#pet_id').val()
    }

    $.ajax({
      url: url,
      type: type,
      data: $('#new_pet').serialize(),
      success: function (result) {
        var imagePath, image

        $('#user_no_pets_input').addClass('hidden')
        $(`.add_pet ${CLOSE_BUTTON_SELECTOR}`).trigger('click')

        if (type === 'PATCH') {
          var pet = $('.pet_list').find('[data-id="' + result.data.id + '"]')

          pet.find('.pet_name').html(result.data.name)
          $.each(result.data, function (index, value) {
            pet.attr('data-' + index, value)
          })
        } else {
          imagePath = PetRescue.env.imagePlaceholder.bunny

          if (result.data.species_id === 1) {
            imagePath = PetRescue.env.imagePlaceholder.dog
          } else if (result.data.species_id === 2) {
            imagePath = PetRescue.env.imagePlaceholder.cat
          }

          image = $('<img>', {
            src: imagePath,
            alt: result.data.name,
            class: 'pet_img',
          }).prop('outerHTML')

          $('.pet_list').append(
            '<div class="pet" data-id="' +
              result.data.id +
              '" data-name="' +
              result.data.name +
              '" data-gender="' +
              result.data.gender +
              '" data-species_id="' +
              result.data.species_id +
              '" data-personality="' +
              result.data.personality +
              '" data-dob="' +
              result.data.dob +
              '" data-size="' +
              result.data.size +
              '">\
            ' +
              image +
              '\
            <p class="pet_name">' +
              result.data.name +
              '</p>\
            <a class="edit_modal open-pet-modal" data-action="open-modal" data-modal="add_pet" data-title="Edit pet" data-button-text="Update Pet" href="">Edit</a>\
            <a class="add_pet_photo" href="">Add Photo</a>\
            <a class="delete_pet" href="">Delete</a>\
            </div>'
          )
        }
      },
      error: function (xhr) {
        if (xhr.status == 401) {
          window.location.reload()
        } else {
          $('#pet_submit_action button').html(buttonText)
          $('#new_pet .error_message').remove()
          $('#new_pet .error').removeClass('error')

          $.each(xhr.responseJSON.errors, function (index, value) {
            $('#pet_' + index + '_input').addClass('error')
            $('#pet_' + index + '_input').append('<p class="error error_message">' + value[0] + '</p>')
          })
        }
      },
    })
  })

  // pre fill edit modal with pet data
  var checkboxes = ['gender', 'size']
  var attrs = ['id', 'size', 'personality', 'gender', 'name', 'dob', 'species_id', 'photo']

  //hide certain fields on open
  $('.registration__fieldset').on('click', '.open-pet-modal', function () {
    $('#pet_id').val('')
    $('.dog-option').addClass('hidden')

    $('.add_pet .c-modal h2').html($(this).attr('data-title'))

    $('#pet_submit_action button').html($(this).attr('data-button-text'))

    if ($(this).hasClass('edit_modal')) {
      var val,
        speciesId,
        pet = $(this).parent()

      $.each(attrs, function (_, value) {
        val = pet.attr('data-' + value)

        $('#pet_' + value)
          .val(val)
          .trigger('change')

        if ($.inArray(value, checkboxes) >= 0) {
          $('#pet_' + value).val(val)
          $('#pet_' + value + '_' + val).prop('checked', true)
        }

        speciesId = pet.attr('data-species_id')

        if (value === 'size' && speciesId === '1') {
          $('#pet_size_input').removeClass('hidden')
        }

        if (value === 'photo' && val != 'false') {
          $('.add_pet .image .icon img').removeClass('hidden')
          $('.add_pet .image .icon img').attr('src', val)
        }

        if (value === 'dob') {
          var d = new Date(val)
          $('#pet_dob_1i').val(d.getFullYear()).trigger('change')
          $('#pet_dob_2i')
            .val(d.getMonth() + 1)
            .trigger('change')
        }
      })
    }
  })

  //update breed field depending on spieces
  $('#pet_species_id').on('change', function (e) {
    var id = $(e.currentTarget).val()

    $('.dog-option').addClass('hidden')

    if (id === '1') {
      $('.dog-option').removeClass('hidden')
    }
  })

  $('#user_no_children').click(function () {
    $('.child_list div, .add_child_button').toggleClass('hidden')
  })

  $('#user_no_pets').click(function () {
    $('.pet_list div, .add_pet_button').toggleClass('hidden')
  })

  $(CLOSE_BUTTON_SELECTOR).on('click', function () {
    $('.add_pet .icon img').addClass('hidden')
  })

  $('#user_submit_action').click(function () {
    $('#user_submit_action button').html('Loading ')
    $('#user_submit_action button').append('<i class="fa fa-spinner fa-pulse"></i>')
  })

  //slider on adopter profile
  if ($('.image_silder')) {
    var slider = $('.image_silder')
    slider.on('unslider.ready', function () {
      if ($('.unslider-carousel li').length < 2) {
        $('.unslider-arrow').hide()
      }
    })

    slider.on('unslider.change', function (_, index) {
      $('.current_photo_number').html(index + 1)
    })

    slider.unslider()
  }
})
