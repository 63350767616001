import $ from 'cash-dom'
import { createLightbox } from 'js/lib/lightbox'

const LIGHTBOX_SELECTOR = '.js-c-attachment--lightboxed'

initialize()

function initialize() {
  renderLightBox()
}

function renderLightBox() {
  if (!$(LIGHTBOX_SELECTOR).length) return
  createLightbox({ selector: LIGHTBOX_SELECTOR })
}

// Allows for rendering the attachment list dyncamically.
export function renderFromFileList(fileListState, $attachmentList, { onRemoveFile }) {
  const $template = $('#c-attachment-template')

  if (!$template.length) throw new Error('No template supplied for rendering file attachment list')

  $attachmentList = $attachmentList || $('.js-c-attachment-list')
  $attachmentList.empty()

  const attachmentMarkup = fileListState.map(file => {

    // A template element is used to generate the HTML needed.
    const template = $template.get(0).content.cloneNode(true);

    // Add an event handler to remove the file once clicked - important that this is triggered once then
    // removed so it can be garbage collected
    if (onRemoveFile) {
      $(template).find('.js-c-attachment__remove-btn').one('click', () => onRemoveFile(file))
    }

    const $attachmentPreviewSlot = $(template).find('[data-slot="attachment-preview"]')
    const $fileNameSlot = $(template).find('[data-slot="file-name"]')
    const $fileSizeSlot = $(template).find('[data-slot="file-size"]')

    $fileSizeSlot.text(getFileSize(file))
    $fileNameSlot.text(file.name)

    // If the file is an image, we want to change the div to an img tag so we can
    // show a preview of it. We duplicate all the attributes across in the process to
    // keep functionality consistent
    if (isFileImage(file)) {
      const attachmentPreviewImgEl = document.createElement('img')
      copyAttributes($attachmentPreviewSlot.get(0), attachmentPreviewImgEl)

      attachmentPreviewImgEl.src = window.URL.createObjectURL(file)
      $attachmentPreviewSlot.replaceWith(attachmentPreviewImgEl)
    } else {
      $attachmentPreviewSlot.text(getFileExtension(file))
    }

    return $(template)
  })

  $attachmentList.append(...attachmentMarkup)
}

// Calculate file sizes
// https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications
function getFileSize(file) {
  const numberOfBytes = file.size

  // Approximate to the closest prefixed unit
  const units = ["B", "KiB", "MiB", "GiB"];
  const exponent = Math.min(
    Math.floor(Math.log(numberOfBytes) / Math.log(1024)),
    units.length - 1,
  )
  const approx = numberOfBytes / 1024 ** exponent;
  const output = exponent === 0
    ? `${numberOfBytes} bytes`
    : `${approx.toFixed(3)} ${units[exponent]}`

  return output
}

function isFileImage(file) {
  return file.type.split('/')[0] === 'image';
}

// Handles some edge cases
// https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/1203361#1203361
function getFileExtension(file) {
  return file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
}

function copyAttributes(source, target) {
  return Array.from(source.attributes).forEach(attribute => {
    target.setAttribute(
      attribute.nodeName === 'id' ? 'data-id' : attribute.nodeName,
      attribute.nodeValue,
    );
  });
}
