import { initHelpScoutBeacon } from 'components/helpscout_beacon/component'
import { on } from 'js/lib/events'

const helpscoutLinks = document.querySelectorAll('.js-c-helpscout-link')

helpscoutLinks.forEach(link => on('click', link, event => handleClick(event, link)))

function handleClick(event) {
  event.preventDefault()
  const hasBeaconInitiated = initHelpScoutBeacon()
  if (hasBeaconInitiated) {
    window.Beacon('toggle')
  }
}
