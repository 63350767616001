import { protectFromUnsavedChanges } from 'js/lib/protect-from-unsaved-changes'
import { differenceInYears, differenceInMonths, differenceInWeeks, add, sub } from 'date-fns'
import { debounce } from 'js/lib/general-utils'

$(document).ready(function () {
  if (!$('.listing_form').length) return

  // Add protection to the form inputs so that if the user exists early, they are notified that they have
  // unsaved changes. Remove the protection when the form submits so that the protection doesn't block that submission
  const cleanupUnsavedProtection = protectFromUnsavedChanges(
    '.listing_form input, .listing_form select, .listing_form textarea'
  )
  $('.listing_form form').on('submit', () => cleanupUnsavedProtection())

  const $listingOwnerSelect = $('.js-listing-owner')
  var $contactDetailsSource = $('input[name="listing[contact_details_source]"]')
  var $yearsSelect = $('#listing_age_years')
  var $monthsSelect = $('#listing_age_months')
  var $weeksSelect = $('#listing_age_weeks')
  var $ageSelects = $yearsSelect.add($monthsSelect).add($weeksSelect)
  var $dateInput = $('input.ui-datepicker-item')
  var dateAsAge = function (dateString) {
    var date = new Date(dateString)
    var result = {}

    result['years'] = differenceInYears(new Date(), date)
    date = add(date, { years: result['years'] })
    result['months'] = differenceInMonths(new Date(), date)
    date = add(date, { months: result['months'] })
    result['weeks'] = differenceInWeeks(new Date(), date)

    return result
  }

  handleUpdateEmailNoticeState()

  $('input[name="listing[name]"]').on(
    'change keyup',
    debounce(function () {
      var name = $(this).val()

      if (name.match(/\btrial\b|\bhold\b|\badoption\b|\bpending\b|\benquiries\b|\bapplications\b/i)) {
        $('.name_contains_status_note').show()
      } else {
        $('.name_contains_status_note').hide()
      }
    }, 500)
  )

  $listingOwnerSelect.on('change', handleUpdateEmailNoticeState)

  /*
    When a new contact detial source changes, update the contact inputs.
    Note that the "user" actually referes to the listing "owner" (@listing.user).
    There is a known bug here where this js doesn't account for if someone changes the listing owner. If you change the
    listing owner to a new user, then the contact details here won't be updated to that person
  */
  $contactDetailsSource.change(function () {
    const oldSource = getCurrentContactDetailsSource()
    const currentInputSource = $(this).val()

    // When the contact details change, make sure we update the email notice accordingly
    handleUpdateEmailNoticeState()

    // update the inputs for the contact details so that they correctly show the values for each type of contact
    $('input, select')
      .filter('[data-group]')
      .each(function () {
        const $element = $(this).prop('disabled', currentInputSource !== 'self')
        const oldValue = $element.val()

        $element.val($element.data(currentInputSource))

        if (oldSource === 'self') {
          $element.data({
            self: oldValue,
          })
        }
      })
  })

  $dateInput.datepicker({
    buttonImage: PetRescue.env.imagePlaceholder.formCalendar,
    showOn: 'button',
    buttonImageOnly: true,
    maxDate: new Date(),
    showOtherMonths: true,
    selectOtherMonths: true,
    changeYear: true,
    changeMonth: true,
    dateFormat: 'yy-mm-dd',
    onSelect: function (val) {
      var age = dateAsAge(val)

      $yearsSelect.val(age.years)
      $monthsSelect.val(age.months)
      $weeksSelect.val(age.weeks)

      $ageSelects.trigger('change', true)
    },
  })

  $ageSelects.on('change', function (_, triggered) {
    var dob = new Date()
    var years = $yearsSelect.val() || 0
    var months = $monthsSelect.val() || 0
    var weeks = $weeksSelect.val() || 0

    if (triggered) {
      return
    }

    dob = sub(dob, { years: years })
    dob = sub(dob, { months: months })
    dob = sub(dob, { weeks: weeks })

    $dateInput.datepicker('setDate', dob)
  })

  $(document).on('click', '.postcode-live-search li', function () {
    if ($(this).attr('data-state')) {
      setInterstateDefault($(this).attr('data-state'))
      displayStateSpecificFields()
    }
  })

  $(document).on('change', '#listing_adoptable_in_abbrs_input input[type="checkbox"]', displayStateSpecificFields)
  displayStateSpecificFields()

  /*
   * Interstate checkboxes will have a default readonly state checked that
   * represents the listing's location source state.
   *
   * We hide the checkbox to enfore a readonly state.
   *
   * This will not revert any previous interstate defaults. So if the location state
   * is changed the prior interstate default will remain checked.
   */
  function setInterstateDefault(stateAbbr) {
    const $input = $('#listing_adoptable_in_abbrs_' + stateAbbr.toLowerCase())
    $input.prop('checked', true)
    $('#listing_adoptable_in_abbrs_input li.choice').css('display', 'list-item')
    $input.closest('li.choice').css('display', 'none')
  }

  /*
   * Return array of all interstate checkboxes checked.
   * Array consists of lower cased state abbreviations.
   */
  function checkedInterstate() {
    var states = []
    $('#listing_adoptable_in_abbrs_input input:checked').each(function () {
      states.push($(this).val().toLowerCase())
    })
    return states
  }

  /*
   * Display state specific fields such as microchip, source number, etc
   * Based off checked items in the interstate input group.
   *
   * The HTML uses data-states attributes to denote whether an element is specific
   * to a state or not. E.g. data-states="wa sa vic"
   *
   * You can also specify that an element should NOT be displayed with a '-' prefix.
   * For example, data-states="wa sa -vic".
   *
   * The exclusion rule wins over the inclusion rule. If you have,
   * data-states="wa -vic" then the element will display when wa is checked,
   * but not when wa and vic is checked.
   *
   * In use at app/views/admin/groups/listings/_identification.html.haml
   */
  function displayStateSpecificFields() {
    var states = checkedInterstate()

    // Hide and disable all state specific fields.
    var $stateEls = $('[data-states]')
    var $stateInputs = $('input[data-states], select[data-states], textarea[data-states]')
    $stateInputs.prop('disabled', true)
    $stateEls.hide()

    // Enable and show interstate checked state specific fields.
    states.forEach(function (state) {
      // enable and show.
      const $els = $('[data-states~="' + state + '"]')
      $els.show()
      const $inputEls = $('[data-states~="' + state + '"]')
      $inputEls.prop('disabled', false)
    })

    // Disable and hide anti-states.
    states.forEach(function (state) {
      const $els = $('[data-states~="-' + state + '"]')
      $els.hide()
      const $inputEls = $('[data-states~="-' + state + '"]')
      $inputEls.prop('disabled', true)
    })
  }

  if (window.location.hash === '#identification') {
    var $container = $('.listings--identification__state:visible')

    if ($container.length > 0) {
      $(window).scrollTop($container.offset().top)
    }
  }

  $('.character-counter')
    .find(':input[maxlength]')
    .each(function () {
      var $el = $(this)
      var maxLength = parseInt($el.attr('maxlength'))
      var remainder = maxLength - $el.val().length

      const $counterWrapper = $("<div class='character-count'> characters remaining</div>")
      const $counterElement = $("<span class='count'>" + remainder + '</span>')
      $counterWrapper.prepend($counterElement).insertAfter($el)
    })

  const updateCounter = debounce(function () {
    var counter = $(this).parent().find('.character-count span')
    const remainder = this.maxLength - this.value.length

    counter.html(remainder)
    $(this).toggleClass('low', remainder <= 50)
  }, 250)

  $('.character-counter').find(':input[maxlength]').on('keyup', updateCounter)
})

function getCurrentContactDetailsSource() {
  const $contactDetailsSource = $('input[name="listing[contact_details_source]"]')
  return $contactDetailsSource.filter(':checked').val()
}

function handleUpdateEmailNoticeState() {
  const currentSource = getCurrentContactDetailsSource()
  const $emailNotice = $('.js-listing-email-notice')
  const $listingOwnerSelect = $('.js-listing-owner')

  const usersFullName = $listingOwnerSelect.find('option:selected').text()

  if (currentSource != 'user') {
    $emailNotice.find('.js-email-notification-notice__name').text(`${usersFullName} (the owner of this pet listing)`)
    $emailNotice.removeClass('hidden')
  } else {
    $emailNotice.addClass('hidden')
  }
}
