import I18n from 'js/lib/i18n.js.erb'
;(function ($) {
  $(document).ready(function () {
    if ($('body').hasClass('verifications-setup')) {
      var $mobileInput = $('#verification_settings_number')

      $mobileInput.intlTelInput({
        initialCountry: 'AU',
        formatOnInit: true,
        hiddenInput: $mobileInput.prop('name'),
        autoPlaceholder: 'aggressive',
        customPlaceholder: function () {
          return I18n.t('intl_tel_input.placeholder')
        },
      })
    }
  })
})(jQuery)
