const linkToggle = document.querySelectorAll('.js-c-accordion')
linkToggle.forEach(init)

function init(parent) {
  const titleClickElement = parent.querySelector('.js-c-accordion-title')
  const container = parent.querySelector('.js-c-accordion-body')

  const isActive = () => container.classList.contains('active')

  // Need to do this initially if open to begin with so that the height is set
  if (isActive()) setHightOfContainerToOpen(container, true)

  titleClickElement.addEventListener('click', handleAccordionClick)

  function handleAccordionClick() {
    if (!isActive()) {
      handleAccordionOpen(parent, container)
    } else {
      handleAccordionClose(parent, container)
    }
  }
}

function handleAccordionClose(parent, container) {
  const chevron = parent.querySelector('.js-c-accordion-chevron')
  chevron.classList.add('fa-chevron-down')
  chevron.classList.remove('fa-chevron-up')
  parent.setAttribute('aria-expanded', false)
  container.setAttribute('aria-hidden', true)
  container.style.height = '0px';
  container.addEventListener('transitionend', () => {
    container.classList.remove('active')
    parent.classList.remove('active')
  }, { once: true })
}

function handleAccordionOpen(parent, container) {
  const chevron = parent.querySelector('.js-c-accordion-chevron')
  chevron.classList.add('fa-chevron-up')
  chevron.classList.remove('fa-chevron-down')

  parent.classList.add('active')
  parent.setAttribute('aria-expanded', true)

  container.setAttribute('aria-hidden', false)
  container.classList.add('active')
  
  setHightOfContainerToOpen(container)
}

function setHightOfContainerToOpen(container, isAlreadyOpen = false) {
  container.style.height = 'auto'
  const height = container.clientHeight + 15 + 'px'
  if (!isAlreadyOpen) container.style.height = '0px'
  setTimeout(() => (container.style.height = height), 0);
}