;(function ($) {
  $.petrescue = $.petrescue || {}

  $.petrescue.init = function (guardSelector, callback) {
    var called = false
    var $scopes = $([window.setTimeout, $(document).ready])

    if ($.isFunction(callback)) {
      $scopes.each(function (_, scope) {
        scope(function () {
          if ($(guardSelector).length !== 0 && !called) {
            called = true
            callback()
          }
        })
      })
    }
  }
})(jQuery)
