//GA tracking of navigation
import { createAnalyticsGroup } from 'js/lib/analytics'
const track = createAnalyticsGroup('Navigation Clicks')

$(document).ready(function () {

  $('.js-toggleMenu').on('click', function() {
    toggleMenu()
  })

  $('.sidebar-nav__close-button').on('click', function(e) {
    e.preventDefault();
    toggleMenu()
  })

  function toggleMenu() {
    $('body').toggleClass('sidebar-open')
    toggleHamburger()
  }

  function toggleHamburger() {
    const $hamburger = $('.js-header__hamburger')

    $hamburger.toggleClass('open')
    const isOpen = $hamburger.hasClass('open')
    const text = isOpen ? 'Close menu' : 'Menu'

    $('.js-header-subnav__menu-text').text(text)

    if (isOpen) {
      track('open',  'sidebar')
    } else {
      track('close',  'sidebar')
    }
  }
})
