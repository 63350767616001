import { formatDistanceToNow } from 'date-fns'

// Check if it exists
if ($('.js-c-recently-adopted').length > 0) {
  const listingData = JSON.parse(document.getElementById('js-listing-data').getAttribute('data-listing-data'))

  for (var i = 0; i < listingData.length; i++) {
    const obj = listingData[i]
    const adoptedAt = new Date(obj.adopted_at)
    var showIndex = 0

    $('.js-c-recently-adopted_container').append(
      '<div class="slides slide-' +
        [i] +
        '"><div class="js-c-recently-adopted_img"><img src="' +
        obj.photo +
        '"/></div><div class="slides-details"><h1 class="name">' +
        obj.name +
        '</h1><h3 class="adopted_at">Adopted ' +
        formatDistanceToNow(adoptedAt) +
        ' ago</h3></div></div>'
    )
    // Hide slides until fully loaded
    $('.slides').hide()

    // Wait for listings to load
    $(window).on('load', function () {
      $('.pet_loader').hide()
      $('.slide-' + showIndex).show()
      $('#js-next-recently-adopted').show()

      document.getElementById('js-next-recently-adopted').onclick = nextSlide
    })

    function nextSlide() {
      $('.slide-' + showIndex).fadeOut()

      showIndex = showIndex + 1

      // Returns back to beginning (0) after 5
      if (showIndex == 5) {
        showIndex = 0
      }
      $('.slide-' + showIndex).fadeIn()
    }
  }
}
