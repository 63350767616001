/*
  Event guide: https://support.google.com/analytics/answer/1033068#Anatomy
  Submitting events: https://developers.google.com/analytics/devguides/collection/gtagjs/events

  action: the doing part of the event "click", "play", "pause" or something to describe target of the event
  label: a specific identifier for the event, e.g. which nav item, or what video, what file, etc etc
  category: The group of events that is taking place.

  Note that it's not nessicary to await this function unles you specifically want to wait for the event to
  finish sending. The event will always be sent before the page unloads.
*/
async function trackGenericEvent(unParsedAction, unParsedCategory, unParsedLabel) {
  if (!window.gtag) {
    throw new Error("Gtag isn't loaded - make sure it's loaded before tracking events")
  }

  // we parse the tracking data so that it conforms to standard - all lower case
  const [action, category, label] = [unParsedAction, unParsedCategory, unParsedLabel].map(str => {
    if (str) {
      return str.trim().toLowerCase()
    }
  })

  return new Promise(resolve => {
    // if the envionment is dev DONT send data to google analytics. This is so that we don't pollute
    // our tracking with our testing or broken data while we work on a feature. Instead, just print to the
    // console
    if (PetRescue.env.environment === 'development') {
      console.info('Tracking event triggered', { category, label, action })
      return resolve()
    }

    // set a timeout to resolve in the event gtag fails to send the event. This way awaited events don't disrupt
    // the critical path.
    setTimeout(resolve, 500)

    // Support both universal and GA4 properties
    // https://support.google.com/analytics/answer/9310895

    // We can actually track any kind of data as part of GA4. Once we have enough historical data we should
    // switch over to that event interface:
    // https://support.google.com/analytics/answer/11091025#zippy=%2Cin-this-article

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      event_callback: resolve,
    })
  })
}

export function createAnalyticsGroup(category) {
  return (action, label) => trackGenericEvent(action, category, label)
}

export function trackFbpEvent(event, data = {}, config = {}) {
  if (PetRescue.env.environment === 'development') {
    console.info('Fb tracking event triggered', { event, ...data, ...config })
    return
  }

  if (!window.fbq) return Promise.resolve()

  return new Promise(resolve => {
    window.fbq('track', event, data, config)
    resolve()
  })
}

/*
  Purchasing tracking helpers
*/

export function trackDonationIntentChange({ pageName, value, frequency }) {
  trackFbpEvent('AddPaymentInfo', { currency: 'AUD', content_name: pageName, value, frequency })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/ecommerce
export function trackPurchase({ transaction_id, affiliation, currency, value, frequency, content_name }) {
  const gaPayload = { transaction_id, affiliation, currency, value }
  const fbPayload = { value, currency, frequency, content_name }

  trackFbpEvent('Donate', fbPayload, { eventID: transaction_id })

  // Don't trigger in dev so we don't pollute our analytics
  if (PetRescue.env.environment === 'development') {
    console.info('Payment tracking event triggered', gaPayload)
    return
  }

  window.gtag('event', 'purchase', gaPayload)
}

// https://support.google.com/google-ads/answer/6331314
export function trackConversion({
  transaction_id,
  account_id = 'AW-363067286',
  conversion_segment = 'B7WuCOXs1csCEJbvj60B',
}) {
  const gaPayload = { send_to: `${account_id}/${conversion_segment}`, transaction_id }

  // Don't trigger in dev or staging so we don't pollute our analytics
  if (PetRescue.env.environment !== 'production') {
    console.info('Conversion tracking event triggered', gaPayload)
    return
  }

  window.gtag('event', 'conversion', gaPayload)
}

// Inline tracking for use in articles etc...
// Usage example: <a href="https://example.com" class="js-track-click" data-category="monthly-report">Link to page from monthly report article</a>

;[].forEach.call(document.querySelectorAll('.js-track-click'), function (el) {
  const track = createAnalyticsGroup(el.getAttribute('data-category'))

  el.addEventListener('click', function () {
    track('click', el.innerHTML)
  })
})
