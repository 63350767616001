function initializeMarkdownEditor() {
  const textarea_containers = document.querySelectorAll('.js-c-form-input--textarea-md')
  textarea_containers.forEach(container => {
    const textarea = container.querySelector('textarea')
    
    if (textarea) {
      setupToolbar(textarea);
    }
  })
}

// Function to set up the toolbar for Markdown formatting
function setupToolbar(textarea) {
  document.querySelectorAll('.js-textarea-md-toolbar-btn').forEach(button => {
    button.addEventListener('click', function(e) {
      e.preventDefault();

      
      const action = Array.from(this.classList).find(cls => 
        cls.startsWith('js-textarea-md-toolbar-btn--')
      );
    
      if (action) {
        const formattedAction = action.replace('js-textarea-md-toolbar-btn--', '')
        let text = '';

        switch (formattedAction) {
          case 'h1':
            text = '# Heading 1\n'
            break;
          case 'h2':
            text = '## Heading 2\n'
            break;
          case 'h3':
            text = '### Heading 3\n'
            break;
          case 'bold':
            text = '__bold text here__'
            break;
          case 'italic':
            text = '_italic text here_'
            break;
          case 'ul':
            text = '\n* Item 1\n* Item 2\n* Item 3\n\n'
            break;
          case 'ol':
            text = '\n1. Item 1\n2. Item 2\n3. Item 3\n\n'
            break;
          case 'link':
            text = '[Link](https://petrescue.org.au/)'
            break;
        }

        insertAtCaret(textarea, text)
      }
    });
  });
}

function insertAtCaret(textarea, text) {
  const startPos = textarea.selectionStart;

  const beforeCaret = textarea.value.substring(0, startPos)
  const afterCaret = textarea.value.substring(startPos)
  textarea.value = beforeCaret + text + afterCaret

  textarea.selectionStart = startPos + text.length
  textarea.selectionEnd = textarea.selectionStart
  textarea.focus()
}

document.addEventListener('DOMContentLoaded', initializeMarkdownEditor)
