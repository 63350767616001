import { CLOSE_BUTTON_SELECTOR } from 'components/modal/component'

;(function ($) {
  $(document).ready(function () {
    $('#update_details_form .submit').on('click', function (e) {
      e.preventDefault()
      $.ajax({
        type: 'PATCH',
        url: '/admin/group_profile',
        dataType: 'json',
        data: $('#update_details_form').serialize(),
        success: function () {
          $(`.group_details_update ${CLOSE_BUTTON_SELECTOR}`).click()
        },
        error: function (xhr) {
          if (xhr.status == 401) {
            window.location.reload()
          } else {
            $('#update_details_form .error_message').remove()
            $('#update_details_form .error').removeClass('error')

            $.each(xhr.responseJSON.errors, function (model, errors) {
              $.each(errors, function (field, errorMessages) {
                $('#group_profile_' + model + '_' + field + '_input').addClass('error')
                $('#group_profile_' + model + '_' + field + '_input').append(
                  '<p class="error error_message">' + errorMessages[0] + '</p>'
                )
              })
            })
          }
        },
      })
    })

    $('.group_details_update .later').on('click', function (e) {
      e.preventDefault()
      $.ajax({
        url: '/admin/group_profile/later',
        success: function () {
          $(`.group_details_update ${CLOSE_BUTTON_SELECTOR}`).click()
        },
      })
    })

    $('.group_details_update .up_to_date').on('click', function (e) {
      e.preventDefault()
      $.ajax({
        url: '/admin/group_profile/up_to_date',
        success: function () {
          $(`.group_details_update ${CLOSE_BUTTON_SELECTOR}`).click()
        },
      })
    })
  })
})(jQuery)
