import Sortable from 'js/vendor/sortable.min'
import { openModal, closeModal } from 'components/modal/component'
import { notifyBugsnag } from 'js/lib/bugsnag'
import { requestNative } from 'js/lib/api/api-request'
import { wait } from 'js/lib/general-utils'
import { openCloudinaryWidget, isCloudinaryWidgetOpen, preloadCloudinaryWidget } from 'js/lib/cloudinary'

const MAX_IMAGES = 9
const MAX_VIDEOS = 4
const COMMON_CLOUDINARY_CONFIG = {
  thumbnailTransformation: { width: 80, height: 80, crop: 'fill' },
  maxImageWidth: 2000,
  maxImageHeight: 2000,
  sources: ['local', 'url', 'camera', 'dropbox', 'facebook', 'instagram', 'google_drive'],
}

const photoUploadButton = $('#js-c-listing-photo-uploader__button')
const videoUploadButton = $('#js-c-listing-video-uploader__button')
const getPlaceHolderEls = () => $('.js-c-listing-photo-uploader-placeholder')

initialize()

async function initialize() {
  if (!$('.c-listing-photo-uploader').length) return

  preloadCloudinaryWidget()

  $('#js-c-listing-photo-uploader__button, .js-c-listing-photo-uploader-placeholder').on('click', handlePhotoButtonClick)
  $('#js-c-listing-video-uploader__button, .js-c-listing-video-uploader-placeholder').on('click', handleVideoButtonClick)

  updatePhotoUIState()
  updateVideoUIState()

  $(document.body).on('click', '.photo img', function () {
    $(this).parent().find('.edit-photo').click()
  })

  // edit image
  $(document.body).on('click', '.edit-photo', function (event) {
    event.preventDefault()
    event.stopPropagation()
    
    handleEditPhoto(this)
  })

  createSortable('sortablePhotos')
  createSortable('sortableVideos')
}

async function handlePhotoButtonClick() {
  if (isCloudinaryWidgetOpen()) return
  disableBtns(photoUploadButton, getPlaceHolderEls())

  try {
    const results = await openCloudinaryWidget({
      ...COMMON_CLOUDINARY_CONFIG,
      resourceType: 'image',
      maxFiles: MAX_IMAGES - getPhotoCount(),
      tags: ['listing_photo'],
    })

    await uploadAndDisplay(results)
  } catch (error) {
    window.alert("Uh oh, we encountered an error! We've been notified, but please reach out if this continues")
    notifyBugsnag(error)
  }

  enableBtns(photoUploadButton, getPlaceHolderEls())
}

async function handleVideoButtonClick() {
  if (isCloudinaryWidgetOpen()) return
  disableBtns(videoUploadButton, getPlaceHolderEls())

  try {
    const results = await openCloudinaryWidget({
      ...COMMON_CLOUDINARY_CONFIG,
      resourceType: 'video',
      maxFiles: MAX_VIDEOS - getVideoCount(),
      tags: ['listing_video'],
    })

    await uploadAndDisplay(results)
  } catch(error) {
    window.alert("Uh oh, we encountered an error! We've been notified, but please reach out if this continues")
    notifyBugsnag(error)
  }

  enableBtns(videoUploadButton, getPlaceHolderEls())
}

function handleEditPhoto(editPhotoButton) {
  const imgTag = $(editPhotoButton).parent().find('img')

  if (imgTag.attr('src').includes('.gif')) {
    window.alert("Sorry, you can't crop a gif")
    return
  }

  $('#croppie_container').empty()

  openModal('edit_modal')

  const imageData = $(editPhotoButton).parent().data('photo') ? $(editPhotoButton).parent().data('photo') : []
  const url = $(editPhotoButton).data('url-path')
  const el = document.getElementById('croppie_container')
  const boundary = $(window).width() < 484 ? $(window).width() - 100 : 400
  const {x,y,x2,y2,a,z,w} = imageData.transformation || {}

  const crop = new Croppie(el, {
    viewport: { width: 247, height: 247 },
    boundary: { width: boundary, height: boundary },
    showZoomer: true,
    enableOrientation: true,
  })

  // Rotation is stored as mutable state so it can be reset when it gets to 360
  let rotate = a ? a : 0

  const zoom = z ? z : 1
  
  const orientation = (a === 90) ? 6 : (a === 180) ? 3 : (a === 270) ? 8 : 1

  const points = (w === 400) ? [x,y,x2,y2].map(i => i * 1.595)
    : w ? [x,y,x2,y2] : []

  const config = (imageData.transformation) ? { url, points, zoom, orientation }
    : { url, points, zoom, enableExif: true }

  crop.bind(config).then(() => $('.loader').hide())

  $('.cr-slider-wrap').append('<p>Zoom <i class="fa fa-search-plus" aria-hidden="true"></i></p>')

  $('.rotate').click(function () {
    crop.rotate(90)
    rotate = rotate != 360 ? rotate + 90 : (rotate = 90)
  })

  $('.edit_modal .save')
    .unbind()
    .click(async function () {

      const cropResult = await new Promise(resolve => crop.result('html').then(resolve))
      $('.edit_result').append(cropResult)
    
      const cropData = crop.get()

      await wait(100) // Not sure exactly why this is requried, probably to wait on dom update?
    
      const size =
        $('.edit_result img').height() > $('.edit_result img').width()
          ? $('.edit_result img').height()
          : $('.edit_result img').width()
      
      const [x, y, x2, y2] = cropData.points
      const a = rotate
      const z = cropData.zoom

      if (!$('.edit_result img').height() || !$('.edit_result img').width()) {
        $('#croppie_container p').empty()
        $('#croppie_container').append('<p class="error">Error occured while saving.</p>')
      }

      try {
        const data = { transformation: { x, y, a, w: size, h: size, z, x2, y2 } }
        const result = await requestNative({ url: imageData.id, type: 'PUT', data })

        imgTag.attr('src', result[1])
        imageData.transformation = { x, y, a, w: size, h: size, z, x2, y2 }
        imgTag.closest('.photo').attr('data-photo', JSON.stringify(imageData))
        
        closeModal('edit_modal')

        $('.edit_result').empty()
        $('.loader').show()
      } catch {
        $('#croppie_container p').empty()
        $('#croppie_container').append('<p class="error">Error occured while saving.</p>')
      }
    })
         
}

async function uploadAndDisplay(photos) {
  if (photos.length === 0) return

  const photoData = photos.map((photo, index) => {
    const { secure_url, public_id, resource_type } = photo.info
    const order = $('.js-c-listing-photo-uploader__thumbnail-img').length + index
    return { secure_url, public_id, resource_type, order }
  })

  const photoResults = await requestNative({ url: 'create', data: { photos: photoData } })

  photoResults.forEach(photo => {
    if (photo.media_type === 'image') {
      renderUploadedImage(photo)
    } else if (photo.media_type === 'video') {
      renderUploadedVideo(photo)
    }
  })
}

function getPhotoCount() {
  return $('.js-c-listing-photo-uploader__thumbnail').length
}

function getVideoCount() {
  return $('.js-c-listing-video-uploader__thumbnail').length
}

function updatePhotoUIState() {
  const photoUploadCount = getPhotoCount()
  $('.js-c-listing-photo-uploader__limit-count').text(photoUploadCount)
  if (MAX_IMAGES - photoUploadCount <= 0) {
    disableBtns(photoUploadButton)
  }
}

function updateVideoUIState() {
  const videoUploadCount = getVideoCount()
  $('.js-c-listing-video-uploader__limit-count').text(videoUploadCount)
  if (MAX_VIDEOS - videoUploadCount <= 0) {
    disableBtns(videoUploadButton)
  }
}

function disableBtns(...btns) {
  btns.forEach(btn => btn.prop('disabled', true).addClass('disabled')) 
}

function enableBtns(...btns) {
  btns.forEach(btn => btn.prop('disabled', false).removeClass('disabled'))
}

function renderUploadedImage(photo) {
  const photoElId = `photo-id-${photo.id}`
  const url = photo.source_url.split('upload/')
  const squaredUrl = url.join('upload/fl_lossy/')
  const image = $('<img />', {
    src: url.join('upload/h_200,w_200,c_fill,g_face,f_auto,q_auto:eco/'),
    class: 'c-listing-photo-uploader__thumbnail-img js-c-listing-photo-uploader__thumbnail-img',
    alt: 'listing photo',
  })

  //get placeholder to replace
  $('.js-c-listing-photo-uploader-placeholder').first().remove()

  if ($('.js-c-listing-photo-uploader__thumbnail').length > 0) {
    $('.js-c-listing-photo-uploader__thumbnail')
      .last()
      .after(
        $('<li>', {
          class: 'c-listing-photo-uploader__thumbnail js-c-listing-photo-uploader__thumbnail',
          id: photoElId,
          data: { photo: { id: photo.id, transformation: '', original_image_path: url } }
        })
      )
  } else {
    $('.js-c-listing-photo-uploader__list').prepend(
      $('<li>', {
        class: 'c-listing-photo-uploader__thumbnail js-c-listing-photo-uploader__thumbnail',
        id: photoElId,
        data: { photo: { id: photo.id, transformation: '', original_image_path: url } }
      })
    )
  }

  $('#' + photoElId).append(image)
  $('<a />', { 
    class: 'action edit-photo', text: 'Edit', href: '', rel: 'nofollow', 'data-action': 'open-modal', 
    'data-modal': 'edit_modal', 'data-url-path': squaredUrl
  }).appendTo('#' + photoElId)

  $('<a />', { 
    class: 'action remove-photo', html: '<i class="fa fa-trash"></i>', href: photo.id, rel: 'nofollow',
    'data-method': 'delete', 'data-confirm': 'Are you sure you want to delete this photo?'
  }).appendTo('#' + photoElId)

  updatePhotoUIState()
}

function renderUploadedVideo(photo) {
  const photoElId = `photo-id-${photo.id}`
  const url = photo.source_url.split('upload/')
  const videoImage = url.join('upload/w_200,h_200,c_fill/').replace(/\.[^/.]+$/, '')
  const image = $('<img />', { src: videoImage + '.jpg', class: 'c-listing-photo-uploader__thumbnail-img js-c-listing-photo-uploader__thumbnail-img', alt: 'listing photo' })

  //get placeholder to replace
  $('.js-c-listing-video-uploader-placeholder').first().remove()

  if ($('.js-c-listing-video-uploader__thumbnail').length > 0) {
    $('.js-c-listing-video-uploader__thumbnail')
      .last()
      .after(
        '<li class="c-listing-photo-uploader__thumbnail js-c-listing-video-uploader__thumbnail" id="' +
          photoElId +
          '"></li>'
      )
  } else {
    $('.js-c-listing-video-uploader__list').prepend(
      '<li class="c-listing-photo-uploader__thumbnail js-c-listing-video-uploader__thumbnail" id="' +
        photoElId +
        '"></li>'
    )
  }
  $('#' + photoElId).append('<div class="overlay video_uploader"></div>')
  $('#' + photoElId).append(image)
  $('<a />', { 
    class: 'action remove-photo', html: '<i class="fa fa-trash"></i>', href: photo.id, rel: 'nofollow',
    'data-method': 'delete', 'data-confirm': 'Are you sure you want to delete this video?'
  }).appendTo('#' + photoElId)

  updateVideoUIState()
}

function createSortable(sortableId) {
  const sortableEl = document.getElementById(sortableId)
  if (!sortableEl) return

  Sortable.create(sortableEl, {
    scroll: false,
    onUpdate: function () {
      const order = Array()
      $(`#${sortableId} .js-c-listing-photo-uploader__thumbnail`).each(function () {
        if ($(this).hasClass('js-c-listing-video-uploader-placeholder')) return
        const dataPhoto = $(this).data('photo')
        order.push(dataPhoto.id)
      })
      if (order.length) {
        $.ajax({ url: 'order_images', type: 'PUT', data: { order } })
      }
    },
  })
}
