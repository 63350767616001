import I18n from 'js/lib/i18n.js.erb'
import { initializePin, tokenizeFields } from 'js/lib/payments/pinpayments-service'
import { ValidationError } from 'js/lib/errors'
import { getRecaptchaResult } from 'components/recaptcha_widget/component'
import { collectPromises } from 'js/lib/general-utils'

const fieldConfig = {
  name: {
    selector: '#pin-card_name',
    placeholder: I18n.t('payments.pin.hosted_field.component.card_name.placeholder'),
  },
  number: {
    selector: '#pin-card_number',
    placeholder: I18n.t('payments.pin.hosted_field.component.card_number.placeholder'),
  },
  expiry: {
    selector: '#pin-card_expiry',
    placeholder: I18n.t('payments.pin.hosted_field.component.card_expiry.placeholder'),
  },
  cvc: {
    selector: '#pin-card_cvc',
    placeholder: I18n.t('payments.pin.hosted_field.component.card_cvc.placeholder'),
  },
}

const hostedFieldsStyles = {
  input: {
    padding: '10px',
    'font-size': '17px',
    'font-family':
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    color: '#000',
    'background-color': '#fff',
    'border-radius': '3px',
    border: '1px solid #ddd',
  },
  'input:focus': {
    outline: 'none',
    border: '1px solid #4D90FE',
  },
  'input::placeholder': {
    color: '#999',
  },
  'input::-moz-placeholder': {
    color: '#999',
    opacity: '1',
  },
  '.hosted-fields-invalid:not(:focus)': {
    color: 'red',
    border: '1px solid red',
  },
}

export async function initializePinPayment() {
  await initializePin(fieldConfig, hostedFieldsStyles)
}

export async function handlePinPayment(additionalValidation = () => Promise.resolve(), useRecaptcha = true) {
  try {
    // we run both actions at the same time so we can get the validation results of both at the same time
    const [token] = await collectPromises(tokenizeFields(getErrorText), additionalValidation())
    if (useRecaptcha) await getRecaptchaResult() // always run recaptcha *after* validation
    return { status: 'success', token }
  } catch (errors) {
    if (Array.isArray(errors)) {
      if (!errors.some(ValidationError.isValidationError)) throw errors
      const validationErrors = Object.assign({}, ...errors.map(error => error.detail))
      return { status: 'validation-error', validationErrors }
    }
    throw errors
  }
}

function getErrorText(code) {
  return I18n.t('payments.pin.hosted_field.component.errors.' + code)
}
