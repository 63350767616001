import { once } from 'js/lib/events'

const selects = document.querySelectorAll('.js-c-select')

selects.forEach(select => {
  // add placeholder styles if available
  if (hasPlaceholderAndIsSelected(select)) {
    once('change', select, () => removePlaceholderClass(select))
  }
})

function hasPlaceholderAndIsSelected(selectElement) {
  const placeholderElement = selectElement.querySelector('.js-c-select-placeholder')
  return placeholderElement && placeholderElement.selected
}

function removePlaceholderClass(selectElement) {
  selectElement.classList.remove('c-select--with-placeholder')
}
