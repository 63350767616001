export default {
  minZoomLevel: 4,
  initialZoomLevel: 4,
  tinyZoomLevel: 10,
  smallZoomLevel: 8,
  medZoomLevel: 7,
  largeZoomLevel: 6,
  searchZoomLevel: 12,
  initialCenter: { lat: -25.363, lng: 131.044 },
  markerSize: { width: 25, height: 25 },
}
