$(document).ready(function () {
  if ($('.listing_form').length) {
    var $breedSelect = $('#listing_breeds')
    var $listingBreedNames = $('#listing_breed_names')
    var $mixCheckbox = $('#listing_mix')
    var $speciesSelect = $('#listing_species_id')
    var $breedOutput = $('<p />', { id: 'breed_output' })
    var $listingAgeYearsSelect = $('select#listing_age_years')
    var $seniorWrapper = $('.senior-wrapper')
    var $seniorCheckbox = $('input#listing_senior')
    var minSeniorListingAge = parseInt($seniorWrapper.data('min-age'))

    // TODO: Remove reliance on hard-coded species ID numbers
    if ($.inArray($('#listing_species_id').val(), ['2', '1', '3', '4']) === -1) {
      $('.breeds_container').hide()
    }

    if ($.inArray($('#listing_species_id').val(), ['2', '1']) != -1) {
      $('#listing_shadow_breed_ids_input label').html('Breed(s)')
    }

    $speciesSelect.change(speciesSelectionUpdated)

    $breedSelect.on('select2:select', function (e) {
      var data = e.params.data
      var $option = $(data.element)
      var $firstAvailableOption = $(this).find('option:not(:selected):first')

      // Move selected options to top, with most recent
      // selection last
      $option.detach().insertBefore($firstAvailableOption)
    })

    $breedSelect.on('select2:unselect', function (e) {
      var data = e.params.data
      var $option = $(data.element)
      var $availableOptions = $(this).find('option:not(:selected)')
      var optionText = $option.text()
      var markerOption

      // Move deselected option back in to
      // alphabetically sorted unselected options
      $availableOptions.each(function (_, el) {
        if (optionText > el.text) {
          markerOption = el
          return true
        }
      })

      $option.insertAfter(markerOption)
    })

    initChosen()

    $('#listing_shadow_breed_ids_input').append('Breed display preview')
    $('#listing_shadow_breed_ids_input').append($breedOutput)
    $breedSelect.add($mixCheckbox).change(generateBreedOutput)

    $('.datepicker_container').click(function () {
      $('.ui-datepicker-trigger').click()
    })

    generateBreedOutput()

    $listingAgeYearsSelect
      .on('change', function (_, data) {
        var listingAge = parseInt($(this).val())
        var speed = data && data.triggered ? 0 : 'fast'

        if (listingAge >= minSeniorListingAge) {
          $seniorCheckbox.prop('disabled', false)
          $seniorWrapper.slideDown(speed)
        } else {
          $seniorCheckbox.prop('disabled', true)
          $seniorWrapper.slideUp(speed)
        }
      })
      .trigger('change', { triggered: true })

    var $medicalNotes = $('#listing_medical_notes');
    var $hasMedicalIssues = $('.js-has-medical-issues-input');

    $hasMedicalIssues.change(function() {
      if ($(this).val() === 'false') {
        $medicalNotes.val("No known pre-existing conditions.");
      } else if ($(this).val() === 'true' && $medicalNotes.val() === "No known pre-existing conditions.") {
        $medicalNotes.val('');
      }
    });

    function initChosen() {
      // 2017-05-26 - Legislation introduced in QLD stating that you must be a registered breeder in order to breed a dog legally
      var l = $('#listing_location_id option:selected').text()

      if (new Date($('#listing_date_of_birth').val()) < new Date('2017-05-26') || l.indexOf('QLD') === -1) {
        $('#listing_breeder_id_input abbr').addClass('hidden')
      }
    }

    function generateBreedOutput() {
      var breeds = []

      $('#listing_breeds option:selected').each(function () {
        breeds.push($(this).text())
      })

      var output = breeds.join(' x ')

      $listingBreedNames.val(output)
      if ($mixCheckbox.is(':checked') && breeds.length > 0) {
        output += ' mix'
      }
      $breedOutput.text(output)
    }

    function dogSelected() {
      $('.dog_option').removeClass('hidden')
      $('.breeds_container').show()
      $('#listing_shadow_breed_ids_input label').html('Breed(s)')
    }

    function catSelected() {
      $('.cat_option').removeClass('hidden')
      $('.breeds_container').show()
      $('#listing_shadow_breed_ids_input label').html('Breed(s)')
    }

    function guineaPigSelected() {
      $('#listing_coat_input').removeClass('hidden')
      $('.breeds_container').show()
    }

    function rabbitSelected() {
      $('#listing_vaccinated_input').removeClass('hidden')
      $('.breeds_container').show()
    }

    function resetSpeciesDependantFields() {
      $('.dog_option').add('.cat_option').addClass('hidden')
      $('#listing_shadow_breed_ids_input label').html('Breed(s) (optional)')
    }

    function speciesSelectionUpdated() {
      var id = $speciesSelect.val()
      var breedName = $speciesSelect.find(':selected').html()

      updateBreeds(id)
      resetSpeciesDependantFields()

      switch (breedName) {
        case 'Cat':
          return catSelected()
        case 'Dog':
          return dogSelected()
        case 'Guinea Pig':
          return guineaPigSelected()
        case 'Rabbit':
          return rabbitSelected()
        default:
          $('.breeds_container').hide()
      }
    }

    function updateBreeds(id) {
      var options = $('#options-' + id).html()

      $breedSelect.empty().append(options).trigger('change')
      $mixCheckbox.prop('checked', false).closest('span').removeClass('checked')
      $('#listing_breed_names').val('')
      $breedOutput.empty()
    }

    function updatePhoneRequired() {
      if (
        $('#listing_contact_preferred_method_10').is(':checked') &&
        $('#listing_display_contact_preferred_method_only').is(':checked')
      ) {
        $('#listing_contact_number_input abbr').hide()
      } else {
        $('#listing_contact_number_input abbr').show()
      }
    }
  
    updatePhoneRequired()
  
    $('.cat_intake_origin_option').change(function () {
      $('.cat_intake_origin_text').addClass('hidden')
      $('.' + $(this).val()).removeClass('hidden')
    })
  
    $('#listing_contact_preferred_method_input input').click(function () {
      updatePhoneRequired()
    })
  
    $('#listing_display_contact_preferred_method_only').click(function () {
      updatePhoneRequired()
    })
  }
})
