/*
  loadScript allows us to load scripts asyncronously and lazily when required. We also
  handle when loading the same script from multiple places so scripts aren't added more than
  once
*/

const LOAD_MAP = {}

export function loadScript(src, checkForExisting = true, attributes = { async: true }) {
  return resolveFromLoadMap(src, (resolve, reject) => {
    if (checkForExisting) {
      const existing = document.querySelectorAll(`script[src="${src}"]`)
      if (existing.length > 0) return resolve()
    }

    const scriptEl = document.createElement('script')
    scriptEl.setAttribute('src', src)

    Object.keys(attributes).forEach(key => {
      if (scriptEl[key] === undefined) {
        scriptEl.setAttribute(key, attributes[key])
      } else {
        scriptEl[key] = attributes[key]
      }
    })

    const handleLoad = () => resolve()
    const handleError = error => reject(error)

    scriptEl.addEventListener('load', handleLoad)
    scriptEl.addEventListener('error', handleError)

    document.body.appendChild(scriptEl)
  })
}

function resolveFromLoadMap(src, callback) {
  LOAD_MAP[src] ||= new Promise(callback)
  return LOAD_MAP[src]
}
