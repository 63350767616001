//GA tracking of navigation
import { createAnalyticsGroup } from 'js/lib/analytics'

export const track = createAnalyticsGroup('Navigation Clicks')

$('.header--external').on('click', '.js-tracked-nav-item', async event => {
  const name = event.target.dataset.name
  if (name) {
    track('click',  'Main Menu - ' + name)
  }
})

$('.header--member').on('click', '.header--member a', async event => {
  const name = event.target.text
  track('click', 'Submenu - ' + name)
})

$('.sidebar-nav').on('click', '.sidebar-nav__main-links a', async event => {
  const name = event.target.text
  track('click', 'Sidebar - ' + name)
})

$('.footer--external').on('click', '.footer--external__links a', async event => {
  const name = event.target.text
  track('click', 'Footer - ' + name)
})

// Mobile sidebar menu
if (window.innerWidth < 484) {
  $('.sidebar-nav__main-links').on('click', '[data-accordion-link]', function() {
    const targetKey = $(this).data('accordion-link')
    const $accordion = $(`[data-accordion="${targetKey}"]`)[0]
    console.log($accordion);

    // make the link active and change the height of the accordion box
    $(this).toggleClass('active')
    $accordion.style.maxHeight = $accordion.style.maxHeight ? null : $accordion.scrollHeight + "px"
  })
}

// Mobile member scroll bar
$('.header--member__navigation').scroll(function () {
  $(this).scrollLeft() > 30 ? $('.header--member i').fadeOut() : $('.header--member i').fadeIn()
})

// Mobile scroll
if ($('.js-header-subnav').length) {
  let headerSubNavStateShown = true
  function changeHeaderState(state) {
    if (state === 'in' && !headerSubNavStateShown) {
      $('.js-header-subnav').fadeIn()
      headerSubNavStateShown = true
    } else if (state === 'out' && headerSubNavStateShown) {
      $('.js-header-subnav').fadeOut()
      headerSubNavStateShown = false
    }
  }

  $(window).scroll(function () {
    if ($(this).scrollTop() > 30 && $(window).width() < 484) {
      changeHeaderState('out')
    } else {
      changeHeaderState('in')
    }
  })
}
