import { notifyBugsnag } from 'js/lib/bugsnag'

function initializeBreedSearch(breedSearch) {
  const speciesInput = document.querySelector(breedSearch.dataset.speciesSelectInput)
  
  if (!speciesInput) {
    notifyBugsnag(`Species input not found with selector: ${breedSearch.dataset.speciesSelectInput}`)
    return
  }

  speciesInput.addEventListener('change', event => {
    const speciesIdValue = speciesInput.value
    
    // Dispatch a custom 'reload' event to the select_search component to refresh the breeds list
    const reloadEvent = new CustomEvent('reload', { detail: { species_id: speciesIdValue } })
    breedSearch.dispatchEvent(reloadEvent) // Dispatch the event to trigger the refresh in select_search
  })
}

function initialize() {
  const breedSearches = document.querySelectorAll('.js-c-form-input-breedsearch .js-c-form-select-search')
  for (const breedSearch of breedSearches) {
    initializeBreedSearch(breedSearch)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  initialize()
})
