import { trackFbpEvent } from 'js/lib/analytics'

const componentEl = document.querySelector('.js-c-conversions-track-page-view')
if (componentEl) trackPageView(componentEl)

function trackPageView(el) {
  const eventID = el.dataset.eventId
  const pageName = el.dataset.pageName
  trackFbpEvent('ViewContent', { content_name: pageName }, { eventID })
}
