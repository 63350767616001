$(document).ready(function () {
  Mailcheck.defaultTopLevelDomains.push('org.au', 'net.au', 'gov.au')
  $('.email_check').on('blur', function () {
    var target = $(this).data('email-check-target')
    var $target

    if (target === 'parent') {
      $target = $(this).parent()
    } else {
      $target = $(this)
    }

    $target.parent().find('.email-suggestion').remove()
    $(this).mailcheck({
      suggested: function (element, suggestion) {
        var $suggestion = $('<a />', {
          href: 'javascript:void(0);',
          class: 'hint email-suggestion',
          text: 'Did you mean ' + suggestion.full + '?',
        }).on('click', function () {
          $(element).val(suggestion.full)
          $target.parent().find('.email-suggestion').remove()
        })

        $target.after($suggestion)
      },
    })
  })
})
