import { requestNative } from 'js/lib/api/api-request'
import { notifyBugsnag } from 'js/lib/bugsnag'

document.querySelectorAll('.c-form-select-search').forEach(selectComponentEl => initializeSelect(selectComponentEl))

function initializeSelect(selectComponentEl) {
  const csSelector = selectComponentEl.querySelector('.js-c-form-select-search-select') // the inputs and ul as a group
  const csInput = csSelector.querySelector('.js-c-form-select-search-input')
  const csInputHidden = csSelector.querySelector('.js-c-form-select-search-input-hidden')
  const csList = csSelector.querySelector('ul')
  const csStatus = selectComponentEl.querySelector('.custom-select-status')
  const searchEndpoint = selectComponentEl.dataset.searchEndpoint
  let queryParams = null
  let prevQueryParams = null

  let csOptions = csList.querySelectorAll('li')
  let aOptions = Array.from(csOptions)

  // when JS is loaded, set up our starting point
  // if JS fails to load, the custom select remains a plain text input
  // create and set start point for the state tracker
  let csState = 'initial'
  // inform assistive tech (screen readers) of the names & roles of the elements in our group
  csSelector.setAttribute('role', 'combobox')
  csSelector.setAttribute('aria-haspopup', 'listbox')
  csSelector.setAttribute('aria-owns', 'custom-select-list') // container owns the list...
  csInput.setAttribute('aria-autocomplete', 'both')
  csInput.setAttribute('aria-controls', 'custom-select-list') // ...but the input controls it
  csList.setAttribute('role', 'listbox')
  csOptions.forEach(function (option) {
    option.setAttribute('role', 'option')
    option.setAttribute('tabindex', '-1') // make li elements keyboard focusable by script only
  })
  // set up a message to keep screen reader users informed of what the custom input is for/doing
  csStatus.textContent = csOptions.length + ' options available. Arrow down to browse or start typing to filter.'

  // EVENTS
  // /////////////////////////////////
  selectComponentEl.addEventListener('click', function () {
    const currentFocus = findFocus()
    switch (csState) {
      case 'initial': // if state = initial, toggleOpen and set state to opened
        toggleList('Open')
        setState('opened')
        break
      case 'opened':
        // if state = opened and focus on input, toggleShut and set state to initial
        if (currentFocus === csInput) {
          toggleList('Shut')
          setState('initial')
        } else if (currentFocus.tagName === 'LI') {
          // if state = opened and focus on list, makeChoice, toggleShut and set state to closed
          makeChoice(currentFocus)
          toggleList('Shut')
          setState('closed')
        }
        break
      case 'filtered':
        // if state = filtered and focus on list, makeChoice and set state to closed
        if (currentFocus.tagName === 'LI') {
          makeChoice(currentFocus)
          toggleList('Shut')
          setState('closed')
        } // if state = filtered and focus on input, do nothing (wait for next user input)

        break
      case 'closed': // if state = closed, toggleOpen and set state to filtered? or opened?
        toggleList('Open')
        setState('filtered')
        break
    }
  })

  selectComponentEl.addEventListener('keyup', function (e) {
    doKeyAction(e.key)
  })

  selectComponentEl.addEventListener('reload', function (e) {
    toggleList('Shut')
    setState('initial')

    // Clear the input field
    csInput.value = ''
    
    // Trigger a new search request based on the updated queryParams
    loadEndpointSearchData()
  })

  csInput.addEventListener('blur', function (e) {
    const nextElement = e.relatedTarget
    if (nextElement && nextElement.tagName !== 'LI') {
      toggleList('Shut')
      setState('initial')
    }
  })

  document.addEventListener('click', function (e) {
    if (!e.target.closest('.js-c-form-select-search-select')) {
      // click outside of the custom group
      toggleList('Shut')
      setState('initial')
    }
  })

  // FUNCTIONS
  // /////////////////////////////////

  function toggleList(whichWay) {
    if (whichWay === 'Open') {
      csList.classList.remove('hidden')
      csSelector.setAttribute('aria-expanded', 'true')
    } else {
      // === 'Shut'
      csList.classList.add('hidden')
      csSelector.setAttribute('aria-expanded', 'false')
    }
  }

  function findFocus() {
    const focusPoint = document.activeElement
    return focusPoint
  }

  function moveFocus(fromHere, toThere) {
    // grab the currently showing options, which might have been filtered
    const aCurrentOptions = aOptions.filter(function (option) {
      if (option.style.display === '') {
        return true
      }
    })
    // don't move if all options have been filtered out
    if (aCurrentOptions.length === 0) {
      return
    }
    if (toThere === 'input') {
      csInput.focus()
    }
    // possible start points
    switch (fromHere) {
      case csInput:
        if (toThere === 'forward') {
          aCurrentOptions[0].focus()
        } else if (toThere === 'back') {
          aCurrentOptions[aCurrentOptions.length - 1].focus()
        }
        break
      case csOptions[0]:
        if (toThere === 'forward') {
          if (aCurrentOptions[1]) aCurrentOptions[1].focus()
        } else if (toThere === 'back') {
          csInput.focus()
        }
        break
      case csOptions[csOptions.length - 1]:
        if (toThere === 'forward') {
          aCurrentOptions[0].focus()
        } else if (toThere === 'back') {
          aCurrentOptions[aCurrentOptions.length - 2].focus()
        }
        break
      default:
        // middle list or filtered items
        const currentItem = findFocus()
        const whichOne = aCurrentOptions.indexOf(currentItem)
        if (toThere === 'forward') {
          const nextOne = aCurrentOptions[whichOne + 1]
          nextOne.focus()
        } else if (toThere === 'back' && whichOne > 0) {
          const previousOne = aCurrentOptions[whichOne - 1]
          previousOne.focus()
        } else {
          // if whichOne = 0
          csInput.focus()
        }
        break
    }
  }

  function doFilter() {
    const terms = csInput.value
    const aFilteredOptions = aOptions.filter(function (option) {
      if (option.innerText.toUpperCase().startsWith(terms.toUpperCase())) {
        return true
      }
    })
    csOptions.forEach(option => (option.style.display = 'none'))
    aFilteredOptions.forEach(function (option) {
      option.style.display = ''
    })
    setState('filtered')
    updateStatus(aFilteredOptions.length)
  }

  function updateStatus(howMany) {
    csStatus.textContent = howMany + ' options available.'
  }

  function makeChoice(whichOption) {
    csInput.value = whichOption.textContent
    csInputHidden.value = whichOption.dataset.value
    csInputHidden.dataset.text = whichOption.textContent
    moveFocus(document.activeElement, 'input')
  }

  function setState(newState) {
    switch (newState) {
      case 'initial':
        csState = 'initial'
        break
      case 'opened':
        csState = 'opened'
        break
      case 'filtered':
        csState = 'filtered'
        break
      case 'closed':
        csState = 'closed'
    }
  }

  function doKeyAction(whichKey) {
    const currentFocus = findFocus()

    function handleDefaultCase() {
      loadEndpointSearchData()

      if (csState === 'initial') {
        // if state = initial, toggle open, doFilter and set state to filtered
        toggleList('Open')
        doFilter()
        setState('filtered')
      } else if (csState === 'opened') {
        // if state = opened, doFilter and set state to filtered
        doFilter()
        setState('filtered')
      } else if (csState === 'closed') {
        // if state = closed, doFilter and set state to filtered
        toggleList('Open')
        doFilter()
        setState('filtered')
      } else {
        // already filtered
        doFilter()
      }
    }

    switch (whichKey) {
      case 'Enter':
        if (csState === 'initial') {
          // if state = initial, toggleOpen and set state to opened
          toggleList('Open')
          setState('opened')
        } else if (csState === 'opened' && currentFocus.tagName === 'LI') {
          // if state = opened and focus on list, makeChoice and set state to closed
          makeChoice(currentFocus)
          toggleList('Shut')
          setState('closed')
        } else if (csState === 'opened' && currentFocus === csInput) {
          // if state = opened and focus on input, close it
          toggleList('Shut')
          setState('closed')
        } else if (csState === 'filtered' && currentFocus.tagName === 'LI') {
          // if state = filtered and focus on list, makeChoice and set state to closed
          makeChoice(currentFocus)
          toggleList('Shut')
          setState('closed')
        } else if (csState === 'filtered' && currentFocus === csInput) {
          // if state = filtered and focus on input, set state to opened
          toggleList('Open')
          setState('opened')
        } else {
          // i.e. csState is closed, or csState is opened/filtered but other focus point?
          // if state = closed, set state to filtered? i.e. open but keep existing input?
          toggleList('Open')
          setState('filtered')
        }
        break

      case 'Escape':
        // if state = initial, do nothing
        // if state = opened or filtered, set state to initial
        // if state = closed, do nothing
        if (csState === 'opened' || csState === 'filtered') {
          toggleList('Shut')
          setState('initial')
        }
        break

      case 'ArrowDown':
        if (csState === 'initial' || csState === 'closed') {
          // if state = initial or closed, set state to opened and moveFocus to first
          toggleList('Open')
          moveFocus(csInput, 'forward')
          setState('opened')
        } else {
          // if state = opened and focus on input, moveFocus to first
          // if state = opened and focus on list, moveFocus to next/first
          // if state = filtered and focus on input, moveFocus to first
          // if state = filtered and focus on list, moveFocus to next/first
          toggleList('Open')
          moveFocus(currentFocus, 'forward')
        }
        break
      case 'ArrowUp':
        if (csState === 'initial' || csState === 'closed') {
          // if state = initial, set state to opened and moveFocus to last
          // if state = closed, set state to opened and moveFocus to last
          toggleList('Open')
          moveFocus(csInput, 'back')
          setState('opened')
        } else {
          // if state = opened and focus on input, moveFocus to last
          // if state = opened and focus on list, moveFocus to prev/last
          // if state = filtered and focus on input, moveFocus to last
          // if state = filtered and focus on list, moveFocus to prev/last
          moveFocus(currentFocus, 'back')
        }
        break
      case 'Backspace':
        // if the focus is on the list items then remove suggestions and focus back on the input & delete last character
        // if not, search like normal (default)
        if (currentFocus.tagName === 'LI') {
          moveFocus(currentFocus, 'input')
          csInput.value = csInput.value.slice(0, -1)
        } else {
          handleDefaultCase()
        }
        break
      case 'Delete':
        // if the focus is on the list items then remove suggestions and focus back on the input
        // if not, search like normal (default)
        if (currentFocus.tagName === 'LI') {
          moveFocus(currentFocus, 'input')
        } else {
          handleDefaultCase()
        }
        break

      default:
        handleDefaultCase()
        break
    }
  }
  

  // If the params have updated the results can change - seen on the breeds input
  function updateQueryParams() {
    const currentQueryParams = selectComponentEl.dataset.queryParams
    if (currentQueryParams !== prevQueryParams) {
      prevQueryParams = currentQueryParams
      queryParams = JSON.parse(currentQueryParams)
    }
  }


  async function loadEndpointSearchData() {
    // Search value must have more than 3 characters
    if (!searchEndpoint || csInput.value.length < 3) {
      // reset the list in the case of a user deleting characters
      csList.replaceChildren()
      return
    }

    updateQueryParams()

    try {
      const response = await requestNative({
        url: searchEndpoint,
        type: 'GET',
        data: { request_term: csInput.value, ...queryParams },
      })

      const listCollection = response.data.map(result => {
        const listEl = document.createElement('li')
        listEl.classList.add(
          'tw-p-3',
          'tw-w-full',
          'hover:tw-bg-neutral-200',
          'focus:tw-outline-none',
          'focus:tw-ring',
          'focus:tw-ring-blue-500',
          'focus:tw-ring-inset',
          'focus:tw-rounded'
        )

        listEl.dataset.value = result.value
        listEl.dataset.metadata = result.metadata
        listEl.textContent = result.text

        listEl.setAttribute('role', 'option')
        listEl.setAttribute('tabindex', '-1')

        listEl.addEventListener('blur', function (e) {
          const nextElement = e.relatedTarget
          if (nextElement && nextElement.tagName !== 'LI') {
            toggleList('Shut')
            setState('initial')
          }
        })

        return listEl
      })

      csList.replaceChildren(...listCollection)
      csOptions = csList.querySelectorAll('li')
      aOptions = Array.from(csOptions)
      csStatus.textContent = csOptions.length + ' options available. Arrow down to browse or start typing to filter.'
    } catch (error) {
      notifyBugsnag(error)
    }
  }
}
