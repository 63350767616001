import $ from "cash-dom"
import anime from 'animejs/lib/anime.es.js'

const IS_VISIBLE_CLASS = 'js-c-modal-visible'
const IS_TRANSITIONING_CLASS = 'js-c-modal-transitioning'

export const MODAL_SELECTOR = '.js-c-modal'
export const INNER_WRAPPER_SELECTOR = '.js-c-modal-wrapper-inner'
export const CLOSE_BUTTON_SELECTOR = '.js-c-modal-close'
export const TRIGGER_MODAL_SELECTOR = '[data-action="open-modal"]'
export const CLOSE_MODAL_SELECTOR = '[data-action="close-modal"]'

const modalList = {}

function initializeModals() {
  $(MODAL_SELECTOR).each((_, el) => {
    const { modalName } = el.dataset
    modalList[modalName] = Modal(modalName)
  })

  // For convience, we allow you to use data actions to trigger a modal, you just need to set it up like so:
  // data-action="open-modal" data-modal="{{ modal name }}"
  $(document).on('click', TRIGGER_MODAL_SELECTOR, event => {
    event.preventDefault()
    const modalTrigger = $(event.target).closest(TRIGGER_MODAL_SELECTOR);
    const modalName = modalTrigger.data('modal');

    openModal(modalName);
  })

  // For convience, we allow you to use data actions to close a modal, you just need to set it up like so:
  // data-action="close-modal" data-modal="{{ modal name }}"
  $(document).on('click', CLOSE_MODAL_SELECTOR, event => {
    event.preventDefault()
    closeModal(event.target.dataset.modal)
  })

  // Open the group details modal immediately if it is available
  // TODO: move this to a group details modal component
  if (modalList['group_details_update']) openModal('group_details_update')
}

// Initialize all modals on dom load
initializeModals()

export function getModal(modalName) {
  return modalList[modalName]
}

export async function openModal(modalName) {
  const modal = getModal(modalName)
  await modal.open()
  return modal
}

export async function closeModal(modalName) {
  const modal = getModal(modalName)
  await modal.close()
  return modal
}

export async function closeAllModals() {
  for (const [, modal] of Object.entries(modalList)) {
    await modal.close()
  }
}

export function isModalOpen(modalName) {
  return modalList[modalName]?.isOpen() || false
}

export function anyModalOpen() {
  for (const [, modal] of Object.entries(modalList)) {
    if (modal.isOpen()) return true
  }
  return false
}


/*
  This modal function creates a modal "instance" which we can then control. It might be worth
  in the future converting this to an actual js class OR using a framework like alpine.js to
  manage it
*/
function Modal(modalName) {

  const $modal = $(`${MODAL_SELECTOR}[data-modal-name="${modalName}"]`)

  let isOpenFlag = false
  let prevActiveElement = null
  let lastYPosition = 0

  async function open() {
    if (isOpenFlag) return
    isOpenFlag = true
    prevActiveElement = document.activeElement

    $modal.addClass(IS_TRANSITIONING_CLASS)

    // fade in
    await anime({
      targets: $modal.get(),
      opacity: [0, 1],
      easing: 'easeOutExpo',
      duration: 300
    }).finished

    setupEventListeners()

    $modal.attr({ 'aria-hidden': false, tabindex: 0 })
    $modal.get(0).focus()
    $modal.addClass(IS_VISIBLE_CLASS)
    $modal.removeClass(IS_TRANSITIONING_CLASS)

    // Fix background position so the page doesn't create extra scroll for the Modal
    lastYPosition = window.scrollY
    $(document.body).css({ position: 'fixed', top: `-${window.scrollY}px` })
  }

  async function close() {
    if (!isOpenFlag) return
    isOpenFlag = false
    
    $modal.addClass(IS_TRANSITIONING_CLASS)

    // fade out
    await anime({
      targets: $modal.get(),
      opacity: [1, 0],
      easing: 'easeOutExpo',
      duration: 300
    }).finished

    $modal.attr({ 'aria-hidden': true })
    $modal.removeAttr('tabindex')
    $modal.removeClass(IS_VISIBLE_CLASS)
    $modal.removeClass(IS_TRANSITIONING_CLASS)

    removeEventListeners()

    // Release the frozen background so scroll is active again for the page
    $(document.body).css({ position: null, top: null })
    window.scrollTo({ top: parseInt(lastYPosition || '0'), behavior: 'instant' });

    if (prevActiveElement) {
      prevActiveElement.focus()
      prevActiveElement = null
    }
  }

  function setupEventListeners() {
    $('body').on('mousedown', INNER_WRAPPER_SELECTOR, handleMouseDownOnBackground)
    $modal.on('click', CLOSE_BUTTON_SELECTOR, handleClickClose)
    $modal.on('keydown', MODAL_SELECTOR, handleEscape)
  }

  function removeEventListeners() {
    $('body').off('mousedown', handleMouseDownOnBackground)
    $modal.off('click', handleClickClose)
    $modal.off('click', handleEscape)
  }

  function handleClickClose() {
    close()
  }

  function handleEscape(event) {
    if (event.code === 'Escape') close()
  }

  // we use a combination of mousedown and mouseup event handlers on the modal to test if the click originated and ended
  // in the blacked out part of the screen
  function handleMouseDownOnBackground(event) {
    const eventTargetIsModalWrapper = event => $(event.target).is(INNER_WRAPPER_SELECTOR)
    if (eventTargetIsModalWrapper(event)) {
      $('body').one('mouseup', INNER_WRAPPER_SELECTOR, function () {
        if (eventTargetIsModalWrapper(event)) close()
      })
    }
  }

  function isOpen() {
    return isOpenFlag
  }

  return {
    open,
    close,
    isOpen
  }
}
