;(function ($) {
  $(document).delegate('.filter-box .filter-box-header', 'click.filterbox', function () {
    var $parent = $(this).parent()
    $parent
      .find('.filter-box-options')
      .stop(true, true)
      .slideToggle(300, function () {
        $parent.toggleClass('expanded')
      })
  })

  $('.filter-box-options li span').click(function () {
    var link = $(this).parent().parent().find('a:first').prop('href')
    window.location.replace(link)
  })

  var isMobile = /Mobi|Android/i.test(navigator.userAgent)
  if (isMobile) {
    $('.expanded').removeClass('expanded')
  }
})(jQuery)
