import { getRecaptchaResult } from 'components/recaptcha_widget/component'

$('.report-listing-form-submit').on('click', async function(e) {
  e.preventDefault();
  let result = await getRecaptchaResult() // always run recaptcha *after* validation

  if (result) {
    $('.report-listing-form').submit();
  }
})
