import { on } from 'js/lib/events'
import { wait } from 'js/lib/general-utils'
import { deleteFavourite, createFavourite } from 'js/lib/api/favourites'
import { openModal } from 'components/modal/component'

const favouriteButtons = document.querySelectorAll('.js-c-favourite-btn')

const ACTIVE_CLASS = 'c-favourite-btn--active'
const ANIMATION_CLASS = 'c-favourite-btn--is-animating'
const ANIMATION_TIMEOUT = 800

favouriteButtons.forEach(btn => on('click', btn, event => handleClick(event, btn)))

async function handleClick(event, btn) {
  event.preventDefault()
  const { requiresLogin, id, listingId } = btn.dataset

  if (requiresLogin) return showModalToLogin()
  if (!listingId) return

  if (id) {
    await handleUnFavourited(id, listingId, btn)
  } else {
    await handleFavourited(listingId, btn)
  }
}

async function handleUnFavourited(id, listingId, btn) {
  btn.disabled = true
  try {
    await deleteFavourite(id, listingId)
    delete btn.dataset.id
    btn.classList.remove(ACTIVE_CLASS)
  } catch (error) {
    if (error.status === 401) showModalToLogin()
  } finally {
    btn.disabled = false
  }
}

async function handleFavourited(listingId, btn) {
  btn.disabled = true
  btn.classList.add(ANIMATION_CLASS)
  const timer = wait(ANIMATION_TIMEOUT)
  const createFav = createFavourite(listingId)
  try {
    const [response] = await Promise.all([createFav, timer])
    if (response.id) {
      btn.dataset.id = response.id
    }
    btn.classList.add(ACTIVE_CLASS)
  } catch (error) {
    btn.classList.remove(ACTIVE_CLASS)
    if (error.status === 401) showModalToLogin()
  } finally {
    btn.classList.remove(ANIMATION_CLASS)
    btn.disabled = false
  }
}

function showModalToLogin() {
  openModal('login_to_continue')
}
