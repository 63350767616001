;(function (window, $) {
  window.addPerPageSelect = function ($containers, callback) {
    $containers.each(function () {
      var $perPageTag
      var match = window.location.href.match(/([\?&]per_page\=)(\d+)/)
      var currentPerPage = 12
      var $select = $('<select>', {
        class: 'no-chosen pagination-select',
        id: 'per-page',
      })

      if ($('body').hasClass('search') || $('body').hasClass('users-favourites')) {
        var perPageOptions = [20, 40, 60]
      } else {
        if ($('body').hasClass('listings-rehomed')) {
          var perPageOptions = [12, 24, 36, 48, 60]
        } else {
          var perPageOptions = [60, 120]
        }
      }

      if (match !== null) {
        currentPerPage = parseInt(match[2])
      }

      $.each(perPageOptions, function (_key, option) {
        $select.append(
          $('<option>', {
            value: option,
            text: option,
            selected: option === currentPerPage,
          })
        )
      })

      $perPageTag = $('<span>', {
        class: 'per-page',
      })
        .append($('<span>', { class: 'text', text: 'Show' }))
        .append($('<form>').append($select))
        .append($('<label>', { for: 'per-page', class: 'text', text: 'pets per page' }))

      $select.on('change', function () {
        var url
        var action = window.location.href
        var perPage = $(this).val()
        var anchor = ''

        // Extract the anchor (if there are any) and remove it from the URL
        if (action.includes('#')) {
          anchor = action.substring(action.indexOf('#'))
          action = action.substring(0, action.indexOf('#'))
        }

        // If we already have a per_page param
        if (match !== null) {
          // Replace it with (?|&)per_page=(value)
          url = action.replace(match[0], match[1] + perPage)
        } else {
          // If we already have URI params
          if (action.indexOf('?') !== -1) {
            url = action + '&per_page=' + perPage
          } else {
            url = action + '?per_page=' + perPage
          }
        }

        // Reset page param and redirect, then re-append the anchor
        window.location.href = url.replace(/([\?&])page\=\d+/, '$1page=1') + anchor
      })

      if ($('.per-page').length == 0) {
        $(this).append($perPageTag)
      }

      if ($.isFunction(callback)) {
        callback.call(this, $perPageTag)
      }
    })
  }
})(window, jQuery)