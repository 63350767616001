;(function ($) {
  $.fn.serializeObject = function () {
    var obj = {}
    var formArray = $(this).serializeArray()

    for (var i = 0; i < formArray.length; i++) {
      obj[formArray[i]['name']] = formArray[i]['value']
    }

    return obj
  }
})(jQuery)
