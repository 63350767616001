import { openCloudinaryWidget, isCloudinaryWidgetOpen, preloadCloudinaryWidget } from 'js/lib/cloudinary'
import $ from 'cash-dom'

initialize()

async function initialize() {
  const imgUploads = $('.js-c-img-upload-input')
  if (!imgUploads.length) return

  preloadCloudinaryWidget()

  imgUploads.each((_, el) => {
    const imgUpload = $(el)
    imgUpload.on('click', '.js-c-img-upload-input__button', event => {
      event.preventDefault()
      handleImageUpload(el)
    })
  })
}

async function handleImageUpload(element) {
  if (isCloudinaryWidgetOpen()) return

  const { tag, allowedFormats, maxImageWidth, maxImageHeight, minImageWidth, minImageHeight } = JSON.parse(
    element.dataset.clientState
  )

  const results = await openCloudinaryWidget({
    // The uploadPreset config is important so that the image gets uploaded without initial transformations
    // TODO: this should be an environment variable
    uploadPreset: 'no_transforms',
    tags: [tag],
    multiple: false,
    sources: ['local', 'url'],
    maxFileSize: 25 * 100000, // 25 MB
    resourceType: 'image',
    allowedFormats,
    maxImageWidth,
    maxImageHeight,
    minImageWidth,
    minImageHeight,
  })

  if (!results.length) return

  const imageUrl = results[0].info.secure_url
  $(element).find('.js-c-img-upload-input__thumbnail').removeClass('hidden')
  $(element).find('.js-c-img-upload-input__thumbnail > img').attr('src', imageUrl)
  $(element).find('.js-c-img-upload-input__hidden-input').attr('value', imageUrl)
}
