import $ from 'cash-dom'
import cookies from 'js-cookie'
import { wait } from 'js/lib/general-utils'
import { getModal, anyModalOpen } from 'components/modal/component'

const modal = getModal('feature_announcement')

async function initialize() {
  // waits 1 second to ensure no other modals are opened. This is a bit hacky but only quick way way without
  // refactoring a lot.
  await wait(1 * 1000) 
  
  // If there is no modal, or any modals open after 3 seconds, don't show the feature annoncement
  if (anyModalOpen()) return
  if (!modal) return

  const featureKey = $('.js-feature-announcement-modal').data('feature-key')
  const cookieKey = `feature-announcement-${featureKey}`

  // Don't raise the feature announcement if they've already seen it
  if (cookies.get(cookieKey)) return 

  $('.js-feature-announcement-modal__close').on('click', () => modal.close())
  
  // We set the cookie when opening the modal
  cookies.set(cookieKey, true, {
    expires: 365,
    path: '/',
    secure: PetRescue.env.public,
  })

  await modal.open()
}

initialize()