import { on } from 'js/lib/events'
import { createAnalyticsGroup } from 'js/lib/analytics'
import { openModal } from 'components/modal/component'

const enquiryButtons = document.querySelectorAll('.js-c-enquiry-btn')

enquiryButtons.forEach(btn => on('click', btn, event => handleClick(event, btn)))

const track = createAnalyticsGroup('enquiry-button')

async function handleClick(event, btn) {
  const { requiresLogin, listingId } = btn.dataset

  if (requiresLogin) {
    event.preventDefault()
    track('click', 'requires-login')
    return showModalToLogin()
  }
  if (!listingId) return
  track('click', 'can-enquire')
}

function showModalToLogin() {
  openModal('login_modal')
}
