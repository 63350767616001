import { openCloudinaryWidget, isCloudinaryWidgetOpen, preloadCloudinaryWidget } from 'js/lib/cloudinary'

initialize()
initializeBannerUpload()

async function initialize() {
  $('.blogpost_date').mask('00/00/0000', {
    placeholder: '__/__/____',
  })

  $('.hide-unchecked').each(function () {
    var $element = $(this)
    var $checkbox = $element.parents('.js-control').find('input[type="checkbox"]')
    $checkbox.on('change', function () {
      if ($checkbox.prop('checked')) {
        $element.show()
      } else {
        $element.hide()
      }
    })
    $checkbox.trigger('change')
  })

  $('#blog_post_members_dashboard_input input').change(function () {
    $('.send_member_alert_container').toggleClass('hidden')
  })

  $('#blog_post_send_member_alert_input input').change(function () {
    $('#blog_post_states_input input').prop('checked', false)
    $('.js-blog_post_states').toggleClass('hidden')
  })

  if ($('#thumbnail--upload').length) {
    document.getElementById('thumbnail--upload').addEventListener('click', async function () {
      if (isCloudinaryWidgetOpen()) return

      var results = await openCloudinaryWidget({
        fieldName: 'blog_post[thumbnail_image]',
        tags: ['blogpost_thumbnail'],
        form: '#blogpost_form',
        cropping: true,
        multiple: false,
      })

      results.forEach(function (result) {
        var url = result.info.secure_url.replace('upload/', 'upload/c_crop,g_custom,w_210,h_210/')
        $('.thumbnail_image img').attr('src', url)
      })
    })
  }

  //show page admin options
  $('.blog-posts__admin-options-button').on('click', function () {
    $('.blog-posts__admin-options').toggle()
  })
}

async function initializeBannerUpload() {
  if (!$('#banner--upload').length) return
  preloadCloudinaryWidget()

  document.getElementById('banner--upload').addEventListener('click', async function () {
    if (isCloudinaryWidgetOpen()) return

    var results = await openCloudinaryWidget({
      fieldName: 'blog_post[responsive_display_image]',
      tags: ['blogpost_banner'],
      form: '#blogpost_form',
      cropping: true,
      multiple: false,
    })

    results.forEach(function (result) {
      var url = result.info.secure_url.replace('upload/', 'upload/c_crop,g_custom,w_621,h_265/')
      $('.banner_image img').attr('src', url)
    })
  })
}
