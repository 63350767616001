import { createAnalyticsGroup } from 'js/lib/analytics'

const banners = document.querySelectorAll('.js-c-article-donation-banner-green')

function initBanner(banner) {
  if (!banner) return

  const donateButton = banner.querySelectorAll('.js-c-article-donation-banner-green__cta')
  const track = createAnalyticsGroup(`donation-modal_button`)
  
  donateButton.forEach(cta => {
    cta.addEventListener('click', () => {
      track('click', 'donation-modal_buttonclick')
    })
  })
}

banners.forEach((banner) => {
  initBanner(banner)
})
