import { on } from 'js/lib/events'
import Cookies from 'js-cookie'
import { createAnalyticsGroup } from 'js/lib/analytics'

const track = createAnalyticsGroup('popups')

// Remind me later - dismisses for 1 day
on('click', '.js-c-popups--member-sector-survey--later', () => {
  Cookies.set('member_survery_popup__dismissed', true, {
    expires: 1,
    path: '/',
    secure: PetRescue.env.public,
  })

  document.querySelector('.js-c-popups--member-sector-survey.js-c-footer-popup').style.display = 'none'

  track('remind-later', 'member-sector-survey-popup')
})

// Find more details here - dismisses for 1 day
on('click', '.js-c-popups--member-sector-survey--more', () => {
  Cookies.set('member_survery_popup__dismissed', true, {
    expires: 1,
    path: '/',
    secure: PetRescue.env.public,
  })

  track('more-details', 'member-sector-survey-popup')
})

// Close - dismisses for 3 days
on('click', '.js-c-popups--member-sector-survey .js-c-footer-popup__close', () => {
  Cookies.set('member_survery_popup__dismissed', true, {
    expires: 3,
    path: '/',
    secure: PetRescue.env.public,
  })

  track('close', 'member-sector-survey-popup')
})

// Take me to the survey - dismisses for 10,000 days
on('click', '.js-c-popups--member-sector-survey--accept', () => {
  Cookies.set('member_survery_popup__dismissed', true, {
    expires: 10000,
    path: '/',
    secure: PetRescue.env.public,
  })

  track('accept', 'member-sector-survey-popup')
})
