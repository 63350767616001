import GLightbox from 'glightbox'

export function createLightbox({ selector, startAt, lightboxHTML, autoplayVideos = false }) {
  const options = {
    selector,
    loop: true,
    autoplayVideos: false, // take control of this so we can manage this ourselves
    plyr: {
      // we have to load the polyfilled version of plyr since we need to support older safari version
      js: 'https://cdn.plyr.io/3.6.8/plyr.polyfilled.js',
    },
  }

  if (startAt !== undefined) options.startAt = startAt
  if (lightboxHTML !== undefined) options.lightboxHTML = lightboxHTML

  const galleryInstance = GLightbox(options)
  
  if (autoplayVideos) enableAutoplayForGallery(galleryInstance)

  return galleryInstance
}

// The `gallery` object conforms to this interface:
// {
//   'href': 'https://picsum.photos/1200/800',
//   'type': 'image',
//   'title': 'My Title',
//   'description': 'Example',
// },
export function createLightboxFromGallery({ gallery, autoplayVideos = false }) {
  const options = {
    elements: gallery,
    loop: true,
    autoplayVideos: false, // take control of this so we can manage this ourselves
    plyr: {
      // we have to load the polyfilled version of plyr since we need to support older safari version
      js: 'https://cdn.plyr.io/3.6.8/plyr.polyfilled.js',
    },
  }

  const galleryInstance = GLightbox(options)
  if (autoplayVideos) enableAutoplayForGallery(galleryInstance)

  return galleryInstance
}

function enableAutoplayForGallery(gallery) {
  gallery.on('slide_changed', ({ current }) => {
    const { player } = current
    if (!player) return
    if (!player.ready) {
      // If player is not ready
      player.on('ready', () => tryAutoPlay(player));
    } else {
      tryAutoPlay(player)
    }
  })
}

function tryAutoPlay(player) {
  const promise = player.play()
  if (typeof promise === 'object' && typeof promise.then === 'function') {
    promise.then(
      () => {},
      error => {
        // Swallow the error
        if (error.name === 'NotAllowedError') {
          console.log('Could not play video - NotAllowedError')
          return
        } else {
          console.error(error)
        }
      },
    )
  }
}