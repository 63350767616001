import { loadScript } from 'js/lib/script-loader'

const CLOUDINARY_WIDGET_DEFAULTS = {
  cloudName: PetRescue.env.cloudinary.cloudName,
  uploadPreset: PetRescue.env.cloudinary.uploadPreset,
  maxImageFileSize: 1000000 * 20, // 1000000 == 1mb
  // Bump limit from 80mb to 150mb
  // to support video sizes from platforms like tiktok
  maxVideoFileSize: 1000000 * 150, // 100 0000 == 1mb
  clientAllowedFormats: 'png,jpg,webp,gif,heic,video',
  styles: {
    palette: {
      windowBorder: '#90A0B3',
      tabIcon: '#5FA503',
      menuIcons: '#5A616A',
      link: '#5FA503',
      action: '#333333',
    },
  },
}

let cloudinaryWidgetOpen = false
let cloudinaryInstance = undefined

export async function loadCloudinaryInstance() {
  if (cloudinaryInstance) return cloudinaryInstance
  await preloadCloudinaryWidget()
  cloudinaryInstance = window.cloudinary.createUploadWidget({
    cloud_name: PetRescue.env.cloudinary.cloudName,
  })
  return cloudinaryInstance
}

export async function preloadCloudinaryWidget() {
  await loadScript('https://widget.cloudinary.com/v2.0/global/all.js')
}

export function isCloudinaryWidgetOpen() {
  return cloudinaryWidgetOpen
}

export function openCloudinaryWidget(options = {}) {
  // More options available at:
  // https://cloudinary.com/documentation/upload_widget_reference#parameters
  const params = { ...CLOUDINARY_WIDGET_DEFAULTS, ...options }
  const results = []

  if (cloudinaryWidgetOpen) return results
  cloudinaryWidgetOpen = true

  return new Promise(async (resolve, reject) => {
    // ensure that cloudinary is loaded
    try {
      await loadCloudinaryInstance()
    } catch (error) {
      reject(error)
    }

    window.cloudinary.openUploadWidget(params, function (error, result) {
      cloudinaryWidgetOpen = false

      if (result.event == 'success') {
        results.push(result)
      }

      if (result.event == 'close') {
        resolve(results)
      }

      if (error) {
        console.error('Cloudinary widget failed to open', error)
        reject(error)
      }
    })
  })
}
