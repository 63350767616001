import { register } from 'js/lib/create-component'
import { loadScript } from 'js/lib/script-loader'
import { Observable, waitForNextResult } from 'js/lib/observable'
import { createFlash } from 'components/flash/component'

const SITE_KEY = '6LfrahgUAAAAAB2laLcMskqOYf4D-ZhQtVSgi7Ul'

let recaptchaIsLoaded
const recaptchaObservable = new Observable()

// We're not actually using alpine for anything but initializing recaptcha. The sate object returned
// doesn't actually do anything.
register('recaptcha', function () {
  function init() {
    // don't initialize on test environments
    if (PetRescue.env.environment === 'test') return
    initializeRecaptcha(this)
  }

  return {
    recaptcha: true,
    init,
  }
})

async function initializeRecaptcha(component) {
  recaptchaIsLoaded = new Promise(resolve => {
    window.onRecaptchaLoad = resolve
    loadScript('https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit')
  })

  await recaptchaIsLoaded
  grecaptcha.render(component.$refs.recaptchaContainer, {
    sitekey: SITE_KEY,
    size: 'invisible',
    badge: 'inline',
    callback: response => recaptchaObservable.emit.event(response),
    'expired-callback': () => recaptchaObservable.emit.error('expired'),
    'error-callback': () => recaptchaObservable.emit.error('error'),
  })
}

export async function getRecaptchaResult() {
  // always return true if this is a test env
  if (['test', 'development'].includes(PetRescue.env.environment)) return true
  if (!recaptchaIsLoaded) throw new Error('Recaptcha isnt loaded yet')
  try {
    await recaptchaIsLoaded
    grecaptcha.reset()
    grecaptcha.execute()
    const result = await waitForNextResult(recaptchaObservable)
    return result
  } catch (error) {
    if (error === 'expired') {
      createFlash('notice', 'Recaptcha expired - please try again')
    } else {
      createFlash('notice', 'Recaptcha error - please refresh the page and try again')
    }
  }
}
