import { createAnalyticsGroup } from 'js/lib/analytics'

const listingCards = document.querySelectorAll('.js-c-promo___listing-card__christmas')

if (listingCards.length > 0) {
  const track = createAnalyticsGroup('xmas2024_listing-card')
  
  listingCards.forEach(listingCard => {
    const ctaButton = listingCard.querySelector('.js-c-promo___listing-card__christmas--donate')
    
    if (ctaButton) {
      ctaButton.addEventListener('click', () => { 
        track('click', 'donate button')
      })
    }
  })
}
