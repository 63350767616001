// TODO: is this used still?
;(function ($) {
  $.detectAdBlock = function () {
    var deferred = $.Deferred()
    var bait = '//googleads.g.doubleclick.net/pagead/gen_204?id=wfocus&gqid=advertisment&advert=ads'

    if (typeof $.adBlockDetected !== 'undefined') {
      if ($.adBlockDetected) {
        deferred.resolve()
      } else {
        deferred.reject()
      }
    } else {
      $.ajax({
        url: bait,
        dataType: 'script',
        success: function () {
          $.adBlockDetected = false
          deferred.reject()
        },
        error: function () {
          $.adBlockDetected = true
          deferred.resolve()
        },
        abort: function () {
          $.adBlockDetected = true
          deferred.resolve()
        },
      })
    }

    return deferred.promise()
  }
})(jQuery)
