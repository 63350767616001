import { getRecaptchaResult } from 'components/recaptcha_widget/component'

$('.listing-share-form-submit').on('click', async function(e) {
  e.preventDefault();
  let result = await getRecaptchaResult() // always run recaptcha *after* validation
  console.log(result);
  if (result) {
    $('.listing-share-form').submit();
  }
})
