import $ from 'cash-dom'
const tooltip = $('.js-c-tooltip')

tooltip.on('mouseover', function() {
  show($(this).find('.js-c-tooltip-body')[0])
})

tooltip.on('mouseout', function() {
  $(this).find('.js-c-tooltip-body').removeClass('c-tooltip-body-show')
})

tooltip.on('focus', function() {
  show($(this).find('.js-c-tooltip-body')[0])
})

tooltip.on('blur', function() {
  $(this).find('.js-c-tooltip-body').removeClass('c-tooltip-body-show')
})

function show(body) {
  if (getOffset(body).right > window.innerWidth) {
    $(body).css("left", window.innerWidth - getOffset(body).right - 20)
  }
  $(body).css("top", -body.offsetHeight -25)
  $(body).addClass('c-tooltip-body-show')
}

function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    right: rect.left + window.scrollX + rect.width
  };
}

$(window).on('resize', function() {
  $('.js-c-tooltip-body').css("left", 0);
});
