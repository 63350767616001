function initForm(input) {
  if (!input || !input.form) return

  input.form.addEventListener('invalid', (e)=>{
    inputValidity(input)
  },true)

  function errorState(input, message) {  
    input.classList.add('tw-ring', 'tw-ring-red-500')
  
    // Check if an error message already exists
    let errorMessage = input.parentNode.querySelector('.invalid-input')
    
    if (!errorMessage) {
      errorMessage = document.createElement('span')
      errorMessage.className = 'invalid-input tw-block tw-text-red-700 tw-font-semibold tw-text-sm tw-mb-2'
      errorMessage.textContent = message
      // Insert the error message before the input
      input.insertAdjacentElement('beforebegin', errorMessage)
    }
  }
  
  function removeErrorState(input) {
    input.classList.remove('tw-ring', 'tw-ring-red-500')

    // Remove the error message if it exists
    const errorMessage = input.parentNode.querySelector('.invalid-input')
    if (errorMessage) {
      errorMessage.remove()
    }
  }
  
  function inputValidity(input) {  
    if (!input.validity.valid) {
      // if the input is required and has no value
      // We might want to add some type specific validation messages
      if (input.required && !input.value) {
        errorState(input, 'This field is required.')
  
        input.addEventListener('input', handleInputChange)
      } else {
        errorState(input, 'Invalid input.')
      }
    } else {
      removeErrorState(input)
    }
  }
  
  function handleInputChange(event) { 
    const input = event.target
    if (input.validity.valid) {
      removeErrorState(input)
    }
  }
}

function initializeInputs() {
  const formInputs = document.querySelectorAll('.js-c-form-input--required')

  formInputs.forEach(input => {
    initForm(input)
  })
}

initializeInputs()




  
