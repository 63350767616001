import { createAnalyticsGroup } from 'js/lib/analytics'

const banners = document.querySelectorAll('.js-c-promo__article_banner__christmas')

if (banners.length > 0) {
  const track = createAnalyticsGroup('xmas2024_article_banner')
  
  banners.forEach(banner => {
    const ctaButton = banner.querySelector('.js-c-promo___hero__christmas--donate')
    
    if (ctaButton) {
      ctaButton.addEventListener('click', () => { 
        track('click', 'learn more')
      })
    }
  })
}