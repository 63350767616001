import { format, formatDistanceToNow } from 'date-fns'
import { createLightbox } from 'js/lib/lightbox'
import { createAnalyticsGroup } from 'js/lib/analytics'

$(document).ready(function () {
  if ($('body').is('.listings-show, .listings-feed_show')) {
    var $slider = $('.listing-slider')
    const track = createAnalyticsGroup('Lightbox')
    const videoCount = $('.glightbox').length

    const lightboxHTML = `<div id="glightbox-body" class="glightbox-container">
        <div class="gloader visible"></div>
        <div class="goverlay"></div>
        <div class="gcontainer">
        <div id="glightbox-slider" class="gslider"></div>
        <span class="gindex">1 of 2</span>
        <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">{nextSVG}</button>
        <button class="gprev gbtn" tabindex="1" aria-label="Previous">{prevSVG}</button>
        <button class="gclose gbtn" tabindex="2" aria-label="Close">{closeSVG}</button>
      </div>
    </div>`

    const gallery = createLightbox({ selector: '.glightbox', startAt: 0, lightboxHTML, autoplayVideos: true })

    $('.js-glightboxButton-count').text(`(${videoCount})`)

    $('.js-pet-listing__video__thumbnail').on('click', function () {
      let index = $(this).data('index')
      $('.gindex').text(`${index + 1} of ${videoCount}`)
      gallery.openAt(index)
      track('click', 'listing videos thumbnail')
    })

    $('.js-glightboxButton').on('click', function () {
      $('.gindex').text(`0 of ${videoCount}`)
      gallery.open()
      track('click', 'listing videos button')
    })

    gallery.on('slide_changed', ({ current }) => $('.gindex').text(`${current.index + 1} of ${videoCount}`))

    if ($slider.length > 0) {
      $slider.on('unslider.ready', function () {
        if ($('.unslider-carousel li').length < 2) {
          $('.unslider-arrow').hide()
        }
      })

      $slider.on('unslider.change', function (_event, index) {
        $slider.css('visibility', 'visible')
        $('video').each(function (_, value) {
          $(value).get(0).pause()
        })
        $('.pet-listing__photos__thumnail.active').removeClass('active')
        $('.unslider-nav-thumbnail#'+index).parent().addClass('active')
        $('.current_photo_number').html(parseInt(index) + 1)
      })

      $slider.unslider()

      $('.unslider-nav-thumbnail').click(function () {
        var id = $(this).attr('id')
        $(this).parent().addClass('active')
        $('.unslider-nav')
          .find("[data-slide='" + id + "']")
          .click()
      })

      var hammertime = new Hammer($slider.get(0))

      hammertime.on('swipeleft', function () {
        $('.unslider .next').click()
      })

      hammertime.on('swiperight', function () {
        $('.unslider .prev').click()
      })
    }

    // Humanise dates in admin box

    const createdAtValue = $('.created_at').data('date')
    const updatedDateValue = $('.updated_at').data('date')

    if (createdAtValue && updatedDateValue) {
      var createdDate = new Date(createdAtValue)
      $('.created_at').html(formatDistanceToNow(createdDate) + ' ago (' + format(createdDate, 'dd MMM yyyy') + ')')

      var updatedDate = new Date(updatedDateValue)
      $('.updated_at').html(formatDistanceToNow(updatedDate) + ' ago (' + format(updatedDate, 'dd MMM yyyy') + ')')
    }
  }

  // Show phone number when enquiry button is clicked
  $('.show-number').click(function (e) {
    e.preventDefault()
    var num = $('.pet-listing__phone-number').first().text()
    $('.show-number').html(num)
    $('.show-number').attr('href', 'tel:' + num)
  })
})
