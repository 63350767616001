;(function (window, $) {
  $(document).ready(function () {
    var $perPageContainers = $('[data-per-page-container]')
    var $modifyFilters = $('.search-listings__modify_filters_btn')
    var $getBackToSearchEl = () => $('.search-listings__back_to_search_btn')
    var $modifySearch = $('.search-listings__modify_search_btn')
    var $filtersContainer = $('.search-listings__filters')
    var $resultsContainer = $('.search-listings__results')
    var $paginationFilters = $('.pagination-filters')
    var originalFilters = $filtersContainer.find('form').html()
    var originalSummary

    if ($('.js-c-search-listings-summary').length) {
      originalSummary = $('.js-c-search-listings-summary').get(0).outerHTML
    }

    $('.search-listings .warning a').on('click', function (e) {
      var $link = $(this)

      $.getJSON($link.prop('href')).done(function (response) {
        $link.parent().text(response.message)
      })

      e.preventDefault()
    })

    $('[data-accordion-for]').on('click', function () {
      var accordianKey = $(this).data('accordion-for')
      $('[data-accordion-id="' + accordianKey + '"]')
        .find('ul')
        .stop(true)
        .slideToggle(300)
      $(this).toggleClass('collapsed')
    })

    window.addPerPageSelect($perPageContainers, function () {
      var $paginationNav = $(this).parent().find('nav.pagination')

      if ($paginationNav.length !== 0) {
        $(this).insertAfter($paginationNav.find('.scale'))
      }
    })

    $filtersContainer.on('change', '.interstate input[type="checkbox"]', function () {
      $(this).parents('a').get(0).click()
    })

    $getBackToSearchEl().find('button').on('click', function () {
      $('.js-c-search-listings-summary').replaceWith(originalSummary)
      $filtersContainer.find('form').html(originalFilters)

      $getBackToSearchEl().hide()
      $filtersContainer.hide()
      $modifyFilters.show()
      $resultsContainer.show()
      $paginationFilters.show()

      $(document.body).removeClass('search-listings__modify_filters')
    })

    $modifyFilters.find('button').on('click', function () {
      originalSummary = $('.js-c-search-listings-summary').get(0).outerHTML
      originalFilters = $filtersContainer.find('form').html()

      $getBackToSearchEl().show()
      $filtersContainer.show()
      $modifyFilters.hide()
      $resultsContainer.hide()
      $paginationFilters.hide()

      $(document.body).addClass('search-listings__modify_filters')

      $filtersContainer.on('click', 'a[data-param-name], .postcode-reset-btn', function (e) {
        var filtersUrl = $(this).prop('href').replace('listings/search', 'listings/search/filters')

        $.ajax({
          type: 'GET',
          url: filtersUrl,
          dataType: 'json',
          success: function (json) {
            $('.js-c-search-listings-summary').replaceWith(json.summary)
            $filtersContainer.find('form').html(json.filters)
            $('select:not(.custom-select2, .component)').select2({
              minimumResultsForSearch: 10,
              width: '100%',
            })
            $('.search-listings__modify_search_btn').show()
          },
        })

        e.preventDefault()
        e.stopPropagation()
      })

      $modifySearch.on('click', function (e) {
        $('.search-listings__form').trigger('submit')

        e.preventDefault()
        e.stopPropagation()
      })
    })
  })
})(window, jQuery)
