import { closeAllModals, openModal } from 'components/modal/component'

$.externalLinkWarning = (function () {
  var selector = '.external_link_warning'
  var whitelist = PetRescue.env.externalLinks.whitelist
  var href

  return {
    initialized: false,
    init: function () {
      if (!this.initialized) {
        this.initialized = true
        bindEvents()
      }
    },
    try: function (a) {
      var deferred = $.Deferred()
      var $a = $(a)

      this.init()

      if (whitelist.indexOf($a.prop('hostname')) === -1) {
        href = $a.prop('href')
        $(selector).find('.link-preview').text(href)
        openModal('external_link_warning')
        deferred.resolve()
      } else {
        deferred.reject()
      }

      return deferred.promise()
    },
  }

  function bindEvents() {
    $(selector).on('click', '.button.accept', function () {
      window.location.href = href
      closeAllModals()
    })

    $(selector).on('click', '.button.decline', function () {
      closeAllModals()
    })
  }
})()
