function createFormHelper(form) {
  const inputs = {};
  initInputs();

  form.addEventListener('change', () => {
    updateState();
    if (typeof onChangeCallback === 'function') {
      onChangeCallback(getState());
    }
  });

  function initInputs() {
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.type !== 'submit') {
        inputs[input.name] = {
          element: input,
          isVisible: true
        };
      }
    }
  }

  function updateState() {
    const formData = new FormData(form);
    for (const [key, input] of Object.entries(inputs)) {
      input.value = formData.get(key);
    }
  }

  function show(inputName) {
    const input = ensureInput(inputName);
    input.isVisible = true;
    const parentField = findParentFormFieldComponent(input.element);
    if (parentField) {
      parentField.classList.remove('tw-hidden')
    }
  }

  function hide(inputName) {
    const input = ensureInput(inputName);
    input.isVisible = false;
    const parentField = findParentFormFieldComponent(input.element);
    if (parentField) {
      parentField.classList.add('tw-hidden')
    }
  }

  function getState() {
    const state = {};
    for (const [key, input] of Object.entries(inputs)) {
      state[key] = {
        value: input.element.value,
        isVisible: input.isVisible,
        dataAttr: { ...input.element.dataset }
      };
    }
    return state;
  }
  
  // Function to reset all .c-form-field elements
  function reset(fields) {
    fields.forEach(field => {
      hide(field)
    });
  }

  function findParentFormFieldComponent(element) {
    return element.closest('.js-c-form-field');
  }
  
  function ensureInput(inputName) {
    if (!inputs[inputName]) throw new Error(`Input ${inputName} is missing from the form`)
    return inputs[inputName]
  }

  let onChangeCallback = null;
  function onChange(callback) {
    onChangeCallback = callback;
  }

  return {
    onChange,
    getState,
    show,
    hide,
    reset
  };
}

export default createFormHelper;
