const checkboxes = document.querySelectorAll('.js-c-form-input-checkbox')

function initCheckboxes(checkbox) {
  if (!checkbox) return

  const icon = checkbox.querySelector('.js-c-form-input-checkbox__icon')
  const button = checkbox.querySelector('.js-c-form-input-checkbox__button')
  const input = checkbox.querySelector('.js-c-form-input-checkbox__input')

  const toggleCheckboxState = () => {
    icon.classList.toggle('hidden')
    const isChecked = button.getAttribute('aria-checked') === 'true'
    button.setAttribute('aria-checked', isChecked ? 'false' : 'true')
    button.setAttribute('data-state', isChecked ? 'unchecked' : 'checked')

    input.checked = !isChecked
  }

  button.addEventListener('click', toggleCheckboxState)
}

checkboxes.forEach((checkbox) => {
  initCheckboxes(checkbox)
})
