;(function ($) {
  /**
   * This allows for the use of data-attributes to control specific attributes on elements based on the state of other
   * elements.
   *
   * data-controlled-by | This should be set on the element you wish to control, with the value being the selector for
   *                    | the elements on which you want the state to be controlled by
   *                    |
   * data-toggle        | "visible", "disabled" or "enabled" - the state to toggle based on the other filters
   *
   * data-predicate     | "on" means the element will be controlled by the value of the element(s) selected by the
   *                    |  data-controlled-by attribute, using data-selector to match the value
   *                    |
   *                    | "any" means that the element will be controlled by any of the elements selected by the
   *                    | data-controlled-by attribute, using data-selector to filter the elements
   *                    |
   *                    | "all" means that the element will be controlled by all of the elements selected by the
   *                    | data-controlled-by attribute, using data-selector to filter the elements
   *                    |
   * data-selector      | the value or matcher used to determine state based on data-predicate
   **/
  $(document).on('controlledBy.bind', '[data-controlled-by]', function () {
    var el = this
    var $el = $(el)
    var controlledBy = $el.data('controlled-by')
    var toggle = $el.data('toggle')
    var predicates = {
      on: function ($element, values) {
        return values.indexOf($element.val()) !== -1
      },
      any: function ($elements, selectors) {
        return $elements.filter(selectors.join(', ')).length > 0
      },
      all: function ($elements, selectors) {
        return $elements.filter(selectors.join(', ')).length === $elements.length
      },
    }
    var handlers = {
      visible: {
        on: function ($el) {
          $el.show()
        },
        off: function ($el) {
          $el.hide()
        },
      },
      disabled: {
        on: function ($el) {
          $el.addClass('disabled').prop('disabled', true)
        },
        off: function ($el) {
          $el.removeClass('disabled').prop('disabled', false)
        },
      },
      enabled: {
        on: function ($el) {
          this['disabled']['off'].call(this, $el)
        },
        off: function ($el) {
          this['disabled']['on'].call(this, $el)
        },
      },
    }

    $(document).on('change.controlledBy', controlledBy, function () {
      var predicate = $el.data('predicate')
      var selectors = $.makeArray($el.data('selector'))

      if (predicates[predicate].call(this, $(controlledBy), selectors)) {
        handlers[toggle]['on'].call(handlers, $el)
      } else {
        handlers[toggle]['off'].call(handlers, $el)
      }
    })

    $(controlledBy).trigger('change')
  })

  $(document).ready(function () {
    $('[data-controlled-by]').trigger('controlledBy.bind')
  })
})(jQuery)
