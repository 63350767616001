import { on } from 'js/lib/events'
import { createAnalyticsGroup } from 'js/lib/analytics'

$(document).ready(function () {
  $('.sidebar-nav__main-links-hover .search_by_id').click(function () {
    $('.sidebar-nav__close-button').click()
  })

  //set off counter when impact panel in in view
  if ($('.dashboards-show').length > 0) {
    var elemTop = $('.homepage__impact__stats-container h3').offset().top
    var counted = false
    isImpactVisible()
  }

  function isImpactVisible() {
    var docViewTop = $(window).scrollTop()
    var docViewBottom = docViewTop + $(window).height()
    if (elemTop <= docViewBottom && counted == false) {
      count()
    }
  }

  function count() {
    counted = true
    $('.counter').each(function () {
      var $this = $(this)
      var countTo = $this.data('count')

      $({ countNum: $this.text() }).animate(
        {
          countNum: countTo,
        },
        {
          duration: 1500,
          easing: 'swing',
          step: function () {
            $this.text(Math.floor(this.countNum).toLocaleString())
          },
          complete: function () {
            $this.text(this.countNum.toLocaleString())
          },
        }
      )
    })
  }

  $(window).scroll(function () {
    isImpactVisible()
  })

  const el = '.js-homepage--tracking'

  on('click', el, event => {
    const anchorElement = event.target.closest(`a${el}`)

    if (anchorElement) {
      const track = createAnalyticsGroup(anchorElement.getAttribute('data-category'))
      const clickValue = anchorElement.getAttribute('data-click')
      track('click', clickValue)
    }
  })
})
