;(function (window, $) {
  //Confetti when adopted
  // Globals
  var canvas
  var ctx
  var W
  var H
  var mp = 150 //Max particles
  var particles = []
  var angle = 0
  var confettiActive = true
  var animationComplete = true
  var reactivationTimerHandler
  var animationHandler
  var requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        return setTimeout(callback, 1000 / 60)
      }
    )
  })()

  var particleColors = {
    colorOptions: ['DodgerBlue', 'Gold', 'pink', 'SlateBlue', 'lightblue', 'Violet', 'PaleGreen', 'Crimson'],
    colorIndex: 0,
    colorIncrementer: 0,
    colorThreshold: 10,
    getColor: function () {
      if (this.colorIncrementer >= 10) {
        this.colorIncrementer = 0
        this.colorIndex++

        if (this.colorIndex >= this.colorOptions.length) {
          this.colorIndex = 0
        }
      }

      this.colorIncrementer++
      return this.colorOptions[this.colorIndex]
    },
  }

  function confettiParticle(color) {
    this.x = Math.random() * W // X-coordinate
    this.y = Math.random() * H - H // Y-coordinate
    this.r = randomFromTo(10, 30) // Radius;
    this.d = Math.random() * mp + 10 // Density;
    this.color = color
    this.tilt = Math.floor(Math.random() * 10) - 10
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05
    this.tiltAngle = 0

    this.draw = function () {
      ctx.beginPath()
      ctx.lineWidth = this.r / 2
      ctx.strokeStyle = this.color
      ctx.moveTo(this.x + this.tilt + this.r / 4, this.y)
      ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4)
      return ctx.stroke()
    }
  }

  $(document).ready(function () {
    if ($('.confetti-canvas').length > 0) {
      setGlobals()
      window.InitializeConfetti()

      $(window).resize(function () {
        canvas.width = W = $(window).innerWidth()
        canvas.height = H = $(window).innerHeight()
      })
    }
  })

  function setGlobals() {
    canvas = $('.confetti-canvas').get(0)
    ctx = canvas.getContext('2d')
    W = $(window).innerWidth()
    H = $(window).innerHeight()
    canvas.width = W
    canvas.height = H
  }

  function draw() {
    ctx.clearRect(0, 0, W, H)
    var results = []
    for (var i = 0; i < mp; i++) {
      ;(function (j) {
        results.push(particles[j].draw())
      })(i)
    }
    update()

    return results
  }

  function randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from)
  }

  function update() {
    var remainingFlakes = 0
    var particle
    angle += 0.01

    for (var i = 0; i < mp; i++) {
      particle = particles[i]
      if (animationComplete) return

      if (!confettiActive && particle.y < -15) {
        particle.y = H + 100
        continue
      }

      stepParticle(particle, i)

      if (particle.y <= H) {
        remainingFlakes++
      }
      checkForReposition(particle, i)
    }

    if (remainingFlakes === 0) {
      window.StopConfetti()
    }
  }

  function checkForReposition(particle, index) {
    if ((particle.x > W + 20 || particle.x < -20 || particle.y > H) && confettiActive) {
      if (index % 5 > 0 || index % 2 == 0) {
        //66.67% of the flakes
        repositionParticle(particle, Math.random() * W, -10, Math.floor(Math.random() * 10) - 20)
      } else {
        if (Math.sin(angle) > 0) {
          repositionParticle(particle, -20, Math.random() * H, Math.floor(Math.random() * 10) - 20)
        } else {
          repositionParticle(particle, W + 20, Math.random() * H, Math.floor(Math.random() * 10) - 20)
        }
      }
    }
  }
  function stepParticle(particle, particleIndex) {
    particle.tiltAngle += particle.tiltAngleIncremental
    particle.y += (Math.cos(angle + particle.d) + 3 + particle.r / 2) / 2
    particle.x += Math.sin(angle)
    particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 15
  }

  function repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
    particle.x = xCoordinate
    particle.y = yCoordinate
    particle.tilt = tilt
  }

  function clearTimers() {
    clearTimeout(reactivationTimerHandler)
    clearTimeout(animationHandler)
  }

  window.InitializeConfetti = function () {
    particles = []
    animationComplete = false
    for (var i = 0; i < mp; i++) {
      var particleColor = particleColors.getColor()
      particles.push(new confettiParticle(particleColor))
    }
    window.StartConfetti()
  }

  window.StartConfetti = function () {
    ;(function animloop() {
      if (animationComplete) return null
      animationHandler = requestAnimFrame(animloop)
      return draw()
    })()
  }

  window.DeactivateConfetti = function () {
    confettiActive = false
    clearTimers()
    setTimeout(function () {
      $('.confetti-canvas').hide()
    }, 3500)
  }

  window.StopConfetti = function () {
    animationComplete = true
    ctx.clearRect(0, 0, W, H)
  }

  window.RestartConfetti = function () {
    clearTimers()
    window.StopConfetti()
    reactivationTimerHandler = setTimeout(function () {
      confettiActive = true
      animationComplete = false
      window.InitializeConfetti()
    }, 100)
  }
})(window, jQuery)
