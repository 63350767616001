$(function () {
  const input = $('.postcode-live-search .c-select-search-input')
  const list = $('.postcode-live-search .c-select-search-options')

  if ($('.postcode-live-search').length > 0) {
    input.keyup(function () {
      if (input.val().length > 2) {
        $.ajax({
          url: '/locations/postcode_live_search',
          data: {
            request_term: input.val(),
          },
          dataType: 'json',
          success: function (response) {
            var results = $.map(response.data, function (location, _) {
              return {
                id: location.value,
                text: location.text,
                state: location.metadata,
              }
            })
            list.empty()

            $.each(results, function (_, value) {
              list.append(
                $('<li>', {
                  'data-value': value.id,
                  'data-state': value.state,
                  tabindex: '-1',
                  text: value.text,
                })
              )
            })
          },
          cache: true,
        })
      }
    })
  }
})
