const menus = document.querySelectorAll('.js-c-menu-collapsible')

menus.forEach((menu) => {
  initMenu(menu)
})

function initMenu(menu) {
  const toggle = menu.classList.contains('js-c-menu-collapsible--toggle') ? menu : menu.querySelector('.js-c-menu-collapsible--toggle')
  const menuPanel = menu.querySelector('.js-c-menu-collapsible--panel')
  const chevron = toggle.querySelector('.js-c-menu-collapsible--icon')

  // if expanded option == true, close the menu
  if (toggle.getAttribute('aria-expanded') === 'false') {
    menuPanel.setAttribute('hidden', '')
    chevron.classList.add('tw-rotate-90')
  }

  menuPanel.open = toggle.getAttribute('aria-expanded') === 'true'

  toggle.addEventListener('click', () => {
    toggleMenu(menuPanel, toggle, chevron)
  })
}

function toggleMenu(menuPanel, toggle, chevron) {
  menuPanel.open = !menuPanel.open
  toggle.setAttribute('aria-expanded', `${menuPanel.open}`)

  if (menuPanel.open) {
    menuPanel.removeAttribute('hidden')
    chevron.classList.remove('tw-rotate-270')
  } else {
    menuPanel.setAttribute('hidden', '')
    chevron.classList.add('tw-rotate-270')
  }
}
