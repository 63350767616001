import $ from 'cash-dom'
import { createFlash } from 'components/flash/component'
import { renderFromFileList } from 'components/conversations/attachment_list/component'
import { notifyBugsnag } from 'js/lib/bugsnag'
import { requestNative } from 'js/lib/api/api-request'
import { createAnalyticsGroup } from 'js/lib/analytics'

// IMPORTANT
// Because we can't manipulate the file input due to security reasons, in order to support adding/removing
// multiple attachments, we need to control the message input via javascript and send new messages via ajax

const $form = $('.js-c-conversations-message-input')
const $textArea = $('.js-c-conversations-message-input__text-area')
const $fileInput = $('.js-c-add-attachement input')
const $attachmentList = $('.js-c-conversations-message-input__attachments')

const track = createAnalyticsGroup('conversations')

// We keep the state of the attachments in a list to render when changed
let fileListState = []

// Kick things off!
initialize()

function initialize() {
  if (!$form.length) return

  // Attach event handlers
  $form.on('submit', handleSubmission)
  $fileInput.on('change', handleFilesChange)
}

async function handleSubmission(event) {
  event.preventDefault()

  const formUrl = $form.attr('action')
  const formData = new FormData($form.get(0))

  if (!formData.get('message')?.length && !fileListState.length) {
    window.alert('You need to either attach some files or write a message!')
    return
  }

  disableFormElementsOnSubmit()
  fileListState.forEach(file => formData.append('attachments[]', file))

  track('message-sent', fileListState.length ? 'with-attachments' : 'without-attachments')

  try {
    await requestNative({ url: formUrl, data: formData })
    // Firefox likes to copy form text between submissions so here we clear it
    $textArea.attr('value', '')
    $fileInput.attr('value', '')

    window.location.reload()
  } catch (error) {
    if (error instanceof Error) throw error;

    // Currently we only process the first error
    const errorMessage = Object.values(error?.data?.errors || {})[0]

    // If the server doesn't supply us with an error message, make sure we notify bugsnag because that means
    // the server isn't responding correctly
    if (!errorMessage) notifyBugsnag(error)

    enableFormElementsAfterSubmit()
    createFlash('alert', errorMessage || "Sorry, we encountered an unknown error")
  }
}

function disableFormElementsOnSubmit() {
  $form.find('button').attr('disabled', true)
  $form.find('button[type="submit"]').text('Submitting...')
  $textArea.attr('disabled', true)
  $fileInput.attr('disabled', true)
}

function enableFormElementsAfterSubmit() {
  $form.find('button').attr('disabled', false)
  $form.find('button[type="submit"]').text('Submit')
  $textArea.attr('disabled', false)
  $fileInput.attr('disabled', false)
}

function triggerFileListRender() {
  renderFromFileList(fileListState, $attachmentList, { onRemoveFile: handleRemoveFile })
}

function handleRemoveFile(file) {
  fileListState = fileListState.filter(item => item !== file)
  triggerFileListRender()
}

function handleFilesChange(event) {
  const inputEl = event.target
  const inputFileArray = [...inputEl.files] // convert to array as file api doesnt like array methods

  if (inputFileArray.find(file => fileIsAboveLimit(file))) {
    window.alert(`Sorry, you can't upload an attachment larger than 10mb`)
    return
  }

  if ((fileListState.length + inputFileArray.length) > Number($fileInput.data('max-attachments'))) {
    window.alert(`Sorry, we only allow a maximum of 3 attachments`)
  }

  // only allow max 3 files
  fileListState = [...fileListState, ...inputFileArray].slice(0, 3)

  // clear the input to allow for new values and so not to overwrite the form data value at submission
  inputEl.value = ''

  triggerFileListRender()
}

function fileIsAboveLimit(file) {
  const numberOfBytes = file.size
  return numberOfBytes >= Number($fileInput.data('file-size-limit'))
}
