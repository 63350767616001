;(function ($) {
  $.humanFileSize = function (bytes) {
    var thresh = 1024
    var units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var u = -1

    if (Math.abs(bytes) < thresh) {
      return bytes + 'B'
    }

    do {
      bytes /= thresh
      ++u
    } while (Math.abs(bytes) >= thresh && u < units.length - 1)

    return bytes.toFixed(1) + units[u]
  }
})(jQuery)
