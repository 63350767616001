$(document).ready(function () {
  $('.js-c-expand-text-text').each(function(_, textBlock){
    let height = $(textBlock).height();
    let parentHeight = $(textBlock).parent().height();

    if (height < parentHeight) {
      $(textBlock).parent().find('.js-c-expand-text').hide();
    }
  })

  $('.js-c-expand-text').on('click', function() {
    $(this).parent().toggleClass('open')
    let text = $(this).parent().hasClass('open') ? 'Click to hide...' : 'Click to expand...'
    $(this).text(text)
  })
})
