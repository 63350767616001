const MutationObserver = window.MutationObserver || window.WebKitMutationObserver

const updateButtonState = (button) => {
  const iconBefore = button.querySelector('.js-c-ui-button__icon--before')
  const iconLoading = button.querySelector('.js-c-ui-button__icon--loading')

  if (!iconLoading) return

  const isLoading = button.getAttribute('data-loading') === 'true'

  if (isLoading) {
    if (iconBefore) iconBefore.classList.add('tw-hidden')
    iconLoading.classList.remove('tw-hidden')
    button.classList.add('tw-opacity-70', 'tw-cursor-wait')
    button.setAttribute('disabled', 'true')
  } else {
    if (iconBefore) iconBefore.classList.remove('tw-hidden')
    iconLoading.classList.add('tw-hidden')
    button.classList.remove('tw-opacity-70', 'tw-cursor-wait')
    button.removeAttribute('disabled')
  }
}

const setupObserver = (button) => {
  if (button.observer) return 
  
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.attributeName === 'data-loading') {
        updateButtonState(button)

        if (button.getAttribute('data-loading') === 'false') {
          observer.disconnect()
          button.observer = null 
        }
      }
    })
  })

  observer.observe(button, { attributes: true })
  button.observer = observer 
}

const handleClick = (event) => {
  const button = event.currentTarget
  if (button.getAttribute('data-loading') === 'true') {
    updateButtonState(button)
    setupObserver(button)
  }
}

const initButton = (button) => {
  button.addEventListener('click', handleClick)
}

document.querySelectorAll('.js-c-ui-button').forEach(initButton)
