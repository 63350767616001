;(function ($) {
  $.fn.expandable = function () {
    $(this).each(function () {
      var $self = $(this)
      var control = $(this).data('control')
      var target = $(this).data('target')
      var $control = $self.find(control)
      var $target = $self.find(target)
      var initialState = $(this).data('initialState')
      var trigger

      $control.on('click', function (e, data) {
        data = data || { duration: 400 }

        $target.stop(true, false).slideToggle(data.duration, function () {
          if ($(this).is(':visible')) {
            $self.removeClass('collapsed').addClass('expanded')
          } else {
            $self.removeClass('expanded').addClass('collapsed')
          }
        })

        e.preventDefault()
      })

      trigger =
        (initialState == 'expanded' && $target.is(':not(:visible)')) ||
        (initialState == 'collapsed' && $target.is(':visible'))

      if (trigger) {
        $control.trigger('click', { duration: 0 })
      }
    })
  }
})(jQuery)
