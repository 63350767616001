import { requestNative } from 'js/lib/api/api-request'
import { notifyBugsnag } from 'js/lib/bugsnag'

const unreadNotifications = document.querySelectorAll('.js-c-unread-items .js-c-notification-item')

function handleItemRead(notification) {
  notification.classList.remove('!tw-bg-[#d5edb6]')
  notification.classList.add('tw-animate-fade-in')
  document.querySelector('.js-c-read-items').prepend(notification)

  // If there are no more unread items, remove the section
  setTimeout(() => {
    const unreadNotifications = document.querySelectorAll('.js-c-unread-items .js-c-notification-item')
    if (unreadNotifications.length === 0) {
      const unreadSection = document.querySelector('.js-c-unread-items')
      if (unreadSection) {
        unreadSection.remove()
      }
      // Hide the indicator
      const indicator = document.querySelector('.js-c-unread-items-indicator')
      if (indicator) {
        indicator.style.display = 'none';
      }
    }
  }, 0)
}

// Function to handle the mark as read button click
async function handleMarkAllAsRead() {
  try {
    const response = await requestNative({
      url: '/notification_views/bulk_create',
      method: 'POST',
      data: { notification_type: 'ui' },
    })

    if (response && response.status === 'success') {

      unreadNotifications.forEach(notification => {
        handleItemRead(notification)
      })
    } 
  } catch (error) {
    notifyBugsnag(error)
  }
}

async function handleNotificationItemClick(event) {

  const notification = event.target.closest('.js-c-notification-item')
  if (!notification) return

  try {
    const response = await requestNative({
      url: '/notification_views',
      method: 'POST',
      data: { 
        notification_type: 'ui',
        id: notification.dataset.id,
      },
    })

    if (response && response.status === 'success') {
      handleItemRead(notification)
    } 
  } catch (error) {
    notifyBugsnag(error)
  }
}
// Event listener for the mark as read button
const markAllAsReadButton = document.querySelector('.js-c-mark_as_read');
if (markAllAsReadButton) {
  markAllAsReadButton.addEventListener('click', handleMarkAllAsRead)
}

// Event listener for the notification item click
// const notificationsDropdown = document.querySelector('.js-c-notifications-dropdown')
const notificationItems = document.querySelectorAll('.js-c-notification-item')
notificationItems.forEach((notification) => {
  notification.addEventListener('click', handleNotificationItemClick)
})
