/*
  We load the helpscout beacon on everypage so it can track users properlly, however we do not initiate it
  unless the page classes are any of the following
*/

const SCREEN_WIDTH_CONSTRAINT = 500
const DISABLED_WHEN_CLASSES = ['admin', 'listings-feed_show', 'feed', 'charter', 'adopted_slideshow', 'pethack', 'js-conversations-ui']
const SHOW_WHEN_CLASSES = ['contact_form']
const BEACON_ID_MAP = {
  public: 'b0d02eaf-2c4c-4edc-b2ea-743627978e27',
  members: '1db66e8f-6517-455c-a55e-03f566259a31',
}

let helpscoutInitiated = false

export function initHelpScoutBeacon() {
  if (helpscoutInitiated) return true

  const helpscoutBeaconScript = document.getElementById('js-c-helpscout-beacon')

  if (!helpscoutBeaconScript || typeof window.Beacon !== 'function') {
    return false
  }

  const kind = helpscoutBeaconScript.dataset.kind
  if (!BEACON_ID_MAP[kind]) {
    throw new Error('Helpscout beacon id not found')
  }

  window.Beacon('init', BEACON_ID_MAP[kind])

  // We add some metadata to the user object so that our helpdesk team can id the user easily.
  // This info will show up in the helpscout UI.
  // https://developer.helpscout.com/beacon-2/web/javascript-api/#beaconidentify-userobject
  window.Beacon('identify', getUserData(helpscoutBeaconScript))
  helpscoutInitiated = true
  return true
}

function initializeOnPageLoad() {
  /*
  If the browser viewport size is mobile size then don't initialize the beacon initally otherwise it
  will cover the sticky ad unit.
*/
  if (window.innerWidth <= SCREEN_WIDTH_CONSTRAINT) return
  if (bodyHasClasses(DISABLED_WHEN_CLASSES) && !bodyHasClasses(SHOW_WHEN_CLASSES)) return
  initHelpScoutBeacon()
}

initializeOnPageLoad()

function bodyHasClasses(classList) {
  for (const className of classList) {
    if (document.body.classList.contains(className)) return true
  }
  return false
}

function getUserData(tag) {
  const name = tag.dataset.userName
  const email = tag.dataset.userEmail

  // The following are custom HelpScout profile properties
  // Read more here:
  // https://docs.helpscout.com/article/1385-customer-properties#create
  const userProfileUrl = tag.dataset.userProfileUrl
  const userGroupUrl = tag.dataset.userGroupUrl

  return {
    name,
    email,
    'user-profile-url': userProfileUrl,
    'user-group-url': userGroupUrl,
  }
}
