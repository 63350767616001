$(document).ready(function () {
  var petHackTimer = $('.pethack-2020__countdown-clock__timer')

  if (!petHackTimer.length) {
    return
  }

  var dateStarts = new Date(petHackTimer.data('date-starts'))

  var weeksEl = petHackTimer.find('[data-timer-value=weeks]')
  var daysEl = petHackTimer.find('[data-timer-value=days]')
  var hoursEl = petHackTimer.find('[data-timer-value=hours]')
  var minuteEl = petHackTimer.find('[data-timer-value=minute]')
  var secondsEl = petHackTimer.find('[data-timer-value=seconds]')

  var oneWeek = 1000 * 60 * 60 * 24 * 7
  var oneDay = 1000 * 60 * 60 * 24
  var oneHour = 1000 * 60 * 60
  var oneMinute = 1000 * 60
  var oneSecond = 1000

  var timerMap = [
    [oneWeek, weeksEl],
    [oneDay, daysEl],
    [oneHour, hoursEl],
    [oneMinute, minuteEl],
    [oneSecond, secondsEl],
  ]

  setInterval(function () {
    var differenceToNow = dateStarts - new Date()

    timerMap.reduce(function (remaining, times) {
      var timeAmount = times[0]
      var timeEl = times[1]
      var total = Math.floor(remaining / timeAmount)
      timeEl.html(total + '&nbsp;')
      return remaining - total * timeAmount
    }, differenceToNow)
  }, 1000)
})
