function initTooltip(tooltipContainer) {
  const triggerId = tooltipContainer.dataset.trigger
  const trigger = document.getElementById(triggerId)
  const tooltip = tooltipContainer.querySelector('.js-c-ui-tooltip')
  const tooltipId = tooltipContainer.querySelector('.js-c-ui-tooltip--text').id

  if (!trigger || !tooltip) {
    return
  }

  let isHovered = false
  let isFocused = false
  let isOpen = false
  let timeoutId

  function toggleTooltip(tooltip, trigger, isOpen) {
    tooltip.style.display = isOpen ? 'block' : 'none'
    tooltip.setAttribute('aria-hidden', !isOpen)

    if (isOpen) {
      if (tooltipId) {
        trigger.setAttribute('aria-describedby', tooltipId)
      }
      document.addEventListener('keydown', handleEscapeKey)
    } else {
      trigger.removeAttribute('aria-describedby')
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }

  function delayedToggleTooltip(open) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      isOpen = open
      toggleTooltip(tooltip, trigger, open)
    }, 500)
  }

  function closeTooltip() {
    isOpen = false
    toggleTooltip(tooltip, trigger, false)
  }

  function handleMouseEnter() {
    isHovered = true
    delayedToggleTooltip(true)
  }

  function handleMouseLeave() {
    isHovered = false
    if (!isFocused) {
      delayedToggleTooltip(false)
    }
  }

  function handleFocus() {
    isFocused = true
    toggleTooltip(tooltip, trigger, true)
  }

  function handleBlur() {
    isFocused = false
    if (!isHovered) {
      delayedToggleTooltip(false)
    }
  }

  function handleEscapeKey(event) {
    if (event.key === 'Escape') {
      closeTooltip()
    }
  }

  trigger.addEventListener('mouseenter', handleMouseEnter)
  trigger.addEventListener('mouseleave', handleMouseLeave)
  trigger.addEventListener('focus', handleFocus)
  trigger.addEventListener('blur', handleBlur)
}

function initializeTooltips() {
  const tooltips = document.querySelectorAll('.js-c-ui-tooltip--container')

  tooltips.forEach(tooltip => {
    initTooltip(tooltip)
  })
}

initializeTooltips()
