$(document).ready(function () {
  if ($('.user_settings-adopter_profile').length) {
    var autocomplete
    var geocoder = new google.maps.Geocoder()
    var formSubmitted = false
    var componentForm = {
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      postal_code: 'short_name',
    }

    var states = {
      NSW: 1,
      VIC: 2,
      QLD: 3,
      NT: 4,
      WA: 5,
      SA: 6,
      ACT: 7,
      TAS: 8,
    }

    autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), { types: ['geocode'] })

    var circle = new google.maps.Circle({
      center: { lat: -36.686043, lng: 143.580322 },
      radius: 47,
    })
    autocomplete.setBounds(circle.getBounds())

    // When the user selects an address from the dropdown, populate the address
    // fields in the form.
    autocomplete.addListener('place_changed', fillInAddress)

    function fillInAddress() {
      // Get the place details from the autocomplete object.
      var place = autocomplete.getPlace()

      // Get each component of the address from the place details
      // and fill the corresponding field on the form.
      $('#user_address_attributes_line1').val(place.name)
      if (place.address_components) {
        updateFields(place.address_components)
      }
    }

    $('.edit_user_profile').submit(function (e) {
      var address = $('#address').val().toLowerCase()
      var emptyFields =
        $('#user_address_attributes_suburb').val() === '' || $('#user_address_attributes_postcode').val() === ''
      var addressChange =
        address.indexOf($('#user_address_attributes_suburb').val().toLowerCase()) != 0 ||
        address.indexOf($('#user_address_attributes_postcode').val().toLowerCase()) != 0

      if ($('#address').val() === '') {
        $('.addess_fields input').val('')
        $('.addess_fields select').val('')
      }

      if (formSubmitted != true && (emptyFields || (addressChange && $('#address').val() != ''))) {
        e.preventDefault()
        geocoder.geocode({ address: $('#address').val() }, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            updateFields(results[0].address_components)
          }
          formSubmitted = true

          // Ensure that if there is a submit button with a value that triggered this submission,
          // that this submit button is clicked so that it's value is also sent to the server. Otherwise
          // submitting the form via js like this would omit that value
          if (e.originalEvent && e.originalEvent.submitter) {
            $(e.originalEvent.submitter).click()
          } else {
            $('.edit_user_profile').submit()
          }
        })
      }
    })

    function updateFields(components) {
      for (var i = 0; i < components.length; i++) {
        var addressType = components[i].types[0]
        if (componentForm[addressType]) {
          var val = components[i][componentForm[addressType]]
          $('.' + addressType + ' input').val(val)
          $('.' + addressType + ' select')
            .val(states[val])
            .trigger('change')
        }
      }
    }
  }
})
