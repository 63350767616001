import { createAnalyticsGroup } from 'js/lib/analytics'

const banner = document.querySelector('.js-c-banner__campaign-banner')

if (banner) {
  const trackingGroup = banner.getAttribute('data-tracking-group') || 'default_group'
  const track = createAnalyticsGroup(`header_campaign_banner_${trackingGroup}`)
  const ctaButton = banner.querySelector('.js-c-banners__campaign-banner--cta')

  ctaButton.addEventListener('click', () => { 
    track('click', 'cta button')
  })
}
