/**
 * A small set of helper functions for event delegation
 * Works exactly like jquery's delegation
 */

const activeEvents = {}

/**
 * Add an event
 * @param  {String}   eventName The event name
 * @param  {String}   selector The selector to run the event on
 * @param  {Function} callback The function to run when the event fires
 */
export function on(eventName, selector, callback) {
  if (!selector || !callback) throw new Error('elementSelector and Callback required')
  eventName = eventName.trim()
  if (!activeEvents[eventName]) {
    activeEvents[eventName] = []
    document.addEventListener(eventName, eventHandler, true)
  }
  activeEvents[eventName].push({ selector, callback })
}

/**
 * Remove an event
 * @param  {String}   eventName The event name
 * @param  {String}   selector The selector to run the event on
 * @param  {Function} callback The function to run when the event fires
 */
export function off(eventName, selector, callback) {
  eventName = eventName.trim()
  if (!activeEvents[eventName]) return
  if (activeEvents[eventName].length < 2 || !selector) {
    delete activeEvents[eventName]
    document.removeEventListener(eventName, eventHandler, true)
    return
  }
  // Otherwise, remove event
  const index = getIndex(activeEvents[eventName], selector, callback)
  if (index < 0) return
  activeEvents[eventName].splice(index, 1)
}

/**
 * Add an event which fires only once
 * @param  {String}   eventName The event name
 * @param  {String}   selector The selector to run the event on
 * @param  {Function} callback The function to run when the event fires
 */
export function once(eventName, selector, callback) {
  on(eventName, selector, function temp(event) {
    callback(event)
    off(eventName, selector, temp)
  })
}

function getIndex(arr, selector, callback) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].selector === selector && arr[i].callback.toString() === callback.toString()) return i
  }
  return -1
}

function doRun(target, selector) {
  if (
    ['*', 'window', 'document', 'document.documentElement', window, document, document.documentElement].indexOf(
      selector
    ) > -1
  )
    return true
  if (typeof selector !== 'string' && selector.contains) {
    return selector === target || selector.contains(target)
  }
  return target.closest(selector)
}

function eventHandler(event) {
  if (!activeEvents[event.type]) return
  activeEvents[event.type].forEach(function (listener) {
    if (!doRun(event.target, listener.selector)) return
    listener.callback(event)
  })
}
