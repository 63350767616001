import $ from 'cash-dom'
import scrollIntoView from 'scroll-into-view-if-needed'
import { requestNative } from 'js/lib/api/api-request'
import { createAnalyticsGroup } from 'js/lib/analytics'

const componentEl = $('.js-c-ai-complete-profile')
const profileCompletionBtnEl = $('.js-c-ai-complete-profile-btn')
const track = createAnalyticsGroup('ai-profile-completions')

init()

function init() {
  if (!componentEl.length) return
  profileCompletionBtnEl.on('click', handleProfileCompletion)
}

async function handleProfileCompletion(event) {
  event.preventDefault()
  
  const { action, inputs, groupLabel } = getConfig(componentEl)
  const data = {
    listing_name: inputs.name.val(),
    species_category: inputs.species_category.text(),
    gender: inputs.gender.val(),
    notes: inputs.notes.val(),
    entry: inputs.entry.val(),
    output: inputs.output.val(),
    embeddings: inputs.embeddings.val(),
    counter: inputs.counter.val()
  }

  track('started', groupLabel)

  if (!data.listing_name.length || !data.notes.length) {
    window.alert(`These name and personality fields are required to use this feature`)
    return
  }

  const oldText = profileCompletionBtnEl.text()
  profileCompletionBtnEl.text('AI is thinking (give it 30 seconds or so)...')
  profileCompletionBtnEl.attr('disabled', true)

  try {
    const result = await requestNative({ url: action, data })
    // append the result to the input
    inputs.entry.val(inputs.notes.val())
    inputs.output.val(result.completion)
    inputs.embeddings.val(result.embeddings)
    inputs.counter.val(parseInt(inputs.counter.val()) + 1)
    inputs.notes.val(`${inputs.notes.val()}\n\n${result.completion}`)
    scrollIntoView(inputs.notes[0], { behavior: 'smooth'})
    track('completed', groupLabel)
  } catch (error) {
    window.alert('There was an error fetching ')
  }

  profileCompletionBtnEl.attr('disabled', null)
  profileCompletionBtnEl.text(oldText)
}

function getConfig(rootEl) {
  const action = rootEl.data('action')
  const groupLabel = rootEl.data('group-label')
  const inputs = {
    name: $(rootEl.data('name-selector')),
    species_category: $(`${rootEl.data('species-category-selector')}`),
    gender: $(rootEl.data('gender-selector')),
    notes: $(rootEl.data('notes-selector')),
    entry: $(rootEl.data('profile-entry-selector')),
    output: $(rootEl.data('profile-output-selector')),
    embeddings: $(rootEl.data('profile-embeddings-selector')),
    counter: $(rootEl.data('profile-counter-selector')),
  }

  if (!inputs.name.length || !inputs.notes.length || !action) {
    // This means the component was unabled to find the required inputs above on the page
    throw new Error('Supporting input for name or notes not found for ai profile completions')
  }

  return { inputs, action, groupLabel }
}