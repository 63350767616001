;(function ($) {
  var attempt = 0
  var $navItem = $('.header--external__navigation li.conversations, .js-conversations-icon')
  var pollUrl = $navItem.data('poll-url')
  var isVisible = true

  if (!pollUrl) {
    // exit early if no poll url can be found
    return
  }

  onVisabilityChange(function (visibility) {
    isVisible = visibility === 'visible'
  })

  function updateUnreadConversationsCount() {
    var oneMinute = 1000 * 60 // set the polling time
    var pollDelay = attempt === 0 ? 0 : oneMinute

    setTimeout(function () {
      var queue = $.Deferred()
      queue
        .then(function () {
          if (isVisible) {
            return $.ajax({
              url: pollUrl,
              type: 'GET',
              dataType: 'json',
            }).then(handleUpdatedConversations)
          }
        })
        .then(updateUnreadConversationsCount)

      queue.resolve()
    }, pollDelay)
  }

  function handleUpdatedConversations(response) {
    attempt = attempt + 1
    $navItem.find('.unread-count').remove()
    if (response.data.count > 0) {
      // This is pretty hacky and should be handled by header/user_icon_nav/component.js
      if ($('.js-c-conversations-indicator').length) {
        $(".js-c-conversations-indicator").removeClass("tw-hidden")
      } else {
        $('<i>', {
          class: 'unread-count',
        }).appendTo($navItem)
      }      
    }
  }

  // kick everything off!
  updateUnreadConversationsCount()
})(jQuery)

/*
    Browser support is choppy for the visability api. Here we're just
    standardising it.
*/
function onVisabilityChange(callback) {
  var hidden, visibilityChange
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }

  function handleVisibilityChange() {
    var status = document[hidden] ? 'hidden' : 'visible'
    callback(status)
  }

  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}
