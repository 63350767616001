import I18n from 'js/lib/i18n.js.erb'

$(document).ready(function () {
  const $wrapper = $('.js-two-factor-auth')
  if ($wrapper.length === 0) return

  const $enabledCheckbox = $wrapper.find('.js-2fa-enabled-switch input[type="checkbox"]')
  const $phoneNumberEnabled = $wrapper.find('.js-2fa-number-enabled-switch')
  const $phoneNumberEnabledInput = $phoneNumberEnabled.find('input[type="checkbox"]')
  const $mobileWrapper = $wrapper.find('.js-2fa-number-wrapper')
  const $mobileInput = $mobileWrapper.find('input')

  function handleEnabledChange() {
    const enabled = $enabledCheckbox.prop('checked')
    if (enabled) {
      $phoneNumberEnabled.stop(true).slideDown()
      handlePhoneEnabledChange()
    } else {
      $mobileInput.val('') // clear the input
      $phoneNumberEnabled.stop(true).slideUp()
      $mobileWrapper.stop(true).slideUp()
    }
  }

  function handlePhoneEnabledChange() {
    const phoneNumberEnabled = $phoneNumberEnabledInput.prop('checked')
    if (phoneNumberEnabled) {
      $mobileWrapper.stop(true).slideDown()
    } else {
      $mobileInput.val('') // clear the input
      $mobileWrapper.stop(true).slideUp()
    }
  }

  $enabledCheckbox.on('change', handleEnabledChange)
  $phoneNumberEnabledInput.on('change', handlePhoneEnabledChange)

  $mobileInput.intlTelInput({
    initialCountry: 'AU',
    formatOnInit: true,
    hiddenInput: $mobileInput.prop('name'),
    autoPlaceholder: 'aggressive',
    customPlaceholder: function () {
      return I18n.t('intl_tel_input.placeholder')
    },
  })
})
