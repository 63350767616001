//GA tracking of release notes
import { createAnalyticsGroup } from 'js/lib/analytics'

export const track = createAnalyticsGroup('Release Notes Clicks')

$('body').on('click', '[data-release-notes-month]', function() {
  const month = $(this).data('release-notes-month')
  if (month) {
    track('click',  'Release note month - ' + month)
  }
})
