import $ from 'cash-dom'
import { createAnalyticsGroup } from 'js/lib/analytics'

const track = createAnalyticsGroup('Adoption Poster Download')

//GA analytics
$('.js-c-adoption-poster').on('click', event => {
  const user = event.target.dataset.user
  track('click', user)
})
