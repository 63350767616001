const $groupEditPage = $('.groups-controller.edit-action')
const $logoImage = $groupEditPage.find('.avatar.image .icon img')
const $logoInput = $groupEditPage.find('.avatar.image input#group_avatar')
const $logoToggle = $groupEditPage.find('#group_remove_avatar')

// Todo: This slector is a wide net to cast - would be better to go for a more specific selector to avoid clashes.
$('.image button').click(function (e) {
  $(this).parent().find('.hidden input').click()
  e.preventDefault()
})

$logoInput.on('change', function (event) {
  const input = event.currentTarget

  if (input.files && input.files[0]) {
    var reader = new FileReader()
    var count = 0

    reader.onload = function (e) {
      var parent = $(input).closest('.image')

      if (parent.hasClass('multiple_images')) {
        parent.find('.icon').append('<div class="photo"><img src="' + e.target.result + '"></div>')
      } else {
        parent.find('.icon img').attr('src', e.target.result).removeClass('hidden')
      }

      count = count + 1

      if (input.files.length > count) {
        reader.readAsDataURL(input.files[count])
      }
    }
    reader.readAsDataURL(input.files[count])

    $logoToggle.prop('checked', false)
    $.uniform.update()
  }
})

$logoToggle.on('change', function () {
  $logoImage.toggleClass('hidden')
})
