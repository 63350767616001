const caseCard = document.querySelectorAll('.js-c-case-list__card')

function initCaseCard(showPets) {

  const trigger = showPets.querySelector('.js-c-show-pets')
  const content = showPets.querySelector('.js-c-show-pets-content')

  if (!trigger || !content) {
    return
  }

  let petsVisible = false

  function toggleContent() {
    content.classList.toggle('tw-hidden')
    petsVisible = !content.classList.contains('tw-hidden')
    trigger.setAttribute('aria-expanded', petsVisible)
    trigger.textContent = petsVisible ? 'Hide pet details' : 'Show pet details'
  }

  trigger.addEventListener('click', toggleContent)
}


caseCard.forEach((showPets) => {
  initCaseCard(showPets)
})
