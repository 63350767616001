;(function () {
  PetRescue.User = (function () {
    User.prototype.is_admin = false
    User.prototype.signed_in = false

    function User() {
      this.el = $('#site-wrapper')
      if (this.el.hasClass('user_signed_in')) {
        this.signed_in = true
        this.name = this.el.find('.name')
        if (this.el.find('a[href^="/admin"]').length > 0) {
          this.is_admin = true
        }
      }
    }

    return User
  })()

  $(function () {
    return (PetRescue.user = new PetRescue.User())
  })
}.call(window))
