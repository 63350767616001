const dropdowns = document.querySelectorAll('.js-c-dropdown')

function initDropdowns(dropdown) {
  const trigger = dropdown.querySelector('.js-c-dropdown-trigger')
  const content = dropdown.querySelector('.js-c-dropdown-content')
  const activeTriggers = document.querySelectorAll('[data-dropdown-active-class]')

  let isExpanded = false

  // Set an active class on any element to be applied when the dropdown is expanded.
  // Example: data: { 'dropdown-active-class': 'tw-rotate-[20deg]'}
  function toggleActiveTriggers(expanded, dropdown) {
    activeTriggers.forEach((activeTrigger) => {
      if (activeTrigger.closest('.js-c-dropdown') === dropdown) {
        const activeClassValue = activeTrigger.getAttribute('data-dropdown-active-class')
        
        if (activeClassValue) {
          const activeClasses = activeClassValue.split(' ')
          
          if (expanded) {
            activeClasses.forEach((activeClass) => {
              activeTrigger.classList.add(activeClass)
            })
          } else {
            activeClasses.forEach((activeClass) => {
              activeTrigger.classList.remove(activeClass)
            })
          }
        }
      }
    })
  }
  

  trigger.addEventListener('click', function (event) {
    event.preventDefault()
    isExpanded = !content.classList.toggle('hidden')
    trigger.setAttribute('aria-expanded', isExpanded)

    if (isExpanded) {
      setMaxHeight(content, true)
      setLeftRightPosition(content, true)
      document.addEventListener('click', closeDropdownOutside)
      document.addEventListener('keydown', handleEscapeKey)
      window.addEventListener('resize', handleWindowResize)
      toggleActiveTriggers(true, dropdown) 
    } else {
      setMaxHeight(content, false)
      setLeftRightPosition(content, true)
      document.removeEventListener('click', closeDropdownOutside)
      document.removeEventListener('keydown', handleEscapeKey)
      window.removeEventListener('resize', handleWindowResize)
      toggleActiveTriggers(false, dropdown) // Remove active class from triggers
    }
  })

  const closeElements = dropdown.querySelectorAll('[data-dropdown="close"]')
  closeElements.forEach(function (closeElement) {
    closeElement.addEventListener('click', function (event) {
      event.preventDefault()
      closeDropdown()
    })
  })

  function closeDropdown() {
    if (isExpanded) {
      content.classList.add('hidden')
      trigger.setAttribute('aria-expanded', false)
      isExpanded = false
      setMaxHeight(content, false)
      setLeftRightPosition(content, true)
      document.removeEventListener('click', closeDropdownOutside)
      document.removeEventListener('keydown', handleEscapeKey)
      window.removeEventListener('resize', handleWindowResize)
      toggleActiveTriggers(false, dropdown) // Remove active class from triggers
    }
  }

  function closeDropdownOutside(event) {
    if (!dropdown.contains(event.target) && !trigger.contains(event.target)) {
      closeDropdown()
    }
  }

  function handleEscapeKey(event) {
    if (event.key === 'Escape') {
      closeDropdown()
    }
  }

  function handleWindowResize() {
    if (isExpanded) {
      setMaxHeight(content, true)
      setLeftRightPosition(content, true)
    }
  }

  // Sets the max height of the dropdown
  // so it doesn't exceed the bottom of the viewport
  // if the dropdown is longer than the viewport, it will lock the scroll 
  // (adds a right padding to compensate the page shift)
  function setMaxHeight(dropdown, isExpanded) {
    const distanceFromTop = dropdown.getBoundingClientRect().top
    const viewportHeight = window.innerHeight
    const maxHeight = viewportHeight - distanceFromTop
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

    if (dropdown.offsetHeight >= maxHeight && isExpanded) {
      document.body.style.overflow = 'hidden'
      document.body.style.paddingRight = `${scrollbarWidth}px`
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.paddingRight = '0'
    }
    dropdown.style.maxHeight = `${maxHeight}px`
  }

  function setLeftRightPosition(dropdownContent, isExpanded) {
    if (!isExpanded) return

    const dropdownContentWidth = dropdownContent.getBoundingClientRect().width
    const dropdownContentOffsetLeft = dropdownContent.getBoundingClientRect().left
    const dropdownContentOffsetRight = window.innerWidth - (dropdownContentOffsetLeft + dropdownContentWidth)

    const dropdown = dropdownContent.closest('.js-c-dropdown')
    const dropdownWidth = dropdown.getBoundingClientRect().width
    const dropdownOffsetLeft = dropdown.getBoundingClientRect().left
    const dropdownOffsetRight = window.innerWidth - (dropdownOffsetLeft + dropdownWidth)

    // when the dropdown content and its closest edge to the screen's edge both fit within the viewport width, 
    // the dropdown should not be fixed to the left or right side,
    // And will unstick and revert to it's default position
    // This is for when the window changes size
    const unstick = (dropdownContentWidth + Math.min(dropdownOffsetLeft, dropdownOffsetRight)) < window.innerWidth 

    // Stick left
    if (dropdownContentOffsetLeft <= 0) {
      dropdownContent.style.left = '0'
      dropdownContent.style.position = 'fixed'
    } 
    // Stick Right
    if (dropdownContentOffsetRight <= 0) {
      dropdownContent.style.right = '0'
      dropdownContent.style.position = 'fixed'
    }
    // Unstick
    if (unstick) {
      dropdownContent.style.right = ''
      dropdownContent.style.left = ''
      dropdownContent.style.position = ''
    }
  }
}

dropdowns.forEach((dropdown) => {
  initDropdowns(dropdown)
})
