$(document).ready(function () {
  $('.admin-tools-photo_search .filter-box-options .active a').click(function (e) {
    var url = $(this).attr('href')

    url = url.split('&')
    url.shift()
    window.location.replace(window.location.origin + window.location.pathname + '?' + url.join('&'))
    e.preventDefault()
  })

  $('.excluded_groups-action').find('select').select2({
    width: '83%',
  })
})
