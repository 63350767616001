import { createAnalyticsGroup } from 'js/lib/analytics'

const track = createAnalyticsGroup('footer_popup__campaign')
const banners = document.querySelectorAll('.js-c-ui-floating-banner')

function initBanner(banner) {
  if (!banner) return
  
  $('.js-c-ui-floating-banner--reminder').on('click', function () {
    trackClickEvent('remind_me_later_button')
  })

  $('.js-c-ui-floating-banner--close').on('click', function () {
    trackClickEvent('close_button')
  })

  $('.js-c-ui-floating-banner--cta').on('click', function () {
    trackClickEvent('donate_button_click')
  })
}

// Track events for GA4 - Callback
function trackClickEvent(eventName) {
  track('click', eventName)
}

banners.forEach((banner) => {
  initBanner(banner)
})
