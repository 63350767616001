import { createAnalyticsGroup } from 'js/lib/analytics'

export const track = createAnalyticsGroup('Newsletter Subscribe')

;(function ($) {
  $.petrescue.init('.cards-common-subscribe-newsletter', function () {
    $('.cards-common-subscribe-newsletter .button').on('click', function (e) {
      //GA analytics
      const page = e.target.dataset.page
      track('subscribe', page)

      e.preventDefault()
      const $emailInput = $('.cards-common-subscribe-newsletter input.email')
      const $nameInput = $('.cards-common-subscribe-newsletter input.name')
      const list_subscription = {
        email: $emailInput.val(),
        name: $nameInput.val(),
      }

      const mc_segments = []
      const mc_tags = ['signed_up_via_subscribe_page']

      $emailInput.parent().removeClass('error')
      $('.cards-common-subscribe-newsletter__subscribe_confirm').empty()

      if ($('#animal_welfare_segment').length > 0) mc_segments.push('animal_welfare_strategy')
      if ($('.cards-common-subscribe-newsletter__petrescue_post').length > 0) mc_segments.push('petrescue_post')

      $.ajax({
        type: 'POST',
        url: '/list_subscriptions',
        dataType: 'json',
        data: {
          list_subscription,
          list_type: 'core',
          mc_segments,
          mc_tags
        },
        success: function () {
          // The subscribe confirmation page is a blog post page
          window.location.replace(`/subscribe-confirmation?name=${$nameInput.val()}`);
        },
        error: function () {
          $emailInput.parent().addClass('error').focus()
        },
      })
    })
  })
})(jQuery)
