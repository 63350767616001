$(document).ready(function () {
  //fundrasing blogpost
  if ($('.example-slider')) {
    var slider = $('.example-slider')
    slider.on('unslider.ready', function () {
      $('.example-slider').css('visibility', 'visible')
      $('.unslider-arrow').hide()
    })

    slider.unslider({ nav: false, autoplay: true, speed: 1000, delay: 6000 })
  }

  $('.copy_link').click(function (e) {
    e.preventDefault()

    var $temp = $('<input>')
    $('body').append($temp)
    $temp.val($(this).find('.link_to_copy').text()).select()
    document.execCommand('copy')
    $temp.remove()

    $('.copy_link').css('background-color', '#5FA503')
    $('.copy_link .fa').removeClass('fa-link')
    $('.copy_link .fa').addClass('fa-check')
    setTimeout(function () {
      $('.copy_link').css('background-color', '#494949')
      $('.copy_link .fa').addClass('fa-link')
      $('.copy_link .fa').removeClass('fa-check')
    }, 1000)
  })
})
