;(function ($) {
  var handlerElement = document.body
  var selector = 'a[data-method=put],a[data-method=patch],a[data-method=post],a[data-method=delete]'
  var events = ['click', 'mousedown', 'mouseup']
  var namespacedEvents = $.map(events, function (event) {
    return [event, 'disable_method_links'].join('.')
  })

  $(handlerElement).on(namespacedEvents.join(' '), selector, function (e) {
    e.preventDefault()
  })
})(jQuery)
