/* global confirm */

document.addEventListener('DOMContentLoaded', function() {
  const formSelects = document.querySelectorAll('.js-rms-case-form select')

  formSelects.forEach(function(select) {
    select.addEventListener('change', function(event) {
      if (confirm('Are you sure you want to change the case manager?')) {
        event.target.closest('form').submit()
      } else {
        event.target.value = event.target.getAttribute('data-original-value')
      }
    })

    select.setAttribute('data-original-value', select.value)
  })
})
