// Reposition sticky elements to be below flash banner
// For example: the listing form control bar
function getFlashHeight() {
  const flashElement = document.querySelector('.js-c-flash')
  const repositionElement = document.querySelector('.js-reposition-flash_banner')
  if (flashElement && repositionElement) {
    repositionElement.style.top = `${flashElement.offsetHeight}px`
  }
}

window.addEventListener('DOMContentLoaded', getFlashHeight)
window.addEventListener('resize', getFlashHeight)

export function createFlash(type, message) {
  const container = document.createElement('div')
  container.className = 'c-flash js-c-flash component'

  const flash = document.createElement('div')
  flash.className = `c-flash__container js-c-flash__conatainer c-flash__container--${type}`

  const flashMessage = document.createElement('p')
  flashMessage.className = 'c-flash__message'
  flashMessage.textContent = message

  flash.appendChild(flashMessage)

  if (type === 'notice') {
    const counterElement = document.createElement('div')
    counterElement.className = 'c-flash__countdown js-c-flash__countdown js-c-flash__close'
    counterElement.innerHTML = `Closing message in <span class="js-c-flash__countdown__counter">5</span><i class="fa fa-times"></i>`
    counterElement.dataset.counter = 5

    flash.appendChild(counterElement)
    initFlashCounter(counterElement)
  }

  if (type === 'alert') {
    const closeButton = document.createElement('div')
    closeButton.className = 'c-flash__close js-c-flash__close'
    closeButton.textContent = 'Got it! Close this.'

    flash.appendChild(closeButton)
  }

  container.appendChild(flash)
  document.getElementById('site-wrapper').appendChild(container)
}

function closeFlash(event) {
  const flash = event.target.closest('.js-c-flash')
  const repositionElement = document.querySelector('.js-reposition-flash_banner')
  flash.style.display = 'none'
  flash.remove()
  if (repositionElement) {
    repositionElement.style.top = ''
  }
}

document.addEventListener('click', event => {
  if (event.target.matches('.js-c-flash__close')) {
    closeFlash(event)
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const countdownElements = document.querySelectorAll('.js-c-flash__countdown')
  if (countdownElements.length === 0) return
  countdownElements.forEach(initFlashCounter)
})

function initFlashCounter(element) {
  const flashContainer = element.closest('.js-c-flash')
  const counterElement = element.querySelector('.js-c-flash__countdown__counter')
  
  let counter = parseInt(element.dataset.counter)
  
  const interval = setInterval(() => {
    counterElement.textContent = --counter
    if (counter <= 0) {
      element.click()
      const repositionElement = document.querySelector('.js-reposition-flash_banner')
      if (repositionElement) {
        repositionElement.style.top = ''
      }
    }
  }, 1000)

  element.addEventListener('click', () => {
    clearInterval(interval)

    // Not sure why this would be empty but it's causing an error so testing for it
    if (flashContainer) {
      flashContainer.style.display = 'none'
      flashContainer.remove()
    }

    const repositionElement = document.querySelector('.js-reposition-flash_banner')
    if (repositionElement) {
      repositionElement.style.top = ''
    }
  }, { once: true })
}
