import $ from 'cash-dom'

const fieldBlock = $('.js-c-form-legacy-field-block')

// Adds error class to the parent when a field has a validation error
if (fieldBlock.length) {
  fieldBlock.each(function() {
    if ($(this).children('.error').length) {
      $(this).addClass('c-form-legacy-field-block--error')
    }
  })
}
