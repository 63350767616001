import { on } from 'js/lib/events'
import { createAnalyticsGroup } from 'js/lib/analytics'

const track = createAnalyticsGroup('search listings summary')

on('change', '.js-c-search-listings-summary .js-c-select', async event => {
  const name = event.target.name
  const selectedOption = event.target.options[event.target.selectedIndex]

  // sometimes this can be undefined if the select is somehow cleared
  if (!selectedOption) return

  track('select', `${name} - ${selectedOption.value}`)
  window.location.href = selectedOption.dataset.url ? selectedOption.dataset.url : event.target.dataset.url
})

on('click', '.js-c-search-listings-summary .js-c-tag > a.c-tag__inner', event => {
  track('remove tag', event.target.parentElement.dataset.name)
})
