import queryString from 'query-string'

$(document).ready(function () {
  if ($('body').is('.admin-logs-index')) {
    $('#type, #severity, #sheltermate_account_id, #per_page').change(function () {
      if (window.location.href.indexOf('?') == -1) {
        var url = window.location.href + '?' + $(this).attr('name') + '=' + $(this).val()
      } else {
        var params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
        var url = window.location.href.split('?')[0]

        params[$(this).attr('name')] = $(this).val()

        $.each(params, function (index, value) {
          if (url.indexOf('?') == -1) {
            url += '?' + index + '=' + value
          } else {
            url += '&' + index + '=' + value
          }
        })
      }
      window.location.href = url
    })
  }
})
