import I18n from 'js/lib/i18n.js.erb'
import { createFlash } from 'components/flash/component'

export async function requestNative(opts) {
  const { url, data, type = 'POST' } = opts
  const isFormData = data instanceof FormData
  const body = type === 'GET' ? undefined : isFormData ? data : JSON.stringify(data)
  const urlParams = type == 'GET' ? new URLSearchParams(data).toString() : undefined

  const headers = {
    'X-CSRF-Token': getCSRFToken(), // required for rails for csrf protection
    'X-Requested-With': 'XMLHttpRequest', // required for rails to respond correctly with .xhr?
    Accept: 'application/json',
  }

  // The headers must not contain a content type if the data provided is
  // in the format of a FormData instance:
  // https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
  if (!isFormData) headers['Content-Type'] = 'application/json'

  const resolvedUrl = urlParams ? `${url}?${urlParams}` : url

  const response = await window.fetch(resolvedUrl, {
    method: type,
    headers,
    credentials: 'same-origin',
    body,
  })

  if (!response.ok) {
    const error = await response.json()
    throw error
  }

  return await response.json()
}

// Note that this method only works with the below jquery request method
export function handleError(error, I18nScope) {
  if (typeof error === 'string') {
    createFlash('alert', error)
    return
  }

  const errorData = error?.responseJSON?.errors || {}
  const firstError =
    Array.isArray(errorData?.base) && errorData.base.find(e => typeof e === 'object' && e.code !== undefined)

  const errorMessage = I18nScope
    ? I18n.t(firstError.code, { scope: I18nScope, msg: firstError.message })
    : firstError.message

  createFlash('alert', errorMessage)
}

export async function request({ url, data, type = 'POST' }) {
  const response = await $.ajax({ url: url, type, dataType: 'json', data })
  return response
}

function getCSRFToken() {
  const csrfToken = document.querySelector("[name='csrf-token']")
  return csrfToken ? csrfToken.content : null
}
