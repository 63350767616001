import { register } from 'js/lib/create-component'
import { trackPurchase, trackConversion } from 'js/lib/analytics'

// Add this line back in to enable the christmas spirit on donation completions for christmas campaigns
// import { runChristmasProtocol } from 'js/public/christmas-protocol'

register('paymentTrackCompletion', function (stateJSON) {
  const stateData = JSON.parse(stateJSON)

  trackPurchase({
    content_name: stateData.content_name,
    transaction_id: stateData.transaction_id,
    affiliation: stateData.affiliation,
    currency: stateData.currency,
    value: stateData.value,
    frequency: stateData.frequency,
  })

  // track conversion in both adwords accounts
  // https://trello.com/c/IoGBZgsC/1770-add-gtm-tag-to-new-general-dog-cat-adoption-pages-as-well-as-the-new-xmas-donate-page-and-wave-1-pages
  trackConversion({ transaction_id: stateData.transaction_id, account_id: 'AW-363067286' })
  trackConversion({ transaction_id: stateData.transaction_id, account_id: 'AW-10806102563' })
  trackConversion({
    transaction_id: stateData.transaction_id,
    account_id: 'AW-10806102563',
    conversion_segment: 'lULqCMKSq74DEKOU4KAo',
  })

  // Add this line back in to enable the christmas spirit on donation completions for christmas campaigns
  // if (stateData.is_christmas) {
  //   runChristmasProtocol()
  // }

  return {}
})
