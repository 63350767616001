import { ValidationError } from 'js/lib/errors'
import { loadScript } from 'js/lib/script-loader'

// Note that currently we only allow for 1 instance of hosted feilds to
// be initialized on the page at any one time (who has 2x payment inputs?) - hence this singleton.
let fields

export async function initializePin(fieldConfig, hostedFieldsStyles) {
  await loadScript('https://cdn.pinpayments.com/pin.hosted_fields.v1.js')

  fields = HostedFields.create({
    sandbox: PetRescue.env.pinPayments.sandbox,
    styles: hostedFieldsStyles,
    fields: fieldConfig,
  })

  await new Promise(resolve => fields.on('ready', resolve))
}

export function tokenizeFields(getErrorText) {
  return new Promise((resolve, reject) => {
    fields.tokenize({ publishable_api_key: PetRescue.env.pinPayments.publishableKey }, function (error, response) {
      if (!error) return resolve(response.token)
      if (error.messages) {
        const fieldErrors = {}
        for (const err of error.messages) {
          fieldErrors['card_' + err.param] = getErrorText(err.code)
        }
        reject(new ValidationError('Pin hosted field validation error', fieldErrors))
      } else {
        reject(error)
      }
    })
  })
}
