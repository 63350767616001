export class ValidationError extends Error {
  constructor(message, detail) {
    super(message)
    this.name = 'VALIDATION_ERROR'
    this.detail = detail
  }

  static isValidationError(error) {
    return error instanceof ValidationError === true
  }
}
