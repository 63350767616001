/*
  This is a really simple implementation of the observable pattern. It's pretty much works like
  a mini event emitter. Note that we could use rxjx/bacon/most but these libraries have significant
  bloat and we don't need all their crazy functionality.
*/
export class Observable {
  constructor() {
    this.observers = []
    this.events = []
  }

  get emit() {
    return {
      event: payload => this.dispatch('event', payload),
      error: payload => this.dispatch('error', payload),
    }
  }

  dispatch(kind, payload) {
    const event = { kind, payload }
    this.events.push(event)
    this.observers.forEach(callback => callback(event, this.events))
  }

  observe(callback) {
    this.observers.push(callback)
    return () => this.observers.filter(c => c !== callback)
  }
}

export function waitForNextResult(observable) {
  return new Promise((resolve, reject) => {
    const unsubscribe = observable.observe(event => {
      if (event.kind === 'event') return resolve(event.payload)
      unsubscribe()
      reject(event.payload)
    })
  })
}
