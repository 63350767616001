$(document).ready(function () {
  $('form ol .password').append('<span class="password-show">SHOW</span>')

  $('.password-show').click(function () {
    var x = $(this).parent().find('input')
    if (x[0].type === 'password') {
      x[0].type = 'text'
      $(this).html('HIDE')
    } else {
      x[0].type = 'password'
      $(this).html('SHOW')
    }
  })
})
