import { setButtonLoadingState } from 'js/lib/button'

const twoFactorForms = document.querySelectorAll('.js-c-two-factor-form')

twoFactorForms.forEach((twoFactorForm) => {
  initTwoFactor(twoFactorForm)
})

function initTwoFactor(twoFactorForm) {
  const verificationCode = twoFactorForm.querySelector('.js-c-two-factor-form__verification-code')
  const submitButton = twoFactorForm.querySelector('.js-c-two-factor-form__submit')

  if (!verificationCode || !submitButton) return

  submitButton.disabled = true

  verificationCode.addEventListener('input', (e) => { handleVerificationCodeInput(e, submitButton) })

  twoFactorForm.addEventListener('submit', (e) => {
    e.preventDefault()
    if (!submitButton.disabled) {
      setButtonLoadingState(submitButton, true)
      HTMLFormElement.prototype.submit.call(twoFactorForm)
    }
  })
}

function handleVerificationCodeInput(event, submitButton) {
  const verificationCodeInput = event.target
  const code = verificationCodeInput.value.trim()

  if (code.length === 6) {
    submitButton.disabled = false
    submitButton.focus()
  } else {
    submitButton.disabled = true
  }
}
