import { createAnalyticsGroup } from 'js/lib/analytics'

$(document).ready(function () {
  const track = createAnalyticsGroup('Alert settings')
  $('.js-alerts-recent__manage_button').on('click', function () {
    track('clicked', 'button style')
  })

  $('.js-alerts-recent__manage_link').on('click', function () {
    track('clicked', 'link style')
  })
});
