$(document).ready(function () {
  $('.article_search').click(function (e) {
    e.preventDefault()
    $.ajax({
      url: 'admin/search_articles?term=' + $('#term').val(),
      type: 'GET',
      success: function (result) {
        $('.article_search_results').empty()
        $.each(result.data.blog_posts, function (_, value) {
          var url = value.page ? '/' : '/library/articles/'
          $('.article_search_results').append(
            '<li><a href="' + url + value.name + '?utm_source=members_home">' + value.title + '</a></li>'
          )
        })

        if (result.data.blog_posts.length == 0) {
          $('.article_search_results').append('<li>No results found</li>')
        }
      },
    })
  })
})
