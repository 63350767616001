import entries from 'object.entries'
import MarkerClusterer from '@google/markerclustererplus'
import loadGoogleMaps from '../lib/google-map-loader'
import { Geocoder } from '../lib/geocoder'
import { mapStyles } from './map-style'
import config from './map-config'

// Marker cluster images
import m1 from '@google/markerclustererplus/images/m1.png'
import m2 from '@google/markerclustererplus/images/m2.png'
import m3 from '@google/markerclustererplus/images/m3.png'
import m4 from '@google/markerclustererplus/images/m4.png'
import m5 from '@google/markerclustererplus/images/m5.png'
import markerIcon from 'images/icons/public_marker_icon.png'

export class RescueDirectoryMap {
  markers = []
  lastOpenedInfoWindow = null
  markerImage = null

  markerCluster = undefined
  map = undefined
  geocoder = undefined

  async init() {
    this.toggleMapLoadingScreen(true)

    await loadGoogleMaps()

    this.geocoder = new Geocoder()
    this.map = new google.maps.Map(document.getElementById('map'), {
      minZoom: config.minZoomLevel,
      zoom: config.initialZoomLevel,
      center: config.initialCenter,
    })

    const styledMapType = new google.maps.StyledMapType(mapStyles, {
      name: 'Styled Map',
    })

    this.map.mapTypes.set('styled_map', styledMapType)
    this.map.setMapTypeId('styled_map')

    this.markerImage = new google.maps.MarkerImage(
      markerIcon,
      new google.maps.Size(config.markerSize.width, config.markerSize.height)
    )

    await this.loadMapData()

    this.toggleMapLoadingScreen(false)

    this.map.addListener('click', () => this.closeInfoWindow())
  }

  async loadMapData(params) {
    const url = $('.sidebar.map-filters').data('url')

    this.toggleMapLoadingScreen(true)

    this.clearMarkers()
    this.closeInfoWindow()

    const response = await $.ajax({ url, type: 'GET', data: params })

    for (const [, data] of entries(response.results)) {
      const infoWindowContent = this.getInfoWindowContent(data.groups)
      if (!$.isEmptyObject(data.location)) {
        const location = new google.maps.LatLng(data.location.lat, data.location.lng)
        this.addMarker(location, infoWindowContent)
      }
    }

    this.redraw()
    this.toggleMapLoadingScreen(false)
  }

  redraw() {
    // overrides the marker cluster styles so we can use webpack to load the images
    const markerClusterStyles = [m1, m2, m3, m4, m5].map((url, index) => {
      const size = MarkerClusterer.IMAGE_SIZES[index]
      return MarkerClusterer.withDefaultStyle({
        url,
        height: size,
        width: size,
      })
    })

    const mcOptions = { zoomOnClick: true, styles: markerClusterStyles }
    this.markerCluster = new MarkerClusterer(this.map, this.markers, mcOptions)
    this.markers.forEach(marker => marker.setVisible(true))
  }

  clearMarkers() {
    if (this.markerCluster) {
      this.markerCluster.clearMarkers()
    }

    this.markers = []
    this.markerCluster = undefined
  }

  closeInfoWindow() {
    if (this.lastOpenedInfoWindow) this.lastOpenedInfoWindow.close()
    this.lastOpenedInfoWindow = null
  }

  toggleMapLoadingScreen(show = true) {
    if (show) {
      $('#map-loader-background').removeClass('hidden')
    } else {
      $('#map-loader-background').addClass('hidden')
    }
  }

  getInfoWindowContent(groups) {
    let content = ''
    for (const group of groups) {
      const $a = $('<a>', {
        target: '_blank',
        href: group.url,
        text: group.name,
      })
      if (typeof group.rating === 'number') {
        $a.append('<i>', { class: 'fa fa-star' }).append('<span>', {
          text: group.rating,
        })
      }
      content += $('<h4>').append($a).prop('outerHTML')
    }
    return content
  }

  addMarker(latLng, infowindowContent) {
    const marker = new google.maps.Marker({
      position: latLng,
      icon: this.markerImage,
      visible: false,
    })

    this.markers.push(marker)

    const infoWindow = new google.maps.InfoWindow({
      content: infowindowContent,
      position: latLng,
    })

    google.maps.event.addListener(marker, 'click', () => {
      this.closeInfoWindow()
      this.openInfoWindow(infoWindow, marker)
    })

    return marker
  }

  openInfoWindow(infoWindow, marker) {
    infoWindow.open(this.map, marker)
    this.lastOpenedInfoWindow = infoWindow
  }

  recenterMap(lat, lng, state) {
    lat = lat || config.initialCenter.lat
    lng = lng || config.initialCenter.lng

    this.map.setCenter(new google.maps.LatLng(lat, lng))
    var zoom = config.initialZoomLevel

    switch (state) {
      case 'ACT':
        zoom = config.tinyZoomLevel
        break
      case 'QLD':
        zoom = config.largeZoomLevel
        break
      case 'VIC':
        zoom = config.smallZoomLevel
        break
      case 'SA':
        zoom = config.medZoomLevel
        break
      case 'WA':
        zoom = config.largeZoomLevel
        break
      case 'NSW':
        zoom = config.medZoomLevel
        break
      case 'NT':
        zoom = config.medZoomLevel
        break
      case 'TAS':
        zoom = config.smallZoomLevel
        break
    }

    this.map.setZoom(zoom)
  }

  async geocode(location) {
    if ($.isEmptyObject(location)) {
      this.map.setCenter(config.initialCenter)
      this.map.setZoom(config.initialZoomLevel)
      return
    }

    try {
      const result = await this.geocoder.geocode(location)

      this.map.setCenter(new google.maps.LatLng(result.lat, result.lng))
      this.map.setZoom(config.searchZoomLevel)
    } catch (error) {
      // console.log left intentionally to allow us to see missing
      // locations in production
      console.log('Geocode failure for: ' + location, error)
      throw error
    }
  }
}
