import { format, isToday, isSameYear } from 'date-fns'
import { underscore } from 'inflected'
import I18n from 'js/lib/i18n.js.erb'

$(document).ready(function () {
  $('.js-c-notifications-dropdown').hide()
  $('.notifications > i').click(function () {
    $('.js-c-notifications-dropdown').toggle()
  })

  $(document).on('click', function (e) {
    var target = $(e.target)

    if ($('.js-c-notifications-dropdown').is(':visible') && target.parents('.notifications').length === 0) {
      $('.js-c-notifications-dropdown').hide()
    }
  })

  $('.js-c-notifications-dropdown-close').click(function () {
    $('.js-c-notifications-dropdown').toggle()
  })

  $('.js-c-mark_as_read').click(function () {
    $(this).hide()

    $.ajax({
      url: '/notification_views/bulk_create',
      type: 'POST',
      data: {
        notification_type: 'ui',
      },
      success: function () {
        $('.notifications i').empty()
        $('.js-c-notification_list .active').removeClass('active')
      },
    })
  })

  // Note that this is triggered by ALL clicks in the list element because all events
  // will naturally bubble up.
  $('.js-c-notifications-dropdown').on('click', '.js-c-notification_item', function () {
    var $item = $(this)
    var id = $item.data('id')

    $.ajax({
      url: '/notification_views',
      type: 'POST',
      data: {
        notification_type: 'ui',
        id: id,
      },
      complete: function () {
        if ($('.js-c-notification_item.active').length === 0) {
          $('.notifications i').empty()
        }
        $item.removeClass('active')
      },
    })
  })

  var page = 2
  var allNotifications = false
  var loading = false

  $('.js-c-notification_list').on('scroll', function () {
    var scrolled = Math.ceil($(this).scrollTop() + $(this).innerHeight())

    if (scrolled >= this.scrollHeight && !allNotifications && !loading) {
      loading = true
      var user = $(this).data('user')

      $('.js-c-notification_list').append(
        $('<li>', {
          class: 'js-c-notification_item c-notification_item loader',
        }).append(
          $('<p>', {
            class: 'centered notification_body',
            text: 'Loading more...',
          })
        )
      )

      setTimeout(function () {
        $.ajax({
          url: '/notification_user/' + user + '?page=' + page,
          type: 'GET',
          success: function (data) {
            page++

            $.each(data.data, function (_, data) {
              var createdAt = new Date(data.created_at)
              var today = isToday(createdAt)
              var isYear = isSameYear(createdAt, new Date())
              var dateFormat = isYear ? 'd MMM' : 'd MMM yyyy'
              var date = today ? 'Today' : format(createdAt, dateFormat)
              var active = data.notification_view ? false : 'active'
              var notificationType = underscore(data.notification.notification_type)
              var actionText = I18n.t('notifications.' + notificationType + '.cta')
              var actionUrl = data.notification.action_url
              var actionData = {
                method: data.notification.action_method,
                remote: data.notification.action_remote,
              }
              var $li = $('<li>', {
                class: 'js-c-notification_item c-notification_item ' + active,
                data: { id: data.id },
              })

              $li.append(
                $('<p>', {
                  class: 'notification_body',
                  html: data.notification.body,
                })
              )
              $li.append(
                $('<a>', {
                  class: 'button action',
                  data: actionData,
                  href: actionUrl,
                  text: actionText,
                })
              )
              $li.append(
                $('<span>', {
                  text: date,
                  class: 'c-notifications-dropdown-date',
                })
              )

              $('.js-c-notification_list').append($li)
            })

            if (data.data.length === 0) {
              allNotifications = true

              $('.js-c-notification_list').append(
                $('<li>', {
                  class: 'js-c-notification_item c-notification_item',
                }).append(
                  $('<p>', {
                    class: 'centered notification_body',
                    text: 'No more notifications',
                  })
                )
              )
            }

            $('.loader').remove()
            loading = false
          },
        })
      }, 750)
    }
  })
})
