/*
  Import this file on christmas to initiate christmas spirit
*/

import Snowflakes from 'magic-snowflakes'
import queryString from 'query-string'
import { wait } from 'js/lib/general-utils'
import { on } from 'js/lib/events'

const CHRISSY_NAMES = [
  'dasher',
  'dancer',
  'prancer',
  'vixen',
  'comet',
  'cupid',
  'donner',
  'blitzen',
  'rudolph',
  'santa',
]
const QUERY_STRING = queryString.parse(window.location.search)

function christmasSpritDetected() {
  const qsName = QUERY_STRING.name?.toLowerCase()
  if (!qsName) return false
  return CHRISSY_NAMES.some(name => name.includes(qsName))
}

async function runSnowflakes() {
  const snowflakes = Snowflakes({
    // color: '#f00', // Default: "#5ECDEF"
    count: 80, // 100 snowflakes. Default: 50
    speed: 2, // The property affects the speed of falling. Default: 1
    wind: true, // Without wind. Default: true
  })

  snowflakes.start()

  await wait(9 * 1000)
  snowflakes._container.classList.add('fade-out')
  await wait(400)
  snowflakes.destroy()
}

async function insertSanta() {
  const santa = document.createElement('div')
  santa.classList.add('christmas-spirit')
  document.body.prepend(santa)
  await wait(4 * 1000)
  santa.remove()
}

export async function runChristmasProtocol() {
  await wait(1000)
  runSnowflakes()
  await wait(3 * 1000)
  insertSanta()
}

if (christmasSpritDetected()) {
  runChristmasProtocol()
}

let clicks = 0
on('click', '#logo a', event => {
  event.preventDefault()
  if (clicks === 0) {
    setTimeout(() => {
      if (clicks < 3) {
        window.location = event.target.href
      } else {
        clicks = 0
        runChristmasProtocol()
      }
    }, 500)
  }

  clicks++
})
