import { requestNative } from 'js/lib/api/api-request'
import { notifyBugsnag } from 'js/lib/bugsnag'

const MEDIA_CLASS = '.js-c-media--manager'
const ITEM_CLASS = '.js-c-media--manager__item'
const DELETE_BUTTON_CLASS = '.js-c-media--manager__delete'
const DRAGGED_ITEM_OPACITY = '0.5'
const DEFAULT_OPACITY = '1'
const HIGHLIGHT_CLASSES = 'tw-outline-dashed tw-outline-blue-500 tw-outline-2 outline-offset-2'
const CHECKBOX_CLASS = '.js-c-form-input-checkbox__button'
const TOOLTIP_CLASS = '.js-c-ui-tooltip--text'
const MAX_SELECTED_COUNT = 9

// insert an element after a reference element
const insertAfter = (newNode, referenceNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

// get the closest item element
const getItem = event => event.target.closest(ITEM_CLASS)

// toggle border style
const toggleBorder = (element, add) => {
  if (element) {
    if (add) {
      element.classList.add(...HIGHLIGHT_CLASSES.split(' '))
    } else {
      element.classList.remove(...HIGHLIGHT_CLASSES.split(' '))
    }
  }
}

// handle item dragging
const onDragStart = event => {
  const item = getItem(event)
  if (item) {
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/plain', null) // For Firefox
    item.style.opacity = DRAGGED_ITEM_OPACITY
    item.dataset.dragged = 'true'
  }
}

const onDragEnd = event => {
  const item = event.target.closest(ITEM_CLASS)
  if (item) {
    item.style.opacity = DEFAULT_OPACITY
    delete item.dataset.dragged
    toggleBorder(item, false)
  }
}

// handle visual feedback during drag
const onDragOver = event => {
  event.preventDefault()
  if (getItem(event)) {
    toggleBorder(getItem(event), true)
  }
}

const onDragLeave = event => {
  const target = event.target.closest(ITEM_CLASS)
  if (target) {
    toggleBorder(target, false)
  }
}

// handle drop event and update media order
const onDrop = (event, media) => {
  event.preventDefault()
  const target = event.target.closest(ITEM_CLASS)
  const draggedItem = document.querySelector(`[data-dragged="true"]`)

  if (target && draggedItem && draggedItem !== target) {
    const shouldInsertAfter =
      Array.prototype.indexOf.call(target.parentNode.children, draggedItem) <
      Array.prototype.indexOf.call(target.parentNode.children, target)
    if (shouldInsertAfter) {
      insertAfter(draggedItem, target)
    } else {
      target.parentNode.insertBefore(draggedItem, target)
    }
    updateOrder(media, draggedItem)
  }
  toggleBorder(target, false)
  if (draggedItem) {
    toggleBorder(draggedItem, false)
  }
}

// Update the media order by setting the `data-order` attribute
const updateOrder = (media, draggedItem) => {
  const items = media.querySelectorAll(ITEM_CLASS)
  items.forEach((item, index) => {
    item.dataset.order = index + 1
    item.setAttribute('aria-label', `Item ${index + 1}`)
  })

  // no need to sync the selected items if it's just the order changing
  updateSelectedSyncItems(media, { sync: false })
  sendOrderUpdate(media)
}

// Send the updated order
async function sendOrderUpdate(media) {
  const items = media.querySelectorAll(ITEM_CLASS)
  const postToUrl = media.dataset.updateMedia
  const formAction = 'reorder'

  const mediaOrder = Array.from(items).map(item => ({
    id: item.getAttribute('data-id'),
    order: item.getAttribute('data-order'),
  }))

  try {
    await requestNative({
      url: postToUrl,
      method: 'POST',
      data: { form_action: formAction, media_order: mediaOrder },
    })
  } catch (error) {
    notifyBugsnag(error)
  }
}

async function sendSelectedSyncUpdate(media, items) {
  const postToUrl = media.dataset.updateMedia
  const formAction = 'select_for_sync'

  const selected = Array.from(items).map(item => ({
    id: item.getAttribute('data-id'),
  }))

  try {
    await requestNative({
      url: postToUrl,
      method: 'POST',
      data: { form_action: formAction, media_selected: selected },
    })
  } catch (error) {
    notifyBugsnag(error)
  }
}

// handle keyboard navigation
const onKeyDown = event => {
  const currentItem = getItem(event)
  if (currentItem) {
    const media = event.target.closest(MEDIA_CLASS)
    const allItems = Array.from(media.querySelectorAll(ITEM_CLASS))

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft':
        moveItem(currentItem, allItems, -1)
        break
      case 'ArrowDown':
      case 'ArrowRight':
        moveItem(currentItem, allItems, 1)
        break
    }
  }
}

// move items with keyboard
const moveItem = (item, allItems, direction) => {
  const currentIndex = allItems.indexOf(item)
  const targetIndex = currentIndex + direction

  if (targetIndex >= 0 && targetIndex < allItems.length) {
    const targetItem = allItems[targetIndex]
    if (direction < 0) {
      item.parentNode.insertBefore(item, targetItem)
    } else {
      insertAfter(item, targetItem)
    }
    item.focus()
    updateOrder(item.closest(MEDIA_CLASS), item)
  }
}

const handleDelete = async (media, event) => {
  event.preventDefault()

  const button = event.target.closest(DELETE_BUTTON_CLASS)

  if (button) {
    const item = button.closest(ITEM_CLASS)

    if (item) {
      const media = item.closest(MEDIA_CLASS)
      const itemId = item.getAttribute('data-id')
      const postToUrl = media.dataset.updateMedia

      const isConfirmed = window.confirm('Are you sure you want to delete this item?')

      if (isConfirmed) {
        try {
          const response = await requestNative({
            url: postToUrl,
            method: 'POST',
            data: { form_action: 'remove', media_id: itemId },
          })

          if (response.message) {
            item.remove()
            updateSelectedSyncItems(media, { sync: false })
          }
        } catch (error) {
          notifyBugsnag(error)
        }
      }
    }
  }
}

const updateSelectedSyncItems = (media, { sync } = { sync: true }) => {
  const mediaItems = media.querySelectorAll(ITEM_CLASS)
  const selectedItems = Array.from(mediaItems).filter(
    mediaItem => mediaItem.querySelector(CHECKBOX_CLASS).getAttribute('aria-checked') === 'true'
  )

  if (sync) sendSelectedSyncUpdate(media, selectedItems)

  mediaItems.forEach(mediaItem => {
    const checkbox = mediaItem.querySelector(CHECKBOX_CLASS)
    const tooltip = mediaItem.querySelector(TOOLTIP_CLASS)
    const isChecked = checkbox.getAttribute('aria-checked') === 'true'
    const label = mediaItem.querySelector('.js-c-form-input-checkbox__label')

    if (isChecked) {
      const itemSyncIndex = selectedItems.indexOf(mediaItem)
      label.textContent = itemSyncIndex === 0 ? 'Feature' : itemSyncIndex + 1
      mediaItem.classList.add('selected')
    } else {
      label.textContent = 'Show on listing'
      mediaItem.classList.remove('selected')

      checkbox.disabled = false
      tooltip.textContent = 'Select this for a listing photo'

      if (selectedItems.length >= MAX_SELECTED_COUNT) {
        checkbox.disabled = true
        tooltip.textContent = "You've reached the max selected"
      }
    }
  })
}

const initMedia = media => {
  media.addEventListener('dragstart', onDragStart)
  media.addEventListener('dragend', onDragEnd)
  media.addEventListener('dragover', onDragOver)
  media.addEventListener('dragleave', onDragLeave)
  media.addEventListener('drop', event => onDrop(event, media))
  media.addEventListener('keydown', onKeyDown)

  media.querySelectorAll(DELETE_BUTTON_CLASS).forEach(button => {
    button.addEventListener('click', event => handleDelete(media, event))
  })

  media.querySelectorAll(CHECKBOX_CLASS).forEach(checkbox => {
    checkbox.addEventListener('click', () => updateSelectedSyncItems(media))
  })

  updateSelectedSyncItems(media, { sync: false })
}

document.querySelectorAll(MEDIA_CLASS).forEach(initMedia)
