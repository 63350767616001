$(document).ready(function () {
  if (
    $('body').is(
      '.admin-groups-groups-new, .admin-groups-groups-edit, .admin-groups-groups-update, .admin-groups-groups-create'
    )
  ) {
    var orgType = $('#group_org_type')
    var refereeContact = $('#group_referee_contact_details_input')
    var refereeEmail = $('#group_referee_email_input')
    var $groupSettingsWrapper = $('.column-form__fieldset.group-settings')

    if ($groupSettingsWrapper.length > 0) {
      $groupSettingsWrapper.find('.switch input[type="checkbox"]').each((_, el) => {
        const targetSelector = $(el).data('target')
        const $target = $(targetSelector)

        $(el).on('change', () => {
          if ($(el).prop('checked')) {
            $target.stop(true).slideDown()
          } else {
            $target.stop(true).slideUp()
          }
        })
      })
    }

    orgType.change(hideShowVetRefInputs)

    $('.checked').parent().addClass('checked')

    $('.group_rating label').click(function () {
      var id = $(this).attr('for')
      var el = $('#' + id)
      $('.group_rating .checker').removeClass('checked')
      $('.group_rating .checked').removeClass('checked')
      $('.group_rating input:checked').prop('checked', false)
      el.attr('checked', true)
      el.parent().parent().addClass('checked')
      $('.group_rating h3').text(el.val())
    })

    function hideShowVetRefInputs() {
      if (orgType.val() === 'vet') {
        refereeContact.add(refereeEmail).hide().find('input').prop('disabled', true)
      } else {
        refereeContact.add(refereeEmail).show().find('input').prop('disabled', false)
      }
    }
  }
})
