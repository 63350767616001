export const focusLock = (event, el) => {
  const focusables = getKeyboardFocusableElements(el)
  const firstFocusable = focusables[0]
  const lastFocusable = focusables[focusables.length - 1]

  if (document.activeElement === lastFocusable && event.key === 'Tab' && !event.shiftKey) {
    event.preventDefault()
    firstFocusable.focus()
  }

  if (document.activeElement === firstFocusable && event.key === 'Tab' && event.shiftKey) {
    event.preventDefault()
    lastFocusable.focus()
  }
}

const getKeyboardFocusableElements = (element) => {
  return [...element.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')]
    .filter(el => !el.hasAttribute('disabled'))
}
