import $ from 'cash-dom'
import scrollIntoView from 'scroll-into-view-if-needed'

let userScrolled = false
const message = $('.js-c-conversation-message')
const lastMessage = message.last().get(0)

if (lastMessage) {
  window.addEventListener('load', () => initialize())
  $(window).one('scroll', () => (userScrolled = true))
}

function initialize() {  
  if (userScrolled) return
  scrollIntoView(lastMessage, { behavior: 'smooth'})    
}
