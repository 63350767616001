
const disclosures = document.querySelectorAll('.js-c-form-disclosure--container')

function initDisclosure(disclosureContainer) {

  if (!disclosures.length) { return }

  const trigger = disclosureContainer.classList.contains('js-c-form-disclosure--trigger') ? disclosureContainer : disclosureContainer.querySelector('.js-c-form-disclosure--trigger')

  const disclosure = disclosureContainer.querySelector('.js-c-form-disclosure')

  disclosure.open = trigger.getAttribute('aria-expanded') === 'true'

  trigger.addEventListener('click', () => {
    toggleDisclosure(disclosure, trigger)
  })

  // Closes disclosure after dom load, so if nojs text will be visible.
  disclosure.setAttribute('hidden', '')
}

function toggleDisclosure(disclosure, trigger) {

  disclosure.open = !disclosure.open

  trigger.setAttribute('aria-expanded', `${disclosure.open}`)

  if (disclosure.open) {
    disclosure.removeAttribute('hidden')
  } else {
    disclosure.setAttribute('hidden', '');
  }
}

disclosures.forEach((disclosure) => {
  initDisclosure(disclosure)
})
