import { openModal, closeModal } from 'components/modal/component'

initializeInterstateConfirmModal()

function initializeInterstateConfirmModal() {
  const $enquiryForm = $('.listing--enquiry-form')

  if ($enquiryForm.length === 0) return

  const MODAL_NAME = 'interstate_confirm'
  const stateOptions = ['NSW', 'QLD', 'NT', 'SA', 'WA', 'TAS', 'VIC', 'ACT']
  const listingStates = $('.listing_state').val()


  $enquiryForm.on('submit', (e, data) => {
    const enquiryLocation = $('#listings_enquiry_location_id').attr('data-text')
    let enquiryState = null
    if ($.isEmptyObject(data) || data.triggered !== true) {
      stateOptions.forEach(function (value) {
        if (enquiryLocation.includes(value)) {
          enquiryState = value
        }
      })

      if (
        listingStates.length !== 0 &&
        enquiryState &&
        enquiryState.length !== 0 &&
        !listingStates.includes(enquiryState)
      ) {
        openModal(MODAL_NAME)
        e.preventDefault()
      }
    }
  })

  $('.js-c-interstate_confirm-cancel').on('click', () => closeModal(MODAL_NAME))
  $('.js-c-interstate_confirm-ok').on('click', () => {
    closeModal(MODAL_NAME)
    $enquiryForm.trigger('submit', { triggered: true })
  })
}
