;(function ($) {
  $.petrescue.init('.alerts-form', function () {
    $('.alerts-form').css('visibility', 'visible')

    $('#alert_species_id')
      .on('change', function () {
        var species = $(this).find('option:selected').text()

        $('#alert_default_age_input').prop('disabled', true)
        $('#alert_dog_age_input select').prop('disabled', true)
        $('#alert_dog_age_input').hide()
        $('#alert_cat_age_input select').prop('disabled', true)
        $('#alert_cat_age_input').hide()
        $('#alert_size_input').hide()
        $('#alert_coat_input').hide()

        if (species === 'Dog') {
          $('#alert_dog_age_input select').prop('disabled', false)
          $('#alert_dog_age_input').show()
          $('#alert_size_input').show()
          // Deselect other options that would invalidate the model.
          $('#alert_cat_age_input option:selected').prop('selected', false)
          $('#alert_coat_input option:selected').prop('selected', false)
        } else if (species === 'Cat') {
          $('#alert_cat_age_input select').prop('disabled', false)
          $('#alert_cat_age_input').show()
          $('#alert_coat_input').show()
          // Deselect other options that would invalidate the model.
          $('#alert_dog_age_input option:selected').prop('selected', false)
          $('#alert_size_input option:selected').prop('selected', false)
        } else {
          // Other species can't use these parameters
          $('#alert_default_age_input').prop('disabled', false)
          $('#alert_dog_age_input option:selected').prop('selected', false)
          $('#alert_cat_age_input option:selected').prop('selected', false)
          $('#alert_coat_input option:selected').prop('selected', false)
          $('#alert_size_input option:selected').prop('selected', false)
        }
      })
      .trigger('change')

    $('#alert_state_id')
      .on('change', function () {
        var $interstateContainer = $('.interstate-container')

        if ($(this).val().length === 0) {
          $interstateContainer.find('input').prop('checked', false)
          $interstateContainer.hide()
        } else {
          $interstateContainer.show()
        }
      })
      .trigger('change')
  })
})(jQuery)
