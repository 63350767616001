/*
  Cloudinary has the feature to load images "responsively" - which means it uses javascript to take images with a data-src
  attribue, then transform the url so that the we only load images which are optmized to their container and device pixel ratio.

  There is a number of downsides to this:
    1.  Images load AFTER the page has loaded - time to first meaningful pain is made slower
    2.  We have to load an old bloaty version of the frontend cloudinary SDK which has a requirement on jquery.
        (see it loaded in the gem vender scripts here app/assets/javascripts/gem_vendor_scripts.js)

  If we were to refactor a bit, we could remove the reliance on the cloudinary frontend sdk and load the images in the same way via
  their newer library https://github.com/cloudinary/js-url-gen. This would save us a bunch of js and probably make things run faster.

  For future reference you can see how the $.cloudinary.responsive() method call works here:
  https://github.com/cloudinary/cloudinary_js/blob/97033130be7305b11f27598c1f17ec6ec5e78a66/src/cloudinaryjquery.js

  And the docs the descibe how it's done here:
  https://cloudinary.com/documentation/responsive_images#automating_responsiveness_with_the_cloudinary_core_js_library

  Note that the ".cld-responsive" class of these responsive images (along with the data-src attribute) are generated by the rails cloudinary
  plugin - not any frontend code. It's produced by the cl_image_tag call which you can see scattered around our haml templates.

  It would be better to not use any js and instead use the picture tag to generate the correct responsive image urls. See here for more info:
  https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
*/

initResponsive()

async function initResponsive() {
  if (document.querySelector('.cld-responsive') === null) return
  $.cloudinary.responsive()
}
