import { MODAL_SELECTOR } from 'components/modal/component'

$(document).ready(function () {
  function getDropdownParent($el) {
    const $modalWrapper = $el.parents(MODAL_SELECTOR).first()
    return $modalWrapper.length ? $modalWrapper : undefined
  }

  $('select:not(.custom-select2,.component)').each((_, el) => {
    const $select = $(el)
    $select.select2({
      minimumResultsForSearch: 10,
      width: '100%',
      dropdownParent: getDropdownParent($select),
    })
  })
})
