/*
  Returns a function, that, as long as it continues to be invoked, will not
  be triggered. The function will be called after it stops being called for
  N milliseconds. If `immediate` is passed, trigger the function on the
  leading edge, instead of the trailing.
  Source: http://davidwalsh.name/javascript-debounce-function
*/
export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

/*
  Async version of setTimeout
*/
export async function wait(timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

/*
  Collect the result of all promises, waiting for all to resolve and/or reject. Will reject if there are any errors,
  but only after all have completed
*/
export function collectPromises(...promises) {
  return new Promise(async (resolve, reject) => {
    const results = await promisesAllSettled(promises)
    const collectedErrors = results.filter(result => result.status === 'rejected')
    const mapPromiseResult = result => (result.status === 'fulfilled' ? result.value : result.reason)

    if (collectedErrors.length) {
      reject(collectedErrors.map(mapPromiseResult))
    } else {
      resolve(results.map(mapPromiseResult))
    }
  })
}

export function promisesAllSettled(promises) {
  if (typeof Promise.allSettled === 'function') {
    return Promise.allSettled(promises)
  }

  return Promise.all(
    promises.map(p => {
      return p.then(value => ({ status: 'fulfilled', value })).catch(reason => ({ status: 'rejected', reason }))
    })
  )
}
