import { createAnalyticsGroup } from 'js/lib/analytics'

const banner = document.querySelector('.js-c-promo___hero__christmas')

if (banner) {
  const donateCta = banner.querySelector('.js-c-promo___hero__christmas--donate')
  const learnMoreCta = banner.querySelector('.js-c-promo___hero__christmas--learn-more')
  const petWindows = banner.querySelectorAll('.js-c-promo___hero__christmas--pet')
  const track = createAnalyticsGroup('xmas2024homepagehero')
  
  if (donateCta) {
    donateCta.addEventListener('click', () => { 
      track('click', 'donate button')
    })
  }

  if(learnMoreCta) {
    learnMoreCta.addEventListener('click', () => { 
      track('click', 'learn more button')
    })
  }

  petWindows.forEach(petWindow => {
    const petTrack = petWindow.getAttribute('data-track-pet') || 'missing_pet_name'
    const anchor = petWindow.querySelector('a')

    if (anchor) {
      anchor.addEventListener('click', () => {
        track('click', petTrack)
      })
    }
  })
}
