$(document).ready(function () {
  if ($('.js-c-counter').length === 0) return;

  var elemTop = $('.js-c-counter-wrapper').offset().top;
  var counted = false;
  isImpactVisible();

  function isImpactVisible() {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    if (elemTop <= docViewBottom && counted == false) {
      count();
    }
  }

  function count() {
    counted = true;
    $('.js-c-counter').each(function () {
      var $this = $(this);
      var countTo = $this.data('count');

      $({ countNum: $this.data('start') }).animate(
        {
          countNum: countTo,
        },
        {
          duration: 1500,
          easing: 'easeOutCirc',
          step: function () {
            var digits = Math.floor(this.countNum).toLocaleString().split('');
            var htmlStr = '';

            digits.forEach(function (value) {
              if (value == ',') {
                htmlStr += `<span class="c-counter-comma">${value}</span>`
              } else {
                htmlStr += `<span class="c-counter-digit">${value}</span>`
              }
            });
            $this.html(htmlStr);
          },
          complete: function () {
            var digits = this.countNum.toLocaleString().split('');
            var htmlStr = '';

            digits.forEach(function (value) {
              if (value == ',') {
                htmlStr += `<span class="c-counter-comma">${value}</span>`
              } else {
                htmlStr += `<span class="c-counter-digit">${value}</span>`
              }
            });
            $this.html(htmlStr);
          },
        }
      )
    })
  }

  $(window).scroll(function () {
    isImpactVisible();
  });
});
