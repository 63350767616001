const triggers = document.querySelectorAll('.js-c-collapsible-trigger')

triggers.forEach(trigger => init(trigger))

function init(trigger) {  
  const key = trigger.dataset.key
  const chevron = trigger.querySelector('.js-c-collapsible--icon')
  const content = document.querySelector(`.js-c-collapsible-content[data-key="${key}"]`)
  
  // if expanded option == true, close the trigger
  if (trigger.getAttribute('aria-expanded') === 'false') {
    content.setAttribute('hidden', '')
    chevron.classList.add('tw-rotate-270')
  }

  content.open = trigger.getAttribute('aria-expanded') === 'true'
  trigger.addEventListener('click', () => toggle(content, trigger, chevron))
}

function toggle(content, trigger, chevron) {
  console.log('hello')
  content.open = !content.open
  trigger.setAttribute('aria-expanded', `${content.open}`)

  if (content.open) {
    content.removeAttribute('hidden')
    chevron.classList.remove('tw-rotate-270')
  } else {
    content.setAttribute('hidden', '')
    chevron.classList.add('tw-rotate-270')
  }
}
