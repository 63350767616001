$(document).ready(function () {
  if ($('.js-c-gauge').length > 0) {
    var elemTop = $('.js-c-gauge').offset().top;
    var animated = false;
    isImpactVisible();

    function isImpactVisible() {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
      if (elemTop <= docViewBottom && animated == false) {
        animate();
      }
    }

    function animate() {
      animated = true;
      var gaugePercentage = $('.js-c-gauge-percentage');
      var rotation = gaugePercentage.data('rotation');

      if (rotation >= "180" ) {
        var rotation = "180";
        goalAchieved();
      }

      document.documentElement.style.setProperty('--love-metere-rotation',  `${rotation}deg`);
    }

    $(window).scroll(function () { 
      isImpactVisible();
    });

    function goalAchieved() {
      $('.confetti-canvas').show()
      window.RestartConfetti()
      setTimeout(window.DeactivateConfetti, 1200)
    }
  }
});
