import I18n from 'js/lib/i18n.js.erb'
import { createFlash } from 'components/flash/component'
import { closeAllModals, openModal } from 'components/modal/component'

;(function ($) {
  $.adoptionNotificationModal = (function () {
    const modelName = 'adopted_notification'
    var i18nPrefix = 'modals.adopted_notification'
    var $modal, $selectContainer, $stageThreePlaceholder, $messagePreviewContainer
    var enquirerCount = 0

    return {
      initialized: false,
      init: function (listings, options) {
        options = $.extend({}, options)

        if (!this.initialized) {
          findElements()
          bindEvents(this, listings)
          this.initialized = true
        } else {
          this.reset()
        }

        addMessagePreview(this, listings, function () {
          hasEnquiries(this, listings, function (response) {
            if (response.data.enquiries > 0) {
              openModal(modelName)
            } else {
              if ($.isFunction(options.noEnquiriesCallback)) {
                options.noEnquiriesCallback()
              }
            }
          })
        })

        return this
      },
      reset: function () {
        enquirerCount = 0
        this.setStage('one')
        $selectContainer.empty()
        $stageThreePlaceholder.empty()
        $messagePreviewContainer.hide()

        return this
      },
      setStage: function (stage) {
        $modal.find('.stage').hide()
        $modal.find('.stage_' + stage).show()

        return this
      },
      getEnquirerCount: function () {
        return enquirerCount
      },
      incrementEnquirerCount: function () {
        enquirerCount++

        return this
      },
      decrementEnquirerCount: function () {
        enquirerCount--

        return this
      },
    }

    function findElements() {
      $modal = $('.adopted_notification')
      $messagePreviewContainer = $modal.find('.preview-message-container')
      $selectContainer = $modal.find('.selectbox_container')
      $stageThreePlaceholder = $modal.find('.stage_three .placeholder')
    }

    function bindEvents(utils, listings) {
      $modal.on('click', '.preview-link', function (e) {
        $modal.find('.preview-message-container').stop(true).slideToggle()

        e.preventDefault()
      })

      $messagePreviewContainer.find('.preview-message-container--close').on('click', function () {
        $modal.find('.preview-message-container').stop(true).slideUp()
      })

      $modal.on('click', '.stage_one .button.yes', function (e) {
        var button = $(this)
        button.html('Loading ')
        button.append('<i class="fa fa-spinner fa-pulse"></i>')

        buildAdopterElements(utils, listings, function () {
          $('.adopter_selectbox select').select2({
            width: '100%',
            dropdownParent: $('.adopted_notification'),
          })
          utils.setStage('two')
          button.html('Yes, notify unsuccessful enquirers')
        })
        e.preventDefault()
      })

      $modal.on('click', '.stage_two .send_notifications', function (e) {
        var isValid = true
        var $selects = $modal.find('.adopter_selectbox select:not(:disabled)')

        $selects.each(function () {
          if ($(this).val().length === 0) {
            $(this).parent().addClass('error')
            isValid = false
          } else {
            $(this).parent().removeClass('error')
          }
        })

        if (isValid) {
          // Create adoption and progress stage
          var adoptions = []

          $selects.find('option:selected').each(function () {
            var data = $(this).data()

            adoptions.push({
              listing_id: data.listingId,
              adopter_id: data.adopterId,
              adopter_type: data.adopterType,
              notify_failed_applicants: data.notifyFailedApplicants,
              adopter_conversation_id: data.conversationId,
            })

            if (undefined !== data.adopterId) {
              utils.decrementEnquirerCount()
            }
          })

          createAdoptionBatch(utils, adoptions, function () {
            if (utils.getEnquirerCount() > 1) {
              $stageThreePlaceholder.append(
                $('<p>', {
                  text: t('stage_three.para_1.high_enquirers_updated', { enquirer_count: utils.getEnquirerCount() }),
                })
              )
            } else {
              $stageThreePlaceholder.append(
                $('<p>', {
                  text: t('stage_three.para_1.low_enquirers_updated'),
                })
              )
            }

            utils.setStage('three')
          })

          checkChatsToArchive(listings)
        }

        e.preventDefault()
      })

      $modal.on('click', '.adopted_notification--stage_three-archive', function (e) {
        e.preventDefault()
        $.ajax({
          url: '/conversations/archive',
          type: 'POST',
          data: JSON.stringify({
            listing_ids: Object.keys(listings),
          }),
          dataType: 'json',
          contentType: 'application/json',
          success: () => closeAllModals(),
        })
      })

      $modal.on('click', '.stage .close-button', function (e) {
        closeAllModals()
        e.preventDefault()
      })
    }

    function addMessagePreview(utils, listings, callback) {
      var listingNames = Object.values(listings)
      var previewUrl = $messagePreviewContainer.data('url')

      $.ajax({
        url: previewUrl,
        type: 'POST',
        data: {
          listing_name: listingNames[0],
        },
        success: function (response) {
          $messagePreviewContainer.find('.preview-message').html(response)

          callback.call(utils)
        },
      })
    }

    function buildAdopterElements(utils, listings, callback) {
      var listingIds = Object.keys(listings)

      $.ajax({
        url: '/admin/listings/enquiries',
        type: 'GET',
        data: {
          listing_ids: listingIds,
        },
        success: function (response) {
          $.each(listings, function (listingId, petName) {
            var enquiries = $.makeArray(response.data.enquiries[listingId])
            var $container = $('<div>', {
              class: 'adopter_selectbox',
            }).append(
              $('<label>', {
                text: t('stage_two.adopter_select.label', { listing_name: petName }),
              })
            )

            if (enquiries.length > 0) {
              var $select = $('<select>').append(
                $('<option>', {
                  text: t('stage_two.adopter_select.blank'),
                  value: '',
                })
              )

              $.each(enquiries, function (_, enquiry) {
                var conversation = enquiry.conversation || {}

                $select.append(
                  $('<option>', {
                    data: {
                      listingId: enquiry.listing_id,
                      adopterId: enquiry.created_by_id,
                      adopterType: enquiry.created_by_type,
                      conversationId: conversation.uid,
                      notifyFailedApplicants: true,
                    },
                    text: enquiry.name + ' (' + enquiry.email + ')',
                    value: enquiry.id,
                  })
                )

                utils.incrementEnquirerCount()
              })

              $select.append(
                $('<option>', {
                  data: {
                    listingId: listingId,
                    notifyFailedApplicants: true,
                  },
                  text: t('stage_two.adopter_select.none'),
                  value: 'other',
                })
              )

              $container.append($select)
            } else {
              $container.append(
                $('<select>', {
                  disabled: true,
                }).append(
                  $('<option>', {
                    text: t('stage_two.adopter_select.no_enquiries'),
                  })
                )
              )
            }

            $selectContainer.append($container)
          })

          callback.call(utils, response)
        },
        error: function (xhr, _status, _error) {
          var errorMessage = xhr.status + ': ' + xhr.statusText
          window.alert('Error - ' + errorMessage)
          createFlash('alert', I18n.t('listings.admin_box.bulk_notifications.flash.error'))
        },
      })
    }

    function hasEnquiries(utils, listings, callback) {
      var listingIds = Object.keys(listings)

      if (listingIds.length > 1) {
        $modal.removeClass('singular').addClass('plural')
      } else {
        $modal.removeClass('plural').addClass('singular')
      }

      $.ajax({
        url: '/admin/listings/enquiries_count',
        type: 'GET',
        data: {
          listing_ids: listingIds,
        },
        success: function (response) {
          callback.call(utils, response)
        },
      })
    }

    function createAdoptionBatch(utils, adoptions, callback) {
      // JSON.stringify and dataType json are necessary here
      // to send adoptions as an array rather than a hash
      $.ajax({
        url: '/listing_adoptions_batch',
        type: 'POST',
        data: JSON.stringify({
          adoptions: adoptions,
        }),
        dataType: 'json',
        contentType: 'application/json',
        success: function (response) {
          callback.call(utils, response)
        },
      })
    }

    function checkChatsToArchive(adoptions) {
      $.ajax({
        url: '/conversations/archive/check_chats_to_archive',
        data: JSON.stringify({
          listing_ids: Object.keys(adoptions),
        }),
        dataType: 'json',
        method: 'POST',
        contentType: 'application/json',
        success: function (response) {
          if (response.data.length > 0) {
            $('.adopted_notification--stage_three-archive').removeClass('hidden')
          }
        },
      })
    }

    function t(key, locals) {
      return I18n.t(i18nPrefix + '.' + key, locals)
    }
  })()
})(jQuery)
