import $ from 'cash-dom'
import { createLightbox } from 'js/lib/lightbox'

const LIGHTBOX_SELECTOR = '.js-c-media-gallery-media--lightboxed'

initialize()

function initialize() {
  renderLightBox()
}

function renderLightBox() {
  if (!$(LIGHTBOX_SELECTOR).length) return
  createLightbox({ selector: LIGHTBOX_SELECTOR })
}

