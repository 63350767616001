import loadGoogleMaps from './google-map-loader'

export class Geocoder {
  cache = {}
  geocoder = undefined

  async init() {
    if (this.geocoder) return

    await loadGoogleMaps()

    this.geocoder = new google.maps.Geocoder()
  }

  async geocode(term) {
    const requestTerm = term.trim()

    await this.init()

    if (this.cache[requestTerm]) return this.cache[requestTerm]

    const response = await searchLocation(requestTerm)
    const locations = response.data

    if (locations.length > 0) {
      this.cache[requestTerm] = locations[0]
      return locations[0]
    }

    const geocodes = await runGeocoding(this.geocoder, {
      address: [window.location, PetRescue.env.countryCode].join(', '),
    })

    if (geocodes.length > 0) {
      const result = normalizeGeocodeResult(geocodes[0])

      this.cache[requestTerm] = result
      return result
    }

    throw new Error(`Geocode failure for: {{requestTerm}}`)
  }
}

function normalizeGeocodeResult(result) {
  const postcode = result.address_components.find(component => {
    return component.types[0] === 'postal_code'
  })
  const suburb = result.address_components.find(component => {
    return component.types[0] === 'locality'
  })
  const state = result.address_components.find(component => {
    return component.types[0] === 'administrative_area_level_1'
  })

  return {
    id: null,
    postcode: postcode && postcode.short_name,
    state: state && state.short_name,
    suburb: suburb && suburb.short_name,
    lat: result.location.lat(),
    lng: result.location.lng(),
  }
}

function searchLocation(term) {
  return $.ajax({
    url: '/locations/geocode/search',
    method: 'GET',
    dataType: 'json',
    data: {
      request_term: term,
    },
  })
}

function runGeocoding(geocoder, args) {
  return new Promise((resolve, reject) => {
    geocoder.geocode(args, (result, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        return reject(status)
      }

      resolve(result)
    })
  })
}
