$(document).ready(function () {
  if ($('.js-sticky_ad').length) {
    $('.sticky_ad_desktop-close').on('click', function() {
      $('.js-sticky_ad').css('display', 'none')
    })

    // Sticky to show on scroll
    $(document).scroll(function () {
      if ($(document).scrollTop() >= 50) {
        $('.js-sticky_ad').css('visibility', 'visible')
      }
      if ($(document).scrollTop() < 50) {
        $('.js-sticky_ad').css('visibility', 'hidden')
      }
    })
  }
})
