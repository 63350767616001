const selects = document.querySelectorAll('.js-c-select')

selects.forEach(select => {
  select.addEventListener('change', () => {
    select.classList.remove('c-select--with-placeholder')
  })

  // on load remove the placeholder class
  if (select.value !== '') {
    select.classList.remove('c-select--with-placeholder')
  }

})
