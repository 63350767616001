$(document).ready(function () {
  if (!$('.pages-show-release-notes').length) return

  $(document).on('click', '.release-notes-calender-month-heading', function () {
    $(this).find('.release-notes-calender-month-expand').toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
    var $parent = $(this).closest('.release-notes-calender-month')

    $parent
      .find('.release-notes-calender-month-updates')
      .stop(true, true)
      .slideToggle(300, function () {
        $parent.toggleClass('expanded')
      })
  })
})
