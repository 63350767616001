import { requestNative } from 'js/lib/api/api-request'
import { openCloudinaryWidget, isCloudinaryWidgetOpen, preloadCloudinaryWidget } from 'js/lib/cloudinary'
import { setButtonLoadingState } from 'js/lib/button'

preloadCloudinaryWidget()

const initUploader = (uploader) => {
  const uploadButton = uploader.querySelector('.js-c-media--upload__button')

  if (uploadButton) {
    uploadButton.addEventListener('click', async (event) => {
      event.preventDefault()
      setButtonLoadingState(uploadButton, true)

      await handleUpload(uploader, () => {
        setButtonLoadingState(uploadButton, false)
      })
    })
  }
}

async function handleUpload(element, callback) {
  if (isCloudinaryWidgetOpen()) return

  const { tag, allowedFormats, maxImageWidth, maxImageHeight, minImageWidth, minImageHeight, resourceType, postToUrl } = JSON.parse(element.dataset.clientState)

  try {
    const results = await openCloudinaryWidget({
      uploadPreset: 'no_transforms',
      tags: [tag],
      multiple: true,
      sources: ['local', 'url'],
      maxFileSize: 25 * 100000, // 25 MB
      resourceType,
      allowedFormats,
      maxImageWidth,
      maxImageHeight,
      minImageWidth,
      minImageHeight,
    })

    if (results.length) {
      await submitUpdates(postToUrl, results)
      window.location.reload()
    }
  } catch (error) {
    console.error('Error during image upload:', error)
    window.alert('An error occurred while uploading the images.')
  }

  callback()
}

async function submitUpdates(postToUrl, results) {
  const media = results.map(upload => {
    const { info } = upload
    return {
      media_type: info.resource_type,
      cdn_public_id: info.public_id,
      source_url: info.url,
      width: info.width,
      height: info.height,
      bytes: info.bytes,
      original_format: info.format,
    }
  })

  try {
    await requestNative({ url: postToUrl, data: { media } })
  } catch (error) {
    console.error('Error submitting updates:', error)
    throw error
  }
}

document.querySelectorAll('.js-c-media--upload').forEach(initUploader)
