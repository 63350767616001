import { formatDistanceToNow } from 'date-fns'
import queryString from 'query-string'

if ($('.adopted_slideshow').length > 0) {
  var showIndex = 0
  var timeCounter = 0
  var showNextSlide = false

  $('.adopted_slideshow').height($(window).height())

  //get latest adopted listings
  async function getData() {
    const params = queryString.parse(window.location.search, { arrayFormat: 'bracket' })
    const data = await $.ajax({ url: 'adopted_slideshow/get_data', data: params, type: 'GET' })
    $('.slides_container').empty()
    data.forEach((value, index) => {
      const adoptedAt = new Date(value.adopted_at)
      const photoUrl = value.featured_photo_url.replace('h_900', 'h_' + $(window).height()).replace('w_900', 'w_' + $(window).width()).replace('q_auto:best', 'q_auto:good,f_auto')
      $('.slides_container').append(
        '<div class="slides slide-' +
          index +
          '"><img src="' +
          photoUrl +
          '"/><div class="slides-details"><h1 class="name">' +
          value.name +
          '</h1><h2 class="group">' +
          value.group.name +
          '</h2><h2 class="adopted_at">Adopted ' +
          formatDistanceToNow(adoptedAt) +
          ' ago</h2></div></div>'
      )
    })

    $('.slides').hide()
    let photosLoaded = 0

    $('.slides img').on('load', function () {
      photosLoaded++
      if (photosLoaded === data.length) {
        $('.pet_loader').hide()
        $('.slide-' + showIndex).show()

        if (!showNextSlide) {
          setTimeout(nextSlide, 5000)
          showNextSlide = true
        }
      }
    })

    function nextSlide() {
      timeCounter = timeCounter + 1
      $('.slide-' + showIndex).fadeOut()
      showIndex = Math.floor(Math.random() * Math.floor(data.length))
      $('.slide-' + showIndex).fadeIn()
      setTimeout(nextSlide, 5000)
      if (timeCounter === 60) {
        getData()
        timeCounter = 0
      }
    }
  }

  getData()
}
