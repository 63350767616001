import Cookies from 'js-cookie'
import { createAnalyticsGroup } from 'js/lib/analytics'

const banners = document.querySelectorAll('.js-c-ui-floating-banner')
const stickyAds = document.querySelectorAll('.js-sticky_ad')
let previouslyFocusedElement

function initbanner(banner) {
  if (!banner) return

  const bannerId = banner.getAttribute('id')
  const isClosed = Cookies.get(`bannerClosed_${bannerId}`)
  const closeButtons = banner.querySelectorAll('.js-c-ui-floating-banner--close')
  const reminderButton = banner.querySelector('.js-c-ui-floating-banner--reminder')
  const ctaButtons = banner.querySelectorAll('.js-c-ui-floating-banner--cta')
  const track = createAnalyticsGroup(`floating-banner_${bannerId}`)

  if (isClosed) {
    return
  }

  ctaButtons.forEach(cta => {
    cta.addEventListener('click', () => { 
      track('click', 'call to action')
      closeBanner(banner, 365)
    })
  })

  closeButtons.forEach(btn => {
    // Closes for 2 days
    btn.addEventListener('click', () => { 
      track('click', 'close-button')
      closeBanner(banner, 2)
    })
  })
  
  if (reminderButton) {
    const reminderDelay = banner.getAttribute('data-reminder-delay') || 2
    reminderButton.addEventListener('click', () => {
      track('click', 'reminder-button')
      closeBanner(banner, parseInt(reminderDelay, 2))
    })
  }

  if (banner.getAttribute('data-state') === 'open') {
    openBanner(banner, track)
  }
}

const openBanner = (banner, track) => {
  previouslyFocusedElement = document.activeElement
  hideStickyAds()
  setTimeout(() => {
    banner.focus()
  }, 0)

  banner.classList.remove('tw-hidden')
  banner.classList.add('tw-flex')
  document.addEventListener('keydown', (event) => {
    track('keydown', 'closed by esc')
    handleKeydown(event, banner)
  })
  document.addEventListener('keydown', (event) => trapFocus(event, banner))
}

const closeBanner = (banner, days) => {
  const bannerId = banner.getAttribute('id')
  Cookies.set(`bannerClosed_${bannerId}`, true, {
    expires: days,
    path: '/',
    secure: PetRescue.env.public,
  })

  banner.setAttribute('data-state', 'close')
  banner.classList.add('tw-hidden')

  document.removeEventListener('keydown', (event) => handleKeydown(event, banner))
  document.removeEventListener('keydown', (event) => trapFocus(event, banner))

  showStickyAds()

  if (previouslyFocusedElement) {
    previouslyFocusedElement.focus()
  }
}
const handleKeydown = (event, banner) => {
  if (event.key === 'Escape' && banner.getAttribute('data-state') === 'open') {
    closeBanner(banner, 2)
  }
}

const trapFocus = (event, el) => {
  const focusables = getKeyboardFocusableElements(el)
  const firstFocusable = focusables[0]
  const lastFocusable = focusables[focusables.length - 1]

  if (document.activeElement === lastFocusable && event.key === 'Tab' && !event.shiftKey) {
    event.preventDefault()
    firstFocusable.focus()
  }

  if (document.activeElement === firstFocusable && event.key === 'Tab' && event.shiftKey) {
    event.preventDefault()
    lastFocusable.focus()
  }
}

const getKeyboardFocusableElements = (element) => {
  return [...element.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')].filter(el => !el.hasAttribute('disabled'))
}

// Don't show the sticky ad while the floating banner is visible
function hideStickyAds() {
  stickyAds.forEach(ad => ad.classList.add('tw-hidden'));
}

function showStickyAds() {
  stickyAds.forEach(ad => ad.classList.remove('tw-hidden'));
}

banners.forEach((banner) => {
  initbanner(banner)
})
